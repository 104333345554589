import FileSaver from 'file-saver';
import { FieldViewerContext, fieldViewerComponents } from './typed-configs';
import { Dashboard } from '@/models/hcad/shared/dashboard';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function csvEscape(inputString: any)
{
    // replace all double-quotes with two double-quotes when double-quoting a value
    // see the spec for csv
    const input =
        (inputString == null)
            ? ''
            : ((typeof inputString != 'string')
                ? ((inputString.toString) ? inputString.toString() : `${inputString}`)
                : inputString);
    return `"${input.replace(/"/g, '""')}"`;
}

export function individualDataTableToCSV(
    data: FieldViewerContext[],
    headers: { text: string, value: number | string, sortable: boolean }[],
    dashboard: Dashboard,
)
{
    // console.log(headers);
    // console.log(data);
    if (data.length === 0)
    {
        return '';
    }

    const csvHeaders: string[] = []; // NOTE: not populating from headers directly because e.g. capabilities can be many columns
    const csvBuilder: string[] = [];

    data.forEach((context, contextIndex) =>
    {
        const csvRowBuilder: string[] = [];
        const isFirstRow = (contextIndex === 0);
        const row = context[0];
        row.forEach((cell, index) =>
        {
            const dataFunctionFactory = fieldViewerComponents.getDataFactory(cell[0]);
            if (dataFunctionFactory)
            {
                const dataFunctions = dataFunctionFactory();
                const value = dataFunctions.toString(index, cell[0], context, dashboard);
                if (value instanceof Map)
                {
                    value.forEach((mapValue, key) => 
                    {
                        if (isFirstRow) csvHeaders.push(csvEscape(key));
                        csvRowBuilder.push(csvEscape(mapValue));
                    });
                }
                else
                {
                    if (isFirstRow && headers.length > index)
                    {
                        csvHeaders.push(csvEscape(headers[index].text));
                    }
                    csvRowBuilder.push(csvEscape(value));
                }
            }
        });
        csvBuilder.push(csvRowBuilder.join(','));
    });
    return `${csvHeaders.join(',')}\n${csvBuilder.join('\n')}`;
}

export function downloadIndividualDataTableCSV(
    data: FieldViewerContext[],
    headers: { text: string, value: number | string, sortable: boolean }[],
    dashboard: Dashboard,
    fileName = 'table.csv',
)
{
    const csvString: string = individualDataTableToCSV(data, headers, dashboard);

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, fileName);
}

export function dataToCSV(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[], // arbitrary object mapping headerString -> valueString where headerString should be one of headers.value
    headers: { text: string, value: number | string, sortable: boolean }[],
)
{
    // console.log(headers);
    // console.log(data);
    if (data.length === 0)
    {
        return '';
    }

    const csvHeaders: string[] = [];
    const csvBuilder: string[] = [];

    headers.forEach(({ text }) => csvHeaders.push(csvEscape(text)));

    data.forEach((row) =>
    {
        const csvRowBuilder: string[] = [];
        headers.forEach(({ value }) =>
        {
            csvRowBuilder.push(csvEscape(row[value] || ''));
        });
        csvBuilder.push(csvRowBuilder.join(','));
    });
    return `${csvHeaders.join(',')}\n${csvBuilder.join('\n')}`;
}

export function downloadCSV(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[], // arbitrary object mapping headerString -> valueString where headerString should be one of headers.value
    headers: { text: string, value: number | string, sortable: boolean }[],
    fileName = 'table.csv',
)
{
    const csvString: string = dataToCSV(data, headers);

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, fileName);
}