function charDisplayLength(ch: string)
{
    switch (ch)
    {
    case 'I':
    case ' ':
    case '-':
    case ':':
    case 'l':
    case 'i':
    case 'f':
    case 't':
    case 'r':
        return 0.585;
    case 'W':
    case 'w':
    case 'M':
    case 'm':
        return 2;
    default:
        return (ch === ch.toUpperCase()) ? 1.5 : 1;
    }
}

export function displayLength(text: string)
{
    let l = 0;
    for (let c = 0; c < text.length; ++c)
    {
        l += charDisplayLength(text.charAt(c));
    }
    return l;
}

export function truncate(text: string, displayLength: number)
{
    let l = 0;
    for (let c = 0; c < text.length; ++c)
    {
        l += charDisplayLength(text.charAt(c));
        if (l > displayLength)
        {
            return text.substr(0, c);
        }
        else if (l === displayLength)
        {
            return text.substr(0, c + 1);
        }
    }
    return text;
}