

























































import { Component, Vue, Prop } from 'vue-property-decorator';

import ValueBars from '@/components/ValueBars.vue';
import PercentageBars from '@/components/PercentageBars.vue';
import Donut from '@/components/Donut.vue';
import DynamicIcon from '@/components/DynamicIcon.vue';

@Component({
    components: {
        ValueBars,
        PercentageBars,
        Donut,
        DynamicIcon,
    }
})
export default class Benchmarks extends Vue
{
    @Prop({ default: 'mdi-account' }) readonly iconClass!: string;
    @Prop({ default: '' }) readonly iconUrl!: string;
    @Prop({ default: 'blue' }) readonly iconColor!: string;
    @Prop({ default: 'Individual' }) readonly header!: string;
    @Prop({ default: 'Kate Jackson' }) readonly targetName!: string;
    @Prop({ default: 'Selected Values' }) readonly targetMetricsTitle!: string;
    @Prop({ default: new Map<string, number>() }) readonly targetMetricsMap!: Map<string, number>;
    @Prop({ default: 9 }) readonly targetMetricsMax!: number;
    @Prop({ default: 'Top 3' }) readonly percentagesTitle!: string;
    /** not to be confused with donut percentages... not sure how to name this better (TODO) */
    @Prop({ default: new Map<string, number>() }) readonly percentages!: Map<string, number>;
    @Prop(Boolean) readonly showDonutSubheader!: boolean;
    @Prop({ default: ['Motivational', 'Change', 'Intellectual', 'People'] }) readonly donutLabels!: Array<string>;
    @Prop({ default: [23, 30, 24, 23] }) readonly donutData!: Array<number>;
}
