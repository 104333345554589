












import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataSourceConfig, Dashboard } from '../../../../models/hcad/shared/dashboard';
import { dataSourceEditComponents } from '../../../../utils/typed-configs';
import { validateRefs } from '../../../../utils/validate-refs';

// TODO: Automate this...?
require('./data-source-configurators/MockDataSourceEdit.vue');
require('./data-source-configurators/BladeDataSourceEdit.vue');

@Component
export default class DataSourceConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    config!: DataSourceConfig;

    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    get editorComponent()
    {
        return dataSourceEditComponents.getComponent(this.config.type);
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}
