




































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { DashboardPageType, BenchmarkDashboardPage, DashboardPageFieldType, BaseDashboardPageField, AggregationSettings, fieldTypeToFilterType, DashboardPageFilterType } from '@/models/hcad/shared/dashboard';
import { pageEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';
import FieldList from '../FieldList.vue';
import AssetSelectInput from '@/components/AssetSelectInput.vue';

@Component({
    components:
    {
        FieldList,
        AssetSelectInput
    }
})
export default class BenchmarkPageConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    page!: BenchmarkDashboardPage;

    readonly disallowedTypes = [
        DashboardPageFieldType.MatchPercent,
        DashboardPageFieldType.LongevityPrediction,
        DashboardPageFieldType.DateCompleted,
        DashboardPageFieldType.Capabilities,
        DashboardPageFieldType.TimeInOrganization,
        DashboardPageFieldType.UserEmail,
    ];

    keyOffset = 0;

    @Watch('page.aggregationFields')
    onNewFields(newValue: BaseDashboardPageField[])
    {
        // addition
        while (this.page.aggregationSettings.length < newValue.length)
        {
            this.page.aggregationSettings.push(new AggregationSettings());
        }
    }

    swapSettings(swap: number[])
    {
        if (swap.length < 2) return;
        const aval = this.page.aggregationSettings[swap[0]];
        this.page.aggregationSettings[swap[0]] = this.page.aggregationSettings[swap[1]];
        this.page.aggregationSettings[swap[1]] = aval;
        ++this.keyOffset;
    }

    copySettings(idx: number)
    {
        this.page.aggregationSettings.push(AggregationSettings.clone(this.page.aggregationSettings[idx]));
        ++this.keyOffset;
    }

    deleteSettings(idx: number)
    {
        this.page.aggregationSettings.splice(idx, 1);
        ++this.keyOffset;
    }

    @Watch('page.includeGlobal')
    onIncludeGlobalChanged(newValue: boolean)
    {
        if (newValue)
        {
            // addition
            this.page.globalAggregationSettings = new AggregationSettings();
        }
        else
        {
            // deletion
            this.page.globalAggregationSettings = undefined;
        }
    }

    hasFilter(index: number)
    {
        return fieldTypeToFilterType(this.page.aggregationFields[index].type) !== DashboardPageFilterType.Invalid;
    }

    typeString(type: DashboardPageFieldType)
    {
        return DashboardPageFieldType[type];
    }

    validate()
    {
        return validateRefs(this.$refs);
    }

    mounted()
    {
        if (this.page && !(this.page.aggregationFields))
        {
            this.page.aggregationFields = [];
            console.log('wtf');
        }
        if (this.page && !(this.page.aggregationSettings))
        {
            this.page.aggregationSettings = [];
            console.log('wtf');
        }
    }
}

pageEditorComponents
    .registerComponent(DashboardPageType.Benchmark, BenchmarkPageConfigEdit)
    .registerDataFactory(DashboardPageType.Benchmark, ()=>new BenchmarkDashboardPage)
;
