let isValidating = false;
const refErrors: string[] = [];
const refStack: string[] = [];

export function beginRefValidation()
{
    if (isValidating)
    {
        throw "Attempted to start ref validation before ending it";
    }
    refErrors.length = 0;
    refStack.length = 0;
    isValidating = true;
}

export function endRefValidation()
{
    if (!isValidating)
    {
        throw "Attempted to end ref validation before starting it";
    }
    
    if(refErrors.length > 0)
    {
        alert(`Form validation failed with the following errors...\n${refErrors.reverse().join('\n')}`);
    }
    refErrors.length = 0;
    refStack.length = 0;
    isValidating = false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function validateRef(ref: any)
{
    if (!ref.validate) return true;
    // console.log(`found ref ${key} with validate`);
    const res = ref.validate(true);
    
    if (!res)
    {
        refErrors.push(refStack.join('/'));
    }
    return res;
}

export function validateRefs(refs: { [key: string]: Vue | Element | Vue[] | Element[] })
{
    let res = true;
    for(const k in refs)
    {
        // Iterate through all refs and validate them.  Return true if all pass,
        // false if any fail.
        const ref = refs[k];
        if (Array.isArray(ref))
        {
            for(const idx in ref)
            {
                const obj = ref[idx];
                refStack.push(`${k}[${idx}]`);
                res = validateRef(obj) && res;
                refStack.pop();
            }
        }
        else if (ref)
        {
            refStack.push(k);
            res = validateRef(ref) && res;
            refStack.pop();
        }
    }

    return res;
}