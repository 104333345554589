
























































































































































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';

import { 
    DashboardPageFilterType,
    DashboardPageFilterConfig,
    BaseFilterQueryData,
    SelectFilterQueryData,
    RangeFilterQueryData,
    RoleBaselineQueryData,
    MetricsToggleQueryData,
} from '@/models/hcad/shared/dashboard';
import FilterPreset from '@/models/hcad/shared/filter-preset';
import dashModule from '@/store/modules/DashboardModule';
import filterPresetModule from '@/store/modules/FilterPresetModule';
import { RoleBaselineReferenceType } from '@/models/hcad/shared/queries';
import authModule from '@/store/modules/AuthModule';

@Component({})
export default class FilterPresetManager extends Vue
{
    @Prop({ type: Object, required: true })
    filterConfig!: DashboardPageFilterConfig;

    @Prop({ type: Array, required: true })
    value!: BaseFilterQueryData[];

    @PropSync('preset', { required: true })
    selectedFilterPreset!: FilterPreset | null;

    dashboardId = '';
    filterPresetOptions: { text: string, value: FilterPreset }[] = []; // excludes hidden presets
    dashFilterPresets: FilterPreset[] | null = null; // includes hidden presets

    disableButtons = false;

    showSaveNewModal = false;
    newPresetName = '';
    saveNewErrorMessage = '';

    showDeleteModal = false;
    deleteErrorMessage = '';

    isMounting = false;

    get hasUnsavedChanges()
    {
        if (this.selectedFilterPreset)
        {
            return this.value.some((v) =>
            {
                if (!(this.selectedFilterPreset)) return false;
                switch (v.type)
                {
                case DashboardPageFilterType.DepartmentSelect:
                    return this.selectedFilterPreset.department !== (v as SelectFilterQueryData).selectedOption;
                case DashboardPageFilterType.LocationSelect:
                    return this.selectedFilterPreset.location !== (v as SelectFilterQueryData).selectedOption;
                case DashboardPageFilterType.JobCodeSelect:
                    return this.selectedFilterPreset.jobCode !== (v as SelectFilterQueryData).selectedOption;
                case DashboardPageFilterType.FunctionSelect:
                    return this.selectedFilterPreset.function !== (v as SelectFilterQueryData).selectedOption;
                case DashboardPageFilterType.CompanySelect:
                    return this.selectedFilterPreset.company !== (v as SelectFilterQueryData).selectedOption;
                case DashboardPageFilterType.YearsEmployedRange:
                {
                    const range = (v as RangeFilterQueryData);
                    return (this.selectedFilterPreset.yearRange[0] !== range.selectedMin
                        || this.selectedFilterPreset.yearRange[1] !== range.selectedMax);
                }
                case DashboardPageFilterType.RoleBaseline:
                {
                    const rbqData = (v as RoleBaselineQueryData);
                    if (this.selectedFilterPreset.isManual !== rbqData.isManual)
                    {
                        return true;
                    }
                    else if (this.selectedFilterPreset.isManual)
                    {
                        // detect change in culture add settings
                        const presetSelectedMetrics = this.selectedFilterPreset.baseline.value as [string, number][];
                        const rbqSelectedMetrics = rbqData.baseline.value as [string, number][];
                        return presetSelectedMetrics && rbqSelectedMetrics
                            && (presetSelectedMetrics.length !== rbqSelectedMetrics.length
                                || presetSelectedMetrics.some((presetSelectedMetric) =>
                                    (rbqSelectedMetrics.every((rbqSelectedMetric) =>
                                        (presetSelectedMetric[0] !== rbqSelectedMetric[0]
                                            || presetSelectedMetric[1] !== rbqSelectedMetric[1])
                                    ))
                                )
                            );
                    }
                    else
                    {
                        // detect change in culture fit settings
                        const presetValue = this.selectedFilterPreset.baseline.value as RoleBaselineReferenceType;
                        const rbqValue = rbqData.baseline.value as RoleBaselineReferenceType;
                        return rbqData.baseline.broadenFitSlider !== this.selectedFilterPreset.baseline.broadenFitSlider
                            || (presetValue != null && rbqValue != null
                                && (presetValue.baseline !== rbqValue.baseline
                                    || presetValue.metrics.length !== rbqValue.metrics.length
                                    || presetValue.metrics.some((presetSelectedMetric) =>
                                        (rbqValue.metrics.every((rbqSelectedMetric) =>
                                            (presetSelectedMetric[0] !== rbqSelectedMetric[0]
                                                || presetSelectedMetric[1] !== rbqSelectedMetric[1])
                                        ))
                                    )
                                )
                            );
                    }
                    // TODO: is there a ComputedRoleBaseline case we should handle here?
                }
                case DashboardPageFilterType.MetricsToggle:
                {
                    const mtqData = (v as MetricsToggleQueryData);
                    return this.selectedFilterPreset.selectedMetrics.length !== mtqData.selectedMetrics.length
                        || this.selectedFilterPreset.selectedMetrics.some((presetSelectedMetric) =>
                        {
                            return mtqData.selectedMetrics.every((selectedMetric) =>
                            {
                                return presetSelectedMetric._id !== selectedMetric._id
                                    || presetSelectedMetric.targetValue !== selectedMetric.targetValue;
                            });
                        });
                }
                }
            });
        }
        return false;
    }

    get hiddenPresetName()
    {
        if (authModule.activeUser)
        {
            return `.._._${authModule.activeUser.email}_${this.$route.params.index}`;
        }
        return `.._._anon_${this.$route.params.index}`;
    }

    async mounted()
    {
        this.isMounting = true;
        try
        {
            this.dashboardId = (await dashModule.getActiveDashboard())._id;
            this.dashFilterPresets = await filterPresetModule.getAllInDashboard(this.dashboardId);
            if (this.dashFilterPresets)
            {
                this.filterPresetOptions =
                    this.dashFilterPresets
                        .filter((fp) => !(fp.name.startsWith('.._._'))) // remove all hidden system presets
                        .map((fp) => ({ text: fp.name, value: fp }));

                // restores last settings for this dashboard page used by this user
                const hiddenPreset = this.dashFilterPresets.find((fp) => (fp.name === this.hiddenPresetName));
                if (hiddenPreset)
                {
                    this.selectedFilterPreset = hiddenPreset;
                    await this.$nextTick();
                    this.selectedFilterPreset = null;
                }
            }
        }
        finally
        {
            this.isMounting = false;
        }
    }

    copyCurrentValuesToPreset(fp: FilterPreset)
    {
        // console.log(fp);
        // console.log(this.value);
        this.value.forEach((v) =>
        {
            switch (v.type)
            {
            case DashboardPageFilterType.DepartmentSelect:
                fp.department = (v as SelectFilterQueryData).selectedOption;
                break;
            case DashboardPageFilterType.LocationSelect:
                fp.location = (v as SelectFilterQueryData).selectedOption;
                break;
            case DashboardPageFilterType.JobCodeSelect:
                fp.jobCode = (v as SelectFilterQueryData).selectedOption;
                break;
            case DashboardPageFilterType.FunctionSelect:
                fp.function = (v as SelectFilterQueryData).selectedOption;
                break;
            case DashboardPageFilterType.CompanySelect:
                fp.company = (v as SelectFilterQueryData).selectedOption;
                break;
            case DashboardPageFilterType.YearsEmployedRange:
                {
                    const range = (v as RangeFilterQueryData);
                    fp.yearRange = [range.selectedMin, range.selectedMax];
                }
                break;
            case DashboardPageFilterType.RoleBaseline:
                {
                    const rbqData = (v as RoleBaselineQueryData);
                    fp.isManual = rbqData.isManual;
                    fp.baseline = rbqData.baseline;
                }
                break;
            case DashboardPageFilterType.MetricsToggle:
                {
                    const mtqData = (v as MetricsToggleQueryData);
                    fp.selectedMetrics = mtqData.selectedMetrics;
                }
                break;
            }
        });
    }

    async onClickUpdatePreset()
    {
        this.disableButtons = true;
        if (this.selectedFilterPreset)
        {
            this.copyCurrentValuesToPreset(this.selectedFilterPreset);
            await filterPresetModule.update(this.selectedFilterPreset);
            // TODO: error reporting for failed update?
        }
        this.disableButtons = false;
    }

    onClickDeletePreset()
    {
        this.deleteErrorMessage = '';
        this.showDeleteModal = true;
    }

    async onDeletePreset()
    {
        this.disableButtons = true;
        if (this.selectedFilterPreset)
        {
            if (await filterPresetModule.delete(this.selectedFilterPreset._id))
            {
                this.filterPresetOptions.splice(
                    this.filterPresetOptions.findIndex(
                        (option) => (this.selectedFilterPreset && option.text === this.selectedFilterPreset.name)
                    ),
                    1,
                );
                this.selectedFilterPreset = null;
            }
            else
            {
                this.deleteErrorMessage = 'Error while deleting. If the error persists, refresh the page and try again.';
                this.disableButtons = false;
                return;
            }
        }
        this.disableButtons = false;
        this.showDeleteModal = false;
    }

    onClickSaveNewPreset()
    {
        this.newPresetName = '';
        this.saveNewErrorMessage = '';
        this.showSaveNewModal = true;
    }

    async onSaveNewPreset()
    {
        // error check
        if (this.newPresetName === '')
        {
            this.saveNewErrorMessage = 'Must specify a Preset name';
            return;
        }
        else if (this.filterPresetOptions.some((option) => (option.text === this.newPresetName)))
        {
            this.saveNewErrorMessage = 'Preset name already exists';
            return;
        }

        this.disableButtons = true;

        // post
        const fp = new FilterPreset();
        fp.name = this.newPresetName;
        this.copyCurrentValuesToPreset(fp);
        const result = await filterPresetModule.create({ dashboardId: this.dashboardId, filterPreset: fp });
        if (!result)
        {
            this.saveNewErrorMessage = 'Error while saving. If the error persists, refresh the page and try again.';
            this.disableButtons = false;
            return;
        }
        this.filterPresetOptions.push({ text: result.name, value: result });
        this.selectedFilterPreset = result;
        this.disableButtons = false;
        this.showSaveNewModal = false;
    }

    @Watch('value', {
        deep: true,
    })
    onFilterValuesChanged() // for autosaving filter state as a special preset
    {
        if (this.isMounting) return; // don't try to autosave until it's done trying to restore

        // console.log(`save to hidden preset: ${this.hiddenPresetName}`);
        if (this.dashFilterPresets)
        {
            const hiddenPreset = this.dashFilterPresets.find((fp) => (fp.name === this.hiddenPresetName));
            if (hiddenPreset)
            {
                this.copyCurrentValuesToPreset(hiddenPreset);
                filterPresetModule.update(hiddenPreset);
            }
            else
            {
                const fp = new FilterPreset();
                fp.name = this.hiddenPresetName;
                this.copyCurrentValuesToPreset(fp);
                filterPresetModule.create({ dashboardId: this.dashboardId, filterPreset: fp, });
            }
        }
    }
}
