




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, BaseDashboardPageField } from '@/models/hcad/shared/dashboard';
import { fieldEditorComponents } from '@/utils/typed-configs';

@Component
export default class NullFieldEdit extends Vue
{
    @Prop({type: Object, required: true})
    field!: BaseDashboardPageField;
}

fieldEditorComponents
    .registerComponent(DashboardPageFieldType.MatchPercent, NullFieldEdit)
    .registerDataFactory(DashboardPageFieldType.MatchPercent, ()=>new BaseDashboardPageField(DashboardPageFieldType.MatchPercent))

    .registerComponent(DashboardPageFieldType.UserName, NullFieldEdit)
    .registerDataFactory(DashboardPageFieldType.UserName, ()=>new BaseDashboardPageField(DashboardPageFieldType.UserName))

    .registerComponent(DashboardPageFieldType.UserEmail, NullFieldEdit)
    .registerDataFactory(DashboardPageFieldType.UserEmail, ()=>new BaseDashboardPageField(DashboardPageFieldType.UserEmail))

    .registerComponent(DashboardPageFieldType.Capabilities, NullFieldEdit)
    .registerDataFactory(DashboardPageFieldType.Capabilities, ()=>new BaseDashboardPageField(DashboardPageFieldType.Capabilities))

    .registerComponent(DashboardPageFieldType.TimeInOrganization, NullFieldEdit)
    .registerDataFactory(DashboardPageFieldType.TimeInOrganization, ()=>new BaseDashboardPageField(DashboardPageFieldType.TimeInOrganization))

    .registerComponent(DashboardPageFieldType.Location, NullFieldEdit)
    .registerDataFactory(DashboardPageFieldType.Location, ()=>new BaseDashboardPageField(DashboardPageFieldType.Location))

    .registerComponent(DashboardPageFieldType.JobCode, NullFieldEdit)
    .registerDataFactory(DashboardPageFieldType.JobCode, ()=>new BaseDashboardPageField(DashboardPageFieldType.JobCode))

    .registerComponent(DashboardPageFieldType.DateCompleted, NullFieldEdit)
    .registerDataFactory(DashboardPageFieldType.DateCompleted, ()=>new BaseDashboardPageField(DashboardPageFieldType.DateCompleted))
;
