





















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseDashboardPageFilter, ControlGroup, DashboardPageFilterType } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import { filterEditorComponents } from '@/utils/typed-configs';
import FilterEdit from './FilterEdit.vue';

@Component({components: { FilterEdit }})
export default class PageList extends Vue
{
    @Prop({type: Array, required: true})
    filters!: BaseDashboardPageFilter[];
    @Prop({type: Array, required: true})
    groups!: ControlGroup[];
    configReactivitySwitch = false;
    createModalOpen = false;
    selectedFilterType = DashboardPageFilterType.Invalid;
    keyOffset = 0;

    get filterSelectOptions()
    {
        const opts: { text: string; value: DashboardPageFilterType; disabled?: boolean }[] = 
            [{ text: 'Select a filter type', value: DashboardPageFilterType.Invalid, disabled: true }];
        
        for(let i = DashboardPageFilterType.Invalid + 1; i < DashboardPageFilterType.Count; ++i)
        {
            // Gets the names
            opts.push({ text: DashboardPageFilterType[i], value: i });
        }
        return opts;
    }

    createFilter()
    {
        const type = this.selectedFilterType;
        const factory = filterEditorComponents.getDataFactory(type);
        if (factory)
        {
            // Create the page and push it into the list
            this.filters.push(factory());
            this.selectedFilterType = DashboardPageFilterType.Invalid;
            this.createModalOpen = false;
            ++this.keyOffset;
        }
        else
        {
            alert(`Error: No factory for filter type ${DashboardPageFilterType[type]}.  Please notify the developers of this error.`);
        }
    }

    copyFilter(idx: number)
    {
        this.filters.push(BaseDashboardPageFilter.clone(this.filters[idx]));
        ++this.keyOffset;
    }

    deleteFilter(idx: number)
    {
        this.filters.splice(idx, 1);
        ++this.keyOffset;
    }

    swapFilters(a: number, b: number)
    {
        const aval = this.filters[a];
        const bval = this.filters[b];
        this.filters[a] = bval;
        this.filters[b] = aval;
        ++this.keyOffset;
    }

    typeString(type: DashboardPageFilterType)
    {
        return DashboardPageFilterType[type];
    }

    validate()
    {
        return validateRefs(this.$refs);
    }

    updateFilterEditComponents()
    {
        const fe = this.$refs['filterEditComponent'] as Vue[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (fe) fe.forEach(f => (f as any).onGroupsChanged());
    }

    onGroupsChanged()
    {
        // this.$forceUpdate(); // needed if group is shown in the list
        this.updateFilterEditComponents();
    }
}
