import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// Note that we don't have to import the modules here.  They are set up automatically when they are imported elsewhere.
export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
});
