import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import AuthRoutes from './auth';
import AdminRoutes from './admin';
import DashboardRoutes from './dashboard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    ...AuthRoutes,
    ...AdminRoutes,
    ...DashboardRoutes,
    {
        path: '*',
        redirect: '/dashboard'
    }
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});

export default router;