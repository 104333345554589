




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, FieldValueType, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerContext } from '../../../utils/typed-configs';

@Component
export default class EmailRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: FieldValueType;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;
}

function toString(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext)
{
    return a[1].user.email;
}

function sort(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, b: FieldViewerContext)
{
    const aval = a[1].user.email;
    const bval = b[1].user.email;
    return -aval.localeCompare(bval);
}

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.UserEmail, EmailRenderer)
    .registerDataFactory(DashboardPageFieldType.UserEmail, () => ({ toString, sort }));

