

















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceJobCodeConfig, Dashboard } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import validationRules from '@/utils/validation-rules';
import VNumberField from '@/components/VNumberField.vue';

@Component({components: {VNumberField}})
export default class MockJobCodeEdit extends Vue
{
    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Object, required: true})
    jobCode!: MockDataSourceJobCodeConfig;

    rules = validationRules;
    recomputeFlag = false;

    get fullRange()
    {
        return this.dashboard.capabilityRange;
    }

    get max()
    {
        return (this.fullRange && this.fullRange.length > 1) ? this.fullRange[1] : 5;
    }

    get min()
    {
        return (this.fullRange && this.fullRange.length > 0) ? this.fullRange[0] : 0;
    }

    minimizeMinimums()
    {
        for (let i = 0; i < this.minMetricValues.length; ++i)
        {
            this.updateMetricRange(i, [this.min, this.maxMetricValues[i]]);
        }
    }

    maximizeMaximums()
    {
        for (let i = 0; i < this.maxMetricValues.length; ++i)
        {
            this.updateMetricRange(i, [this.minMetricValues[i], this.max]);
        }
    }

    evenlySplitSubcapabilities()
    {
        let cIndex = 0;
        this.dashboard.capabilities.forEach((cat) => cat.capabilities.forEach((cap) =>
        {
            cap.subCapabilities.forEach((subcap, subcapI) =>
            {
                this.updateMetricRange(
                    cIndex + 1 + subcapI,
                    [
                        Math.min(
                            this.minMetricValues[cIndex + 1 + subcapI],
                            Math.floor(this.maxMetricValues[cIndex] / cap.subCapabilities.length)
                        ),
                        Math.ceil(this.maxMetricValues[cIndex] / cap.subCapabilities.length)
                    ]);
            });
            cIndex += 1 + cap.subCapabilities.length;
        }));
    }

    ensureValidSet()
    {
        if (this.jobCode.metricRanges.length > this.capabilityList.length)
        {
            this.jobCode.metricRanges = this.jobCode.metricRanges.slice(0, this.capabilityList.length);
        }

        for(let i = this.jobCode.metricRanges.length; i < this.capabilityList.length; ++i)
        {
            const range = this.capabilityList[i].range || this.dashboard.capabilityRange;
            this.jobCode.metricRanges.push(range);
        }
    }

    setMinMet(idx: number,  value: number)
    {
        this.ensureValidSet();
        this.jobCode.metricRanges[idx][0] = value;
        this.recomputeFlag = !this.recomputeFlag;
    }

    setMaxMet(idx: number,  value: number)
    {
        this.ensureValidSet();
        this.jobCode.metricRanges[idx][1] = value;
        this.recomputeFlag = !this.recomputeFlag;
    }

    updateMetricRange(idx: number, nums: number[])
    {
        this.ensureValidSet();
        this.jobCode.metricRanges[idx][0] = nums[0];
        this.jobCode.metricRanges[idx][1] = nums[1];
        this.recomputeFlag = !this.recomputeFlag;
    }

    get capabilityList()
    {
        const caplist = [];
        for (const cat of this.dashboard.capabilities)
        {
            for (const cap of cat.capabilities)
            {
                caplist.push(cap);
                for (const subcap of cap.subCapabilities)
                {
                    caplist.push(subcap);
                }
            }
        }
        return caplist;
    }

    get minMetricValues()
    {
        if (this.recomputeFlag && !this.recomputeFlag) return [];
        this.ensureValidSet();
        return this.jobCode.metricRanges.map(a=>a[0]);
    }

    get maxMetricValues()
    {
        if (this.recomputeFlag && !this.recomputeFlag) return [];
        this.ensureValidSet();
        return this.jobCode.metricRanges.map(a=>a[1]);
    }

    mounted()
    {
        this.ensureValidSet();
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}
