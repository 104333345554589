



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import MetricsGroup from '@/components/MetricsGroup.vue';
import {
    CapabilityCategory,
    Capability,
    DashboardPageMetricsToggleFilter,
    MetricsToggleQueryData,
    DashboardPageFilterType,
    Dashboard,
} from '@/models/hcad/shared/dashboard';
import dashModule from '@/store/modules/DashboardModule';
import { filterRuntimeComponents } from '@/utils/typed-configs';
import { displayLength } from '@/utils/text-util';

@Component({
    components: {
        MetricsGroup,
    }
})
export default class MetricsToggle extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageMetricsToggleFilter;

    /** array of aggregated selected metrics */
    @Prop({type: Object, required: true}) 
    value!: MetricsToggleQueryData;

    /** array of selected metrics */
    // selectedMetrics: Array<Array<Capability>> = [];

    activeDashboardNullable: Dashboard | null = null;
    loading = true;

    get globalMetricRange()
    {
        return this.activeDashboardNullable ? this.activeDashboardNullable.capabilityRange : [0, 9];
    }

    async mounted()
    {
        // console.log('MetricsToggle mounted');
        this.loading = true;
        try
        {
            this.activeDashboardNullable = await dashModule.getActiveDashboard();

            const metricRange = this.activeDashboardNullable.capabilityRange;
            
            // Always target the maximum value if target selection is disabled
            const fallbackDefault = this.filter.allowTargetSelection ? (metricRange[0] + metricRange[1]) / 2 : metricRange[1];
            this.categories.forEach((cat) =>
            {
                cat.capabilities.forEach((cap) =>
                {
                    if (!cap.expanded) cap.expanded = false;
                    if (!cap.selected) cap.selected = false;
                    if (!cap.targetValue) cap.targetValue = fallbackDefault;

                    cap.subCapabilities.forEach((subcap) =>
                    {
                        if (!subcap.expanded) subcap.expanded = false;
                        if (!subcap.selected) subcap.selected = false;
                        if (!subcap.targetValue) subcap.targetValue = fallbackDefault;
                    });
                });
            });
        }
        catch(er)
        {
            console.error(`Failed to load - ${er}`);
        }
        this.loading = false;
    }

    get categories()
    {
        return this.activeDashboardNullable ? this.activeDashboardNullable.capabilities : [];
    }

    twoLineTitle(category: CapabilityCategory)
    {
        const twoLineCharLimit = 17.95 + Math.max(0, (category.capabilities.length - 3) * 5.9);
        return displayLength(category.name.toUpperCase()) > twoLineCharLimit;
    }

    get twoLineTitles()
    {
        return true || this.categories.some(this.twoLineTitle);
    }

    onSelection(m: Capability)
    {
        // console.log(m);
        // handle deselect for non-multiple filter
        if (!(this.filter.multiple) && m.selected)
        {
            this.categories.forEach((cat) =>
            {
                cat.capabilities.forEach((cap) =>
                {
                    if (cap !== m)
                    {
                        cap.selected = false;
                    }
                    cap.subCapabilities.forEach((subcap) =>
                    {
                        if (subcap !== m)
                        {
                            subcap.selected = false;
                        }
                    });
                });
            });
        }

        // construct selected array
        this.value.selectedMetrics = [];
        this.categories.forEach((cat) =>
        {
            cat.capabilities.forEach((cap) =>
            {
                if (cap.selected)
                {
                    this.value.selectedMetrics.push(cap);
                }
                cap.subCapabilities.forEach((subcap) =>
                {
                    if (subcap.selected)
                    {
                        this.value.selectedMetrics.push(subcap);
                    }
                });
            });
        });

        this.$forceUpdate();
        (this.$refs.groups as Vue[]).forEach(g => g.$forceUpdate());
    }

    @Watch('value.selectedMetrics')
    onValueSMChanged(newValue: Array<Capability>)
    {
        this.categories.forEach((cat) =>
        {
            cat.capabilities.forEach((cap) =>
            {
                if (newValue.includes(cap))
                {
                    cap.selected = true;
                }
                else
                {
                    cap.selected = false;
                }
                cap.subCapabilities.forEach((subcap) =>
                {
                    if (newValue.includes(subcap))
                    {
                        subcap.selected = true;
                    }
                    else
                    {
                        subcap.selected = false;
                    }
                });
            });
        });

        this.$forceUpdate();
        (this.$refs.groups as Vue[]).forEach(g => g.$forceUpdate());
    }
}

filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.MetricsToggle, MetricsToggle)
    .registerDataFactory(DashboardPageFilterType.MetricsToggle,
        ()=>new MetricsToggleQueryData(DashboardPageFilterType.MetricsToggle))
;
