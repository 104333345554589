













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ValueBar extends Vue
{
    /** range 0 to 1 */
    @Prop(Number) readonly value!: number;
    /** any CSS supported value */
    @Prop(String) readonly length!: string;
    /** width instead of height */
    @Prop(Boolean) readonly sideways!: boolean;

    get heightPropName()
    {
        return this.sideways ? 'width' : 'height';
    }
}
