














import { Component, Vue, Prop } from 'vue-property-decorator';

import ValueBar from '@/components/ValueBar.vue';

@Component({
    components: {
        ValueBar
    }
})
export default class ValueBars extends Vue
{
    /** range 0 - 5 */
    @Prop(Number) readonly value!: number;
}
