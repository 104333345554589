


































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceConfig, Dashboard, MockDataSourceLocationConfig, MockDataSourceDepartmentConfig } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import validationRules from '@/utils/validation-rules';
import VNumberField from '@/components/VNumberField.vue';
import MockJobCodeEdit from './MockJobCodeEdit.vue';

@Component({components: {VNumberField, MockJobCodeEdit}})
export default class MockLocationEdit extends Vue
{
    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Object, required: true})
    location!: MockDataSourceLocationConfig;

    @Prop({type: Object, required: true})
    config!: MockDataSourceConfig;

    refreshFlag = false;

    setMinForDepartment(depId: string, value: number)
    {
        let tg = this.location.departmentRelativeCounts[depId];
        if (!tg)
        {
            tg = [0, 1];
        }
        tg[0] = value;
        this.refreshFlag = !this.refreshFlag;
    }

    setMaxForDepartment(depId: string, value: number)
    {
        let tg = this.location.departmentRelativeCounts[depId];
        if (!tg)
        {
            tg = [0, 1];
        }
        tg[1] = value;
        this.refreshFlag = !this.refreshFlag;
    }

    get departmentMapping()
    {
        const list: { department: MockDataSourceDepartmentConfig; value: [number, number] }[] = [];
        if (this.refreshFlag && !this.refreshFlag) return list;

        const deps = this.config.departments;
        for(const dep of deps)
        {
            if (this.location.departmentRelativeCounts[dep.id])
            {
                list.push({
                    department: dep,
                    value: this.location.departmentRelativeCounts[dep.id]
                });
            }
            else
            {
                const res: [number, number] = [0, 1];
                this.location.departmentRelativeCounts[dep.id] = res;
                list.push({ 
                    department: dep,
                    value: res 
                });
            }
        }
        return list;
    }

    keyOffset = 0;

    rules = validationRules;

    validate()
    {
        return validateRefs(this.$refs);
    }
}
