import User from '../../User';

export class RoleBaselineMember
{
    bladeUser?: User | null = null;

    userId?: string | null = null;

    firstName?: string | null = null;

    lastName?: string | null = null;

    fullName?: string | null = null;

    /** the only required user info */
    email = '';

    invited = false;

    /** allows dynamically including info like location, department, performance, seniority, etc. */
    userdata: {
        /** will be dynamically matched to our various filters and/or matched for use by the fit computation */
        fieldName: string,
        fieldValue: string,
    }[] = [];

    // populated by backend but not currently saved in the database due to synchronization edge case concerns
    assessed = false;
}

export default class RoleBaseline
{
    _id = '';

    name = 'New Job Baseline';

    dashboard = '';

    members: RoleBaselineMember[] = [];

    checkLinks = false;

    updated: Date = new Date();
}
