
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceConfig, Dashboard, MockDataSourceLocationConfig } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import MockLocationEdit from './MockLocationEdit.vue';
@Component({components: {MockLocationEdit}})
export default class MockLocationList extends Vue
{
    @Prop({type: Object, required: true})
    config!: MockDataSourceConfig;

    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    createLocation()
    {
        this.config.locations.push(new MockDataSourceLocationConfig());
    }

    keyOffset = 0;
    swapLocations(a: number, b: number)
    {
        const aval = this.config.locations[a];
        const bval = this.config.locations[b];
        this.config.locations[a] = bval;
        this.config.locations[b] = aval;
        ++this.keyOffset;
    }

    copyLocations(idx: number)
    {
        this.config.locations.push(MockDataSourceLocationConfig.clone(this.config.locations[idx]));
        ++this.keyOffset;
    }

    deleteLocations(idx: number)
    {
        this.config.locations.splice(idx, 1);
        ++this.keyOffset;
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}

