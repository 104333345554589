import axios from '@/utils/axios';
import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules';
import { Dashboard } from '@/models/hcad/shared/dashboard';

@Module
class FilterPresetModule extends VuexModule
{
    isLoadingNewPreset = false;

    @Mutation
    setIsLoadingNewPreset(isLoading: boolean)
    {
        this.isLoadingNewPreset = isLoading;
    }

    @Action
    async getById(id: string)
    {
        try
        {
            const resp = await axios.get<FilterPreset>(`/filterpresets/${id}`);
            return Object.assign(new FilterPreset, resp.data);
        }
        catch (err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async getAllInDashboard(dashboardId: string)
    {
        try
        {
            const resp =  await axios.get<FilterPreset[]>(`/filterpresets/dashboard/${dashboardId}`);
            for (let i = 0; i < resp.data.length; ++i)
            {
                resp.data[i] = Object.assign(new Dashboard, resp.data[i]);
            }
            return resp.data;
        }
        catch (err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async create(args: { dashboardId: string, filterPreset: FilterPreset })
    {
        const { dashboardId, filterPreset } = args;
        try
        {
            const res =  await axios.post<FilterPreset>(`/filterpresets/${dashboardId}`, { filterPreset });
            return res.data;
        }
        catch (err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async update(filterPreset: FilterPreset)
    {
        try
        {
            const res =  await axios.patch<FilterPreset>(`/filterpresets/${filterPreset._id}`, { filterPreset: filterPreset });
            return res.data;
        }
        catch (err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async delete(id: string)
    {
        try
        {
            await axios.delete(`/filterpresets/${id}`);
            return true;
        }
        catch (err)
        {
            console.error(err);
        }
        return false;
    }
}

// Register the module
import store from '../index';
import FilterPreset from '@/models/hcad/shared/filter-preset';
const filterPresetModule = new FilterPresetModule({store, name: 'filterPreset'});
export default filterPresetModule;