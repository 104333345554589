































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import BenchmarkColumn from '@/components/BenchmarkColumn.vue';

import {
    Capability,
    DashboardPageType,
    BenchmarkDashboardPage,
    BaseFilterQueryData,
    DashboardPageFilterType,
    MetricsToggleQueryData,
    SelectFilterQueryData,
    BenchmarkData,
    fieldTypeToFilterType,
    DashboardPageFieldType,
} from '@/models/hcad/shared/dashboard';
import {
    FilterValueType,
} from '@/models/hcad/shared/queries';
import {
    pageViewerComponents,
} from '@/utils/typed-configs';
import dashModule from '@/store/modules/DashboardModule';
import dataSourceModule from '@/store/modules/DataSourceModule';

@Component({
    components: {
        BenchmarkColumn,
    }
})
export default class Benchmarks extends Vue
{
    @Prop({type: Object, required: true})
    page!: BenchmarkDashboardPage;

    @Prop({type: Number, required: true})
    index!: number;

    @Prop({type: Array, required: true})
    filterState!: BaseFilterQueryData[];

    loading = false;
    queryResult: BenchmarkData[] | null = null;

    get targetId()
    {
        return this.$route.params['targetId'];
    }

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get aggregationFields()
    {
        return this.page.includeGlobal
            ? [...this.page.aggregationFields, { name: 'Global', type: DashboardPageFieldType.Invalid }]
            : this.page.aggregationFields;
    }

    get aggregationSettings()
    {
        return (this.page.includeGlobal && this.page.globalAggregationSettings)
            ? [...this.page.aggregationSettings, this.page.globalAggregationSettings]
            : this.page.aggregationSettings;
    }

    get aggregationValues()
    {
        const aValues: FilterValueType[] = [];
        this.aggregationFields.forEach((v, k) =>
        {
            const index = this.filterState.findIndex(fs => fs.type === fieldTypeToFilterType(v.type));
            if (index >= 0 && index < this.filterValues.length)
            {
                aValues.push(this.filterValues[index]);
            }
            else if (this.aggregationSettings[k].defaultValue)
            {
                aValues.push(this.aggregationSettings[k].defaultValue);
            }
            else if (this.queryResult && this.queryResult[k])
            {
                const targetName = this.queryResult[k].targetName;
                aValues.push(targetName ? targetName : '');
            }
            else
            {
                aValues.push('');
            }
        });
        return aValues;
    }

    @Watch('aggregationValues')
    async onNewAggregationValues(newValue: FilterValueType[])
    {
        await Vue.nextTick();
        // not really sure why nextTick is needed honestly, but without it there's a display bug
        // where the select can potentially appear to have nothing selected when it really does.
        newValue.forEach((v, k) =>
        {
            if (!v && this.aggregationSettings.length > k && this.aggregationSettings[k].defaultValue)
            {
                this.setDefaultValue(this.aggregationFields[k].type, k);
            }
        });
    }

    get filterValues(): FilterValueType[]
    {
        return this.filterState.map(fs => fs.toValue());
    }

    get selectedMetrics(): Array<Capability>
    {
        const mState =
            this.filterState.find(fs => fs.type === DashboardPageFilterType.MetricsToggle) as MetricsToggleQueryData;
        return (mState) ? mState.selectedMetrics : [];
    }

    selectedMetricsValueMap(data: BenchmarkData)
    {
        const map = new Map<string, number>();
        this.selectedMetrics.forEach((c) =>
        {
            const cind = this.capabilities.findIndex(v => v.name === c.name);
            if (cind >= 0 && data.metricValues.length > cind)
            {
                map.set(c.name, data.metricValues[cind]);
            }
        });
        return map;
    }

    get selectedMetric(): Capability | null
    {
        return (this.selectedMetrics.length) ? this.selectedMetrics[0] : null;
    }

    get selectedMetricIndex(): number
    {
        return this.capabilities.findIndex(v => v.name === this.selectedMetric?.name);
    }

    get capabilities()
    {
        const caps = [];
        for (const cat of this.dashboard.capabilities)
        {
            for (const cap of cat.capabilities)
            {
                caps.push(cap);
                for (const subcap of cap.subCapabilities)
                {
                    caps.push(subcap);
                }
            }
        }
        return caps;
    }

    get donutLabels()
    {
        return this.dashboard.capabilities.map(v => v.name);
    }

    donutData(data: BenchmarkData)
    {
        return data.categoryPercentages;
    }

    percentageMap(data: BenchmarkData)
    {
        const res = new Map<string, number>();
        const sortee: { name: string; value: number }[] = [];
        let cIndex = 0;
        this.dashboard.capabilities.forEach((cat) => cat.capabilities.forEach((cap) =>
        {
            sortee.push({ name: cap.name, value: data.metricValuePercentages[cIndex] });
            cIndex += 1 + cap.subCapabilities.length; // skip sub-capabilities
        }));
        sortee.sort((a, b) => (a.value < b.value ? 1 : (a.value > b.value ? -1 : 0)));
        for (let i = 0; i < 3 && i < sortee.length; ++i)
        {
            res.set(sortee[i].name, sortee[i].value);
        }
        return res;
    }

    // TODO: don't refresh on MetricToggle changes
    @Watch('filterState', { deep: true })
    async onNewFilters()
    {
        await this.refresh();
    }

    async refresh()
    {
        this.loading = true;
        try
        {
            const data = await dataSourceModule.queryBenchmarkPage({
                dashboard: await dashModule.getActiveDashboard(),
                pageIdx: this.index,
                filterValues: this.filterValues,
                pageData: { targetId: this.targetId }
            });
            
            if (data)
            {
                // console.log(data);
                this.$set(this, 'queryResult', data.data);
            }
            else
            {
                this.$set(this, 'queryResult', null);
            }
        }
        finally
        {
            this.loading = false;
        }
    }

    setDefaultValue(fieldType: DashboardPageFieldType, index: number)
    {
        const mState =
            this.filterState.find(fs => fs.type === fieldTypeToFilterType(fieldType)) as SelectFilterQueryData;
        if (mState)
        {
            mState.selectedOption = this.aggregationSettings[index].defaultValue;
        }
    }

    setDefaultValues()
    {
        this.aggregationFields.forEach((v, k) =>
        {
            this.setDefaultValue(v.type, k);
        });
    }

    async mounted()
    {
        this.setDefaultValues();
        await this.refresh();
    }
}

pageViewerComponents
    .registerComponent(DashboardPageType.Benchmark, Benchmarks)
    .registerDataFactory(DashboardPageType.Benchmark, ()=>new BenchmarkDashboardPage)
;
