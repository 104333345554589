const validationRules = {
    required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
    url: () => true,
    requiredOrOther: (other: string | null) => (value: string)=>
    {
        if (!!other && other.trim().length > 0) return true;
        return (!!value && value.trim().length > 0) || 'This field is required';
    }
};

export default validationRules;