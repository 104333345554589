














































import { Component, Vue } from 'vue-property-decorator';
import dashModule from '@/store/modules/DashboardModule';
import { Dashboard } from '../../../models/hcad/shared/dashboard';
import DashboardListDeleteDialogue from './DashboardListDeleteDialogue.vue';

@Component({components: {DashboardListDeleteDialogue}})
export default class DashboardList extends Vue
{
    deleteModalOpen = false;
    get dashboardHeaders()
    {
        return [
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Domain', value: 'targetDomain', sortable: true },
            { text: 'Controls', value: 'controls', sortable: false, width: "100px" },
        ];
    }

    loading = false;
    dashboardList: Dashboard[] = [];

    activateDashboard(dash: Dashboard)
    {
        this.$router.push(`/dashboard/${dash._id}`);
    }

    async createDashboard()
    {
        this.$router.push(`/admin/dashboard/new/`);
    }

    async editDashboard(dash: Dashboard)
    {
        this.$router.push(`/admin/dashboard/edit/${dash._id}`);
    }

    async deleteDashboard(dash: Dashboard)
    {
        console.log(dash);
        console.log(dash._id);
        await dashModule.deleteDashboard(dash._id);
        await this.refresh();
    }

    async refresh()
    {
        this.loading = true;
        this.dashboardList.length = 0;

        const dashes = await dashModule.getAllDashboards();
        if (dashes)
        {
            for(let i = 0; i < dashes.length; i++)
            {
                this.dashboardList.push(dashes[i]);
            }
        }
        else
        {
            console.log('No dashboards found');
        }

        this.loading = false;
    }

    async mounted()
    {
        await this.refresh();
    }
}
