





























import { Component, Vue, Prop } from 'vue-property-decorator';

import ValueBar from '@/components/ValueBar.vue';

@Component({
    components: {
        ValueBar
    }
})
export default class PercentageBars extends Vue
{
    /** label maps to value in range 0 - max */
    @Prop(Map) readonly value!: Map<string, number>;
    /** title goes above values */
    @Prop(String) readonly title!: string;
    /** any CSS supported value */
    @Prop(String) readonly width!: string;
    /** level of precision to display; default is 1 */
    @Prop({ default: 1 }) readonly decimals!: number;
    /** value to be treated as 100% */
    @Prop(Number) readonly max!: number;
    /** displayed value formatted with percent sign? */
    @Prop(Boolean) readonly noPercentSign!: boolean;

    get precisionMultiplier()
    {
        return 10 ** this.decimals;
    }

    roundedValue(v: number)
    {
        return Math.round(v * this.precisionMultiplier) / this.precisionMultiplier;
    }
}
