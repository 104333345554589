



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageType, BaseFilterQueryData } from '@/models/hcad/shared/dashboard';
import { pageViewerComponents } from '@/utils/typed-configs';
import { Dashboard } from '../../models/hcad/shared/dashboard';
import dashModule from '../../store/modules/DashboardModule';
import FilterSidebar from '@/components/FilterSidebar.vue';

// TODO: Automate this...?
require('./Benchmarks.vue');
require('./IndividualDataTable.vue');
require('./RoleBaselinePage.vue');
require('./AttritionAnalysisPage.vue');
require('./GroupDataTable.vue');

@Component({
    components: {
        FilterSidebar,
    }
})
export default class DashboardPage extends Vue
{
    @Prop({type: String, required: true})
    index!: string;

    get indexNum()
    {
        const int = Number.parseInt(this.index);
        if (isNaN(int)) return 0;
        return int;
    }

    filterState: BaseFilterQueryData[] = [];

    activeDashboardNullable: Dashboard | null = null;
    loading = true;

    async mounted()
    {
        this.loading = true;
        try
        {
            this.activeDashboardNullable = await dashModule.getActiveDashboard();
            await dashModule.getAllDefaultFiltersForActiveDashboard();
        }
        catch(er)
        {
            console.error(`Failed to load - ${er}`);
        }
        this.loading = false;
    }

    get errorString()
    {
        if (!this.activeDashboardNullable)
        {
            return 'No active dashboard.';
        }
        else if (this.activeDashboardNullable.pages.length === 0)
        {
            return 'Active dashboard has no pages configured.';
        }
        else if (!this.page)
        {
            return `No page registered for type ${this.typeString}. Please notify the developers of this error.`;
        }
        return '';
    }

    get page()
    {
        return ((this.activeDashboardNullable && this.activeDashboardNullable.pages.length > this.indexNum)
            ? this.activeDashboardNullable.pages[this.indexNum]
            : null);
    }

    get pageType()
    {
        return (this.page ? this.page.type : null);
    }

    get typeString()
    {
        return (this.pageType ? DashboardPageType[this.pageType] : null);
    }

    get viewerComponent()
    {
        return (this.pageType ? pageViewerComponents.getComponent(this.pageType) : null);
    }

    get capabilities()
    {
        return this.activeDashboardNullable ? this.activeDashboardNullable.capabilities : [];
    }

    get filterConfig()
    {
        return this.page ? this.page.filterConfig : {};
    }
}
