













































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Capability, ValueColor } from '@/models/hcad/shared/dashboard';
import DynamicIcon from '@/components/DynamicIcon.vue';
import dashModule from '@/store/modules/DashboardModule';

@Component({components: {DynamicIcon}})
export default class CapabilityIcon extends Vue
{
    @Prop({type: Object, required: true})
    readonly capability!: Capability;

    @Prop({type: Number, required: true})
    readonly count!: number;

    @Prop({type: Number, required: true})
    readonly value!: number;

    @Prop({type: Boolean})
    readonly forceMaxValueColor!: boolean;
    
    @Prop({type: Boolean})
    readonly hideValue!: boolean;

    @Prop({type: Boolean})
    readonly big!: boolean;

    bigSize = 50;

    get shouldRenderLarge()
    {
        return (
            // this.count <= 6 ||
            this.big
        );
    }

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get displayValue()
    {
        if (this.dashboard.allowFractionalCapabilityValues)
        {
            // Two decimal places
            return this.value.toFixed(2);
        }
        return Math.round(this.value).toString();
    }

    get capIconStyle()
    {
        let style = `background-color: ${this.bgColor};`;
        if (this.shouldRenderLarge)
        {
            style += `width: ${this.bigSize}px; height: ${this.bigSize}px;`;
        }
        return style;
    }

    get iconStyle()
    {
        if (this.shouldRenderLarge)
        {
            return `width: ${this.bigSize}px; height: ${this.bigSize}px;`;
        }
        return "width: 80%; height: 80%;";
    }

    get valueColoration() : ValueColor | null
    {
        // console.log(this.capability);
        if (this.capability.valueColors && this.capability.valueColors.length)
        {
            const valueColorsCopy = [...this.capability.valueColors];
            valueColorsCopy.sort((v1, v2) =>
            {
                return v2.maxValue - v1.maxValue;
            });

            if (this.forceMaxValueColor)
            {
                const maxColor = new ValueColor();
                maxColor.iconClass = this.capability.iconClass;
                maxColor.iconUrl = this.capability.iconUrl;
                maxColor.color = this.capability.color;
                maxColor.maxValue = Number.MAX_VALUE;
                return maxColor;
            }

            let vc = null;
            valueColorsCopy.forEach((v) =>
            {
                if (v.maxValue >= this.value)
                {
                    vc = v;
                }
            });
            return vc;
        }
        return null;
    }

    get capIconUrl()
    {
        if (this.valueColoration)
        {
            return this.valueColoration.iconUrl;
        }
        return this.capability.iconUrl;
    }

    get capIconClass()
    {
        if (this.valueColoration)
        {
            return this.valueColoration.iconClass;
        }
        return this.capability.iconClass;
    }

    get bgColor()
    {
        if (this.valueColoration)
        {
            return this.valueColoration.color;
        }
        else if (this.value === 0)
        {
            return '#E0E0E0';
        }
        return this.capability.color;
    }

    get fgColor()
    {
        // if (this.value === 0) return 'rgba(255, 255, 255, 0.4)';
        return 'white';
    }
}

