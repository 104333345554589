



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceConfig, Dashboard, SurnameSets, MockDataSourceCandidateGenerationConfig } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import MockDepartmentEdit from './MockDepartmentEdit.vue';

@Component({components: {MockDepartmentEdit}})
export default class MockDepartmentList extends Vue
{
    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Object, required: true})
    config!: MockDataSourceConfig;

    checkboxValues: boolean[] = [];

    get selectedSet()
    {
        const res = this.config.candidateGenerationConfig.selectedSurnameSets;
        if (res.length === 0) return ['mixed'];
        return res;
    }

    recomputeCheckboxValues()
    {
        this.checkboxValues = this.surnameSets.map((set) => this.config.candidateGenerationConfig.selectedSurnameSets.includes(set));
    }

    onCheckboxChange(set: string, value: boolean)
    {
        if (!value)
        {
            const idx = this.config.candidateGenerationConfig.selectedSurnameSets.indexOf(set);
            if (idx >= 0) this.config.candidateGenerationConfig.selectedSurnameSets.splice(idx, 1);
        }
        else
        {
            this.config.candidateGenerationConfig.selectedSurnameSets.push(set);
        }

        // sort and remove duplicates
        this.config.candidateGenerationConfig.selectedSurnameSets.sort();

        const deduped = this.config.candidateGenerationConfig.selectedSurnameSets.filter((v, i, a) => a.indexOf(v) === i);
        this.$set(this.config.candidateGenerationConfig, 'selectedSurnameSets', deduped);
        this.recomputeCheckboxValues();
    }

    get surnameSets() 
    {
        return SurnameSets;
    }

    validate()
    {
        return validateRefs(this.$refs);
    }

    hasFinishedLoading = false;
    created()
    {
        if (!this.config.candidateGenerationConfig)
        {
            this.config.candidateGenerationConfig = new MockDataSourceCandidateGenerationConfig();
            this.$set(this.config, 'candidateGenerationConfig', this.config.candidateGenerationConfig);
        }
        this.recomputeCheckboxValues();
        this.hasFinishedLoading = true;
    }
}
