import {
    BaseDashboardPage, 
    BaseDashboardPageField,
    BaseDashboardPageFilter, 
    BaseFilterQueryData, 
    Dashboard, 
    DashboardPageFieldType,
    DashboardPageFilterType, 
    DashboardPageType, 
    DataSourceConfig,
    FieldValueType,
    IndividualDataTableUserData
} from "@/models/hcad/shared/dashboard";
import { UserInfoAndMetrics } from "@/models/hcad/shared/queries";

import { VueConstructor } from "vue";


type TypedConfigFactory<T> = () => T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class TypedConfigSet<KeyT = any, DataT = any>
{
    getComponent(key: KeyT)
    {
        return this.components.get(key);
    }
    
    getDataFactory(key: KeyT)
    {
        return this.factories.get(key);
    }

    registerComponent(key: KeyT, component: VueConstructor<Vue>)
    {
        this.components.set(key, component);
        return this;
    }

    registerDataFactory(key: KeyT, factory: TypedConfigFactory<DataT>)
    {
        this.factories.set(key, factory);
        return this;
    }

    category<KT, DT>(name: string)
    {
        if (!this.categories.has(name))
        {
            this.categories.set(name, new TypedConfigSet<KT, DT>());
        }
        return this.categories.get(name) as TypedConfigSet<KT, DT>;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private factories = new Map<KeyT, TypedConfigFactory<DataT>>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private components = new Map<KeyT, VueConstructor<Vue>>();
    private categories = new Map<string, TypedConfigSet>();
}

const TypedComponentConfigs = new TypedConfigSet;
export default TypedComponentConfigs;

const dataSourceRoot = TypedComponentConfigs.category('datasource');
export const dataSourceEditComponents = dataSourceRoot.category<string, DataSourceConfig>('editors');

const pageRoot = TypedComponentConfigs.category('pages');
export const pageEditorComponents = pageRoot.category<DashboardPageType, BaseDashboardPage>('editors');
export const pageViewerComponents = pageRoot.category<DashboardPageType, BaseDashboardPage>('viewers');

const filterRoot = TypedComponentConfigs.category('filters');
export const filterEditorComponents = filterRoot.category<DashboardPageFilterType, BaseDashboardPageFilter>('editors');
export const filterRuntimeComponents = filterRoot.category<DashboardPageFilterType, BaseFilterQueryData>('viewers');

const fieldRoot = TypedComponentConfigs.category('fields');
export const fieldEditorComponents = fieldRoot.category<DashboardPageFieldType, BaseDashboardPageField>('editors');

// The data factory for fieldViewerComponents returns a sort function and toString function
export type FieldViewerContext = [[DashboardPageFieldType, FieldValueType][], UserInfoAndMetrics<IndividualDataTableUserData>];
export type FieldSortFn =
    (fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, b: FieldViewerContext) => number;
export type FieldToStringFn =
    (fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, dashboard: Dashboard) => string | Map<string, string>;
export type FieldViewerData = { toString: FieldToStringFn, sort: FieldSortFn };
export function getIndex(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext)
{
    // fieldIdx is unreliable because columns can be hidden and this is fed the table row
    // using fieldType instead will always be reliable (if slower)
    let index = fieldIdx;
    if (a[0].length <= fieldIdx || a[0][fieldIdx][0] !== fieldType)
    {
        index = a[0].findIndex((v) => v[0] === fieldType);
    }
    return index;
}
export function getValue(a: FieldViewerContext, index: number)
{
    return (index < 0 || index > a[0].length) ? undefined : a[0][index][1];
}
export const fieldViewerComponents = fieldRoot.category<DashboardPageFieldType, FieldViewerData>('viewers');