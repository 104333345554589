import { render, staticRenderFns } from "./DefaultContainer.vue?vue&type=template&id=83a0dfbe&scoped=true&"
import script from "./DefaultContainer.vue?vue&type=script&lang=ts&"
export * from "./DefaultContainer.vue?vue&type=script&lang=ts&"
import style0 from "./DefaultContainer.vue?vue&type=style&index=0&id=83a0dfbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83a0dfbe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VFooter,VMain,VOverlay,VProgressCircular})
