




























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class InfoButton extends Vue
{
    @Prop({type: String, required: true})
    readonly description!: string;

    @Prop({type: String, default: 'white'})
    readonly color!: string;

    get colorReal()
    {
        return this.color || 'white';
    }

    showFilterDescription = false;

    filterDescriptionFocused = false;

    onInfoButtonMouseLeave()
    {
        if (!this.filterDescriptionFocused) this.showFilterDescription = false;
    }
}
