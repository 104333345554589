



































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import auth from '@/store/modules/AuthModule';
import HeaderBar from '@/components/hcad/HeaderBar.vue';
import { Dashboard } from '../../models/hcad/shared/dashboard';
import dashModule from '../../store/modules/DashboardModule';
import activeEnvironment from '@/config/Environment';

@Component({components: {HeaderBar}})
export default class DefaultContainer extends Vue
{
    @Prop({ type: String, default: null })
    dashid!: string | null;

    activeDashboardNullable: Dashboard | null = null;

    get activeDashboard()
    {
        return this.activeDashboardNullable as Dashboard;
    }

    openDashboard(dash: Dashboard)
    {
        let url = '#/dashboard/' + dash._id;
        if (document.title !== 'Loading...'
        && document.title !== activeEnvironment.DefaultTabText
        && (this.activeDashboardNullable && !this.activeDashboard.environmentSettings.tabText))
        {
            url = `/?rnd=${Date.now()}&tabtext=` + document.title + url;
        }
        else
        {
            url = `/?rnd=${Date.now()}` + url;
        }
        window.open(url, '_self');
    }

    toggleDashboardList()
    {
        this.showDashboardList = !(this.showDashboardList);
    }

    get lowerLogo()
    {
        return ((this.activeDashboard && this.activeDashboard.environmentSettings.lowerLogo)
            ? this.activeDashboard.environmentSettings.lowerLogo
            : 'https://assets.redmeru.com/master/dashboard_icons/PoweredBy_Logo_BLADE.png');
    }

    get pagesToDisplay()
    {
        return this.activeDashboard.pages;
    }

    get busy_visual()
    {
        // Wrapper so that we can add other bits in here
        return this.busy;
    }

    get busy()
    {
        return this.loading;
    }

    get loggedIn()
    {
        return auth.isSignedIn;
    }

    get userName()
    {
        return auth.activeUser ? `${auth.activeUser.firstName} ${auth.activeUser.lastName}` : '';
    }

    async loadDashboard()
    {
        this.loading = true;
        if (this.dashid)
        {
            this.activeDashboardNullable = await dashModule.getDashboardById({ id: this.dashid, setActive: true });
        }
        else
        {
            this.activeDashboardNullable = await dashModule.getActiveDashboard();
        }

        this.loading = true;
    }

    showDashboardList = false;

    sidePanelItems = [
        { text: "Dashboards", header: true, selected: false },
        { text: "Recents", icon: "mdi-history" },
        { text: "Created by Me", icon: "mdi-pencil" },
        { text: "Private Dashboards", icon: "mdi-shield-outline" },
        {
            text: "All Dashboards",
            icon: "mdi-format-list-bulleted",
            showDashboardList: true
        },

        { text: "Folders", header: true },
        { text: "All Folders", icon: "mdi-folder-outline" },
        { text: "Created by Me", icon: "mdi-folder-edit" },
        { text: "Shared with Me", icon: "mdi-sync" },

        { text: "Favorites", header: true },
        { text: "All Favorites", icon: "mdi-star" },
    ];

    dashboardList: Dashboard[] = [];

    async loadDashboardList()
    {
        this.dashboardList.length = 0;
        const resp = await dashModule.getAllDashboards();
        if (resp)
        {
            for(const dash of resp)
            {
                // Ignore internal dashboards
                if (dash.internal) continue;

                this.dashboardList.push(dash);
            }
        }
    }

    loading = true;
    async mounted()
    {
        this.loading = true;
        try
        {
            await this.loadDashboardList();
            await this.loadDashboard();
        }
        catch(er)
        {
            console.error(`Failed to load - ${er}`);
        }
        this.loading = false;
    }
}
