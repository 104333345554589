





























































import { Component, Vue } from 'vue-property-decorator';
import { parse } from 'papaparse';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Row = any;
type SanitizationError = {field: string, err: string};
type RowError = {row: Row, errors: SanitizationError[]};

function errorListToObject(errList: SanitizationError[])
{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    for (const err of errList)
    {
        obj[err.field] = err.err;
    }
    return obj;
}

@Component
export default class CsvSanitizer extends Vue
{
    visible = false;
    visiblePromise: Promise<void> | null = null;
    visiblePromiseResolver: (() => void) | null = null;
    shouldIgnoreRest = false;
    errors: RowError[] = [
        // {row: {email: 'blardee@blar.bla', name: 'Blardee Blar', performance: 'Up'}, errors: [{field: 'performance', err: 'This is bullshit!'}]}
    ];
    
    get tableData()
    {
        return this.errors.map((e) => ({ ...e.row, errors: errorListToObject(e.errors), source: e }));
    }

    get tableHeaders()
    {
        const headers = new Set<string>();
        for(const err of this.errors)
        {
            for(const field of Object.keys(err.row))
            {
                headers.add(field);
            }
        }

        const res = [];
        for(const header of headers)
        {
            res.push({text: header, value: header});
        }
        return res;
    }

    get errorCsvLink()
    {
        let csv = '';
        const headers = this.tableHeaders;
        console.log({headerLen: headers.length});
        for(let i = 0; i < headers.length; ++i)
        {
            const header = headers[i];
            const append = `"${header.value}"` + ((i === headers.length - 1) ? '\n' : ',');
            csv += append;
        }
        for(const err of this.errors)
        {
            for(let i = 0; i < headers.length; ++i)
            {
                const header = headers[i];
                const append = `"${(err.row[header.value] || '').replace(/["]/g, '""')}"` + ((i === headers.length - 1) ? '\n' : ',');
                csv += append;
            }
        }
        return 'data:attachment/text,' + encodeURI(csv);
    }

    async waitForFixes()
    {
        this.visible = true;
        this.visiblePromise = new Promise((resolve) => this.visiblePromiseResolver = resolve);
        await this.visiblePromise;
        this.visible = false;
        this.visiblePromise = null;
        this.visiblePromiseResolver = null;
    }

    submitFixes()
    {
        if (this.visiblePromiseResolver)
        {
            this.visiblePromiseResolver();
        }
        else
        {
            alert('Error: Invalid fix submitted (nothing to fix)');
        }
    }

    cancelRemainingFixes()
    {
        this.shouldIgnoreRest = true;
        this.submitFixes();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sanitize(csvString: string, sanitizers: any, onSuccess: ((data: Row[]) => Promise<void>) | ((data: Row[]) => void), replacers?: any)
    {
        
        const intermediate = parse<Row>(csvString, { header: true, skipEmptyLines: true, transform: (value) => value.trim(), });
        const parsed = intermediate.data;
        if (intermediate.errors.length > 0)
        {
            throw new Error('Invalid CSV provided');
        }

        return this.sanitizeImpl(parsed, sanitizers, onSuccess, replacers);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async sanitizeImpl(parsed: Row[], sanitizers: any, onSuccess: ((data: Row[]) => Promise<void>) | ((data: Row[]) => void), replacers: any)
    {
        this.errors.length = 0;
        const success: Row[] = [];
        const fail: RowError[] = [];

        for(const row of parsed)
        {
            const localErrors: SanitizationError[] = [];
            for(const field of Object.keys(row))
            {
                const lowerField = field.trim().replace(/[_ ]/g, '').toLowerCase();
                if (sanitizers[lowerField])
                {
                    const err = sanitizers[lowerField](row[field]);
                    if (typeof err === 'string')
                    {
                        localErrors.push({field, err});
                    }
                }
            }

            if (localErrors.length === 0)
            {
                if (replacers)
                {
                    for (const field of Object.keys(row))
                    {
                        const lowerField = field.trim().replace(/[_ ]/g, '').toLowerCase();
                        if (replacers[lowerField])
                        {
                            const res = replacers[lowerField](row[field]);
                            if (res)
                            {
                                row[field] = res;
                            }
                        }
                    }
                }
                success.push(row);
            }
            else
            {
                fail.push({row, errors: localErrors});
            }
        }

        if (success.length > 0)
        {
            const res = onSuccess(success);
            if (res) await res;
        }

        if (fail.length > 0)
        {
            this.errors.push(...fail);
            await this.waitForFixes();
            if (!this.shouldIgnoreRest)
            {
                await this.sanitizeImpl(this.errors.map(a=>a.row), sanitizers, onSuccess, replacers);
            }
            this.shouldIgnoreRest = false;
        }
    }
}
