import { NavigationGuardNext, Route } from "vue-router";
import auth from '@/store/modules/AuthModule';

export async function requireNotLoggedIn<V extends Vue> (to: Route, from: Route, next: NavigationGuardNext<V>)
{
    await auth.init();
    if (auth.isSignedIn)
    {
        return next('/');
    }
    return next();
}

export async function requireLoggedIn<V extends Vue> (to: Route, from: Route, next: NavigationGuardNext<V>)
{
    await auth.init();
    if (auth.isSignedIn)
    {
        return next();
    }
    return next('/auth/login');
}

export async function requireIsAdmin<V extends Vue> (to: Route, from: Route, next: NavigationGuardNext<V>)
{
    // TODO: Implement admin checking logic
    return requireLoggedIn(to, from, next);
}