









































import { Component, Vue, Prop } from 'vue-property-decorator';
import RoleBaseline from '@/models/hcad/shared/role-baseline';
import DashboardGroup from '@/models/hcad/shared/dashboard-group';

// Note: This was componetized specifically for the computed properties.  It could stand to be reorganized a bit more cleanly.
@Component({components: {}})
export default class BaselineProgressBar extends Vue
{
    @Prop({type: Object, required: true})
    selectedBaselineOrGroup!: RoleBaseline | DashboardGroup;

    @Prop({type: Boolean})
    displayAssessed!: boolean;

    @Prop({type: Boolean})
    displayName!: boolean;

    @Prop({type: String})
    overrideTerm!:string | undefined;

    @Prop({type: Function})
    // eslint-disable-next-line @typescript-eslint/ban-types
    click!: Function | undefined;

    onClick()
    {
        if (this.click)
        {
            this.click();
        }
    }

    get invitedCount()
    {
        if (this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length)
        {
            return this.selectedBaselineOrGroup.members.filter(m => m.invited).length;
        }
        return 0;
    }

    get inviteProgress()
    {
        if (this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length)
        {
            // Same logic as computeInviteProgress, but using cached properties
            return 100 * this.invitedCount / this.selectedBaselineOrGroup.members.length;
        }
        return 0;
    }

    get assessedCount()
    {
        if (this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length)
        {
            return this.selectedBaselineOrGroup.members.filter(m => m.assessed).length;
        }
        return 0;
    }

    get baselineProgress()
    {
        if (this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length)
        {
            return 100 * this.assessedCount / this.selectedBaselineOrGroup.members.length;
        }
        return 0;
    }
}
