






import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, IndividualDataTableUserData, Dashboard } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerContext } from '../../../utils/typed-configs';
import CapabilityIcon from './CapabilityIcon.vue';

@Component({components: {CapabilityIcon}})
export default class CapabilitiesRenderer extends Vue
{
    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    get capabilityList()
    {
        return capabilityList(this.dashboard);
    }

    get metricValues()
    {
        return this.context.metrics.values;
    }
}

function capabilityList(dashboard: Dashboard)
{
    const res = [];
    for (const cat of dashboard.capabilities)
    {
        for (const cap of cat.capabilities)
        {
            res.push(cap);
            for (const subcap of cap.subCapabilities)
            {
                subcap.sub = true;
                res.push(subcap);
            }
        }
    }
    return res;
}

function toString(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, dashboard: Dashboard)
{
    const capabilities = capabilityList(dashboard);
    const values = a[1].metrics.values;
    const stringMap = new Map<string, string>();
    capabilities.forEach((c, index) =>
    {
        if (!(c.sub) && index > -1 && index < values.length)
        {
            if (dashboard.allowFractionalCapabilityValues)
            {
                stringMap.set(c.name, `${values[index].toFixed(2)}`);
            }
            else
            {
                stringMap.set(c.name, `${Math.round(values[index])}`);
            }
        }
    });
    return stringMap;
}

function sort(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, b: FieldViewerContext)
{
    const aval = a[1].metrics.values.reduce((c,d)=>c+d);
    const bval = b[1].metrics.values.reduce((c,d)=>c+d);
    return (aval) - (bval);
}

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.Capabilities, CapabilitiesRenderer)
    .registerDataFactory(DashboardPageFieldType.Capabilities, () => ({ toString, sort }));
