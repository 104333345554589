import { Capability } from './dashboard';
import { RoleBaselineFilterValueType } from './queries';

export default class FilterPreset
{
    _id = '';

    name = 'New Job Baseline';

    dashboard = '';

    company: string | null = null;

    department: string | null = null;

    function: string | null = null;

    jobCode: string | null = null;

    location: string | null = null;

    yearRange: [number, number] = [0, 30];

    selectedMetrics: Capability[] = [];

    baseline: RoleBaselineFilterValueType = {
        manual: false,
        value: { baseline: null, metrics: [] },
        broadenFitSlider: 0,
        cultureAddBroadenFitSlider: 0.3,
        combineMode: 'or'
    };

    isManual = false;

    updated: Date = new Date();
}
