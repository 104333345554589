


































































































































































import { Component, Vue, Prop, Watch, } from 'vue-property-decorator';
import { AdditiveForceArrayVisualizer } from 'shapjs';

import {
    DashboardPageType,
    BaseFilterQueryData,
    AttritionAnalysisData,
    AttritionAnalysisDashboardPage,
    DashboardPageSelectFilter,
} from '@/models/hcad/shared/dashboard';

import { pageViewerComponents } from '@/utils/typed-configs';
import dashModule from '@/store/modules/DashboardModule';
import dataSourceModule from '@/store/modules/DataSourceModule';
import ClassificationAnalysisColumn from './attrition-analysis-components/ClassificationAnalysisColumn.vue';
import { FilterValueType } from '@/models/hcad/shared/queries';

@Component({components: {ClassificationAnalysisColumn, AdditiveForceArrayVisualizer}})
export default class AttritionAnalysisPage extends Vue
{
    @Prop({type: Object, required: true})
    page!: AttritionAnalysisDashboardPage;

    @Prop({type: Number, required: true})
    index!: number;

    // unused
    @Prop({type: Array, required: true})
    filterState!: BaseFilterQueryData[];

    loading = false;
    queryResult: AttritionAnalysisData[] | null = null;

    additionalDetailsModalOpen = false;
    modalTarget = 0;

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get filterValues(): FilterValueType[]
    {
        return this.filterState.map(fs => fs.toValue());
    }

    violinPlotSrc: string | null = null;
    async openAdditionalDetailsModal(target: number)
    {
        this.violinPlotSrc = null;
        this.modalTarget = target;
        this.additionalDetailsModalOpen = true;

        if (this.queryResult && this.queryResult[target])
        {
            const temp = await dataSourceModule.getAttritionChartData({features: this.capabilities.map(a=>a.name), data: this.queryResult[target]});
            console.log('loaded value');
            console.log(temp);
            if (target === this.modalTarget)
            {
                console.log('assigned!');
                this.$set(this, 'violinPlotSrc', temp);
            }
        }
    }

    // get violinPlotSrc()
    // {
    //     if (this._violinPlotSrc) return this._violinPlotSrc;
    //     // if (this.queryResult && this.queryResult.length > this.modalTarget)
    //     // {
    //     //     return `data:image/png;base64,${this.queryResult[this.modalTarget].violinPlot}`;
    //     // }
    //     return '';
    // }

    get capabilities()
    {
        return this.dashboard.capabilities.flatMap(v=>v.capabilities);
    }

    get namedSignificant()
    {
        if (!(this.queryResult) || this.queryResult.length <= this.modalTarget)
        {
            return [];
        }
        const namedSignificant = this.queryResult[this.modalTarget].significantFeatures.map((f, i) =>
        {
            if (i >= this.capabilities.length) return { name: '', significant: f };
            return { name: this.capabilities[i].name, significant: f };
        });
        return namedSignificant;
    }

    get namedFactors()
    {
        if (!(this.queryResult) || this.queryResult.length <= this.modalTarget)
        {
            return [];
        }
        console.log(this.namedSignificant);
        const impacts = this.queryResult[this.modalTarget].featureImportance;
        console.log(impacts);
        const namedFactors = this.namedSignificant.map((v, index) =>
        {
            const result: { name: string, significant: boolean, impact: number } = Object.assign({ impact: 0 }, v);
            if (impacts.length > index)
            {
                result.impact = impacts[index];
            }
            return result;
        }).filter(v=>v.name);
        // console.log(namedFactors);
        namedFactors.sort((a, b) =>
        {
            // if (a.significant && !(b.significant)) return -1;
            // if (!(a.significant) && b.significant) return 1;
            return Math.abs(b.impact - 1) - Math.abs(a.impact - 1);
        });
        // hack to correct significance
        const firstSignificantIndex = namedFactors.findIndex(f=>f.significant);
        if (firstSignificantIndex >= 0)
        {
            for (let i = 0; i < firstSignificantIndex; ++i)
            {
                namedFactors[i].significant = true;
            }
            for (let i = 0; i < namedFactors.length; ++i)
            {
                if (namedFactors[i].impact === 1) namedFactors[i].significant = false;
            }
        }
        console.log(namedFactors);
        return namedFactors;
    }

    get significantCount()
    {
        return this.namedFactors.filter(v=>v.significant).length;
    }

    get generatedAdditionalText()
    {
        return `Almas’ machine learning algorithm identified that there were ${
            this.significantCount
        } statistically significant factors affecting employees in this attrition bracket.\n${
            this.namedFactors.filter(v=>!v.significant).length
        } index factors were confirmed as uncorrelated.\n${
            this.mostPositiveSignificantFactorText
        }${
            this.mostNegativeSignificantFactorText
        }${
            this.mostStatisticallySignificantFactorText
        }\n${
            this.positiveSignificantFactorsText
        }${
            this.negativeSignificantFactorsText
        }${
            this.neutralSignificantFactorsText
        }\n\nAll other index factors were confirmed as uncorrelated.`;
    }

    formatImpact(impact: number): number
    {
        return Math.round(impact * 1000) / 1000; // 3 decimals
    }

    get mostStatisticallySignificantFactorText()
    {
        if (!(this.namedFactors) || !(this.namedFactors.length))
        {
            return '';
        }
        const topFactor = this.namedFactors[0];
        if (!(topFactor.significant))
        {
            return '';
        }
        return `\n\t • The factor with the greatest degree of statistical significance was ${
            topFactor.name
        } and ${
            topFactor.impact > 1 ? 'increased' : 'decreased'
        } the odds of the outcome by ${this.formatImpact(topFactor.impact)} times.`;
    }

    get mostPositiveSignificantFactorText()
    {
        if (!(this.namedFactors) || !(this.namedFactors.length))
        {
            return '';
        }
        const topFactor = this.namedFactors.find(f => f.impact > 1 && f.significant);
        if (!topFactor)
        {
            return '';
        }
        return `\n\t • The factor with the greatest positive impact (practical significance) on the outcome was ${
            topFactor.name
        }.`;
    }

    get mostNegativeSignificantFactorText()
    {
        if (!(this.namedFactors) || !(this.namedFactors.length))
        {
            return '';
        }
        const topFactor = this.namedFactors.find(f => f.impact < 1 && f.significant);
        if (!topFactor)
        {
            return '';
        }
        return `\n\t • The factor with the greatest negative impact (practical significance) on the outcome was ${
            topFactor.name
        }.`;
    }

    get positiveSignificantFactorsText()
    {
        if (!(this.namedFactors) || !(this.namedFactors.length))
        {
            return '';
        }
        let text = '';
        this.namedFactors.forEach((factor) => 
        {
            if (factor.significant && factor.impact > 1) // TODO: non-neutral determiner?
            {
                text += `\n\t • ${
                    factor.name
                } increases the odds of this outcome by ${this.formatImpact(factor.impact)} times.`;
            }
        });
        return (text.length > 0) ? `${text}` : '';
    }

    get negativeSignificantFactorsText()
    {
        if (!(this.namedFactors) || !(this.namedFactors.length))
        {
            return '';
        }
        let text = '';
        this.namedFactors.forEach((factor) => 
        {
            if (factor.significant && factor.impact < 1) // TODO: non-neutral determiner?
            {
                text += `\n\t • ${
                    factor.name
                } decreases the odds of this outcome by ${this.formatImpact(factor.impact)} times.`;
            }
        });
        return (text.length > 0) ? `${text}` : '';
    }

    get neutralSignificantFactorsText()
    {
        if (!(this.namedFactors) || !(this.namedFactors.length))
        {
            return '';
        }
        let text = '';
        this.namedFactors.forEach((factor) => 
        {
            if (factor.significant
                && !(factor.impact > 1 || factor.impact < 1)) // TODO: neutral determiner?
            {
                text += `\n\t • ${
                    factor.name
                } impacts the odds of this outcome by ${this.formatImpact(factor.impact)} times.`;
            }
        });
        return (text.length > 0) ? `${text}` : '';
    }

    get insignificantFactorsText()
    {
        if (!(this.namedFactors) || !(this.namedFactors.length))
        {
            return '';
        }
        return `\nBased on the available data, ${
            this.namedFactors.filter(v=>!(v.significant)).map(v=>v.name).join(', ')
        } did not conclusively find a statistically significant influence on this outcome.`;
    }

    classificationName(index: number)
    {
        switch (index)
        {
        case 0: return 'STAYED < 2 YEARS';
        case 1: return 'STAYED 2-5 YEARS';
        case 2: return 'STAYED > 5 YEARS';
        }
    }

    @Watch('filterState', { deep: true })
    async onNewFilters()
    {
        await this.refresh();
    }

    async refresh()
    {
        console.log('get attrition analysis data');
        this.loading = true;
        try
        {
            const data = await dataSourceModule.queryAttritionAnalysisPage({
                dashboard: await dashModule.getActiveDashboard(),
                pageIdx: this.index,
                filterValues: this.filterValues,
            });
            console.log(data);
            
            if (data)
            {
                // console.log(data);
                this.$set(this, 'queryResult', data.data);
            }
            else
            {
                this.$set(this, 'queryResult', null);
            }
        }
        finally
        {
            this.loading = false;
        }
    }

    async mounted()
    {
        if (!(this.queryResult)) await this.refresh();

        const dash = await dashModule.getActiveDashboard();

        if (dash && this.queryResult && this.page.filterConfig.filterViewEnabled)
        {
            // pre-compute all single-filter options
            const values = new Array(this.page.filterConfig.filters.length).fill(null);
            const optionFetches = [];
            for (let f = 0; f < values.length; ++f)
            {
                const filter = this.page.filterConfig.filters[f] as DashboardPageSelectFilter;
                if (filter && filter.useValuesFromDataSource)
                {
                    optionFetches.push(dashModule.getDefaultFilterValuesForActiveDashboard(filter.type));
                }
                else if (filter)
                {
                    optionFetches.push(new Promise<Array<string>>((resolve) => resolve(filter.options)));
                }
                else
                {
                    optionFetches.push(new Promise<Array<string>>((resolve) => resolve([])));
                }
            }
            const options = await Promise.all(optionFetches);
            // console.log(options);
            // console.log(values);
            for (let f = 0; f < values.length; ++f)
            {
                const filter = this.page.filterConfig.filters[f] as DashboardPageSelectFilter;
                const filterOptions = options[f];
                if (filter && filterOptions && filterOptions.length)
                {
                    // console.log(filter);
                    // console.log(filterOptions);
                    for (let v = 0; v < filterOptions.length; ++v)
                    {
                        values[f] = filterOptions[v];
                        console.log(values);
                        dataSourceModule.queryAttritionAnalysisPage({
                            dashboard: dash,
                            pageIdx: this.index,
                            filterValues: [...values],
                        });
                    }
                    values[f] = null;
                }
            }
        }
    }
}

pageViewerComponents
    .registerComponent(DashboardPageType.AttritionAnalysis, AttritionAnalysisPage)
    .registerDataFactory(DashboardPageType.AttritionAnalysis, ()=>new AttritionAnalysisDashboardPage)
;
