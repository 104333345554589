










import { Component, Vue, Prop } from 'vue-property-decorator';
import { DoughnutChart } from 'vue-chart-3';

import { displayLength, truncate } from '@/utils/text-util';

@Component({
    components: {
        DoughnutChart,
    }
})
export default class Donut extends Vue
{
    @Prop(Array) readonly labels!: Array<string>;
    @Prop(Array) readonly data!: Array<number>;

    readonly colors1 = [
        'rgba(54, 182, 255, 1)',
        'rgba(217, 98, 115, 1)',
        'rgba(85, 187, 64, 1)',
        'rgba(157, 95, 12, 1)',
        'rgba(190, 52, 195, 1)',
    ];
    readonly colors2 = [
        'rgba(0, 0, 35, 0.8)',
        'rgba(35, 0, 0, 0.8)',
        'rgba(0, 35, 0, 0.8)',
        'rgba(30, 15, 10, 0.8)',
        'rgba(35, 0, 35, 0.8)',
    ];
    readonly colors3 = [
        'rgba(54, 182, 255, 0.75)',
        'rgba(187, 68, 85, 0.75)',
        'rgba(85, 187, 64, 0.75)',
        'rgba(157, 95, 12, 0.75)',
        'rgba(190, 52, 195, 0.75)',
    ];
    colorGradient(context: { dataIndex: number })
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const donutComponent: any = this.$refs.donut;
        const canvasEl: HTMLCanvasElement = donutComponent.canvasRef;
        const ctx: CanvasRenderingContext2D | null = canvasEl.getContext("2d");
        if (!ctx) return this.colors1[context.dataIndex % this.colors1.length];
        const gradient: CanvasGradient = ctx.createRadialGradient(
            canvasEl.width / 2.5, canvasEl.height / 2.5, canvasEl.height / 8,
            canvasEl.width / 2.5, canvasEl.height / 2.5, canvasEl.height);
        gradient.addColorStop(0, this.colors1[context.dataIndex % this.colors1.length]);
        gradient.addColorStop(0.5, this.colors2[context.dataIndex % this.colors2.length]);
        gradient.addColorStop(1, this.colors3[context.dataIndex % this.colors3.length]);
        return gradient;
    }

    get donutData()
    {
        return {
            labels: (this.labels && this.labels.length) ? this.labels : [
                'Motivational',
                'Intellectual',
                'Change'
            ],
            datasets: [{
                data: (this.data && this.data.length) ? this.data : [60, 30, 10],
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    offset: -30,
                },
                backgroundColor: this.colorGradient,
                borderColor: this.colorGradient,
                // borderWidth: [1, 9, 9],
                // borderWidth: 11,
                // offset: [0, 25, 45],
                borderRadius: 1,
                hoverOffset: 20,
                // hoverBorderWidth: 9,
                // rotation: 90,
                radius: 80,
            }]
        };
    }

    hoveredIndex = -1;

    get donutOptions()
    {
        return {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onHover: (e: Event, item: [{ index: number }]) =>
            {
                // console.log('hover');
                // console.log(e);
                // console.log(item);
                if (item.length > 0)
                {
                    this.hoveredIndex = item[0].index;
                }
                else
                {
                    this.hoveredIndex = -1;
                }
            },
            plugins: {
                legend: false,
                // tooltip: false,
                tooltip: {
                    callbacks: {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        label(tooltipItem: any)
                        {
                            return `${tooltipItem.label}: ${Math.round(tooltipItem.parsed * 10) / 10}%`;
                        }
                    }
                },
                datalabels: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any 
                    backgroundColor: (context: any) =>
                    {
                        return context.dataset.backgroundColor(context);
                    },
                    borderColor: 'rgba(255,255,255,0.25)',
                    borderRadius: 25,
                    borderWidth: 2,
                    color: 'white',
                    font: {
                        // weight: 'bold',
                        size: 11,
                    },
                    padding: 6,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    formatter: (v: number, context: any) => 
                    {
                        let name: string = context.chart.config.data.labels[context.dataIndex];
                        if (displayLength(name) > 12)
                        {
                            const truncatedName = truncate(name, 12);
                            if (name.length - truncatedName.length > 1) // ... is at least as long as 1 char
                            {
                                name = `${truncatedName}...`;
                            }
                        }
                        return ((this.hoveredIndex !== context.dataIndex)
                            ? `${name}: ${Math.round(v * 10) / 10}%`
                            : null);
                    },
                }
            },

            // Core options
            aspectRatio: 4 / 3,
            cutout: '35%',
            layout: {
                padding: 32,
            },
        };
    }
}
