





import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class DynamicIcon extends Vue
{
    /** custom image */
    @Prop(String) readonly iconUrl!: string;
    /** any Material icon supported value */
    @Prop(String) readonly iconClass!: string;
    /** any CSS supported value; currently just used by Material icons. */
    @Prop(String) readonly color!: string;

    @Prop({type: Number, default: 27}) readonly size!: number;
}
