













































import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseDashboardPage, DashboardPageType, Dashboard } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import { pageEditorComponents } from '@/utils/typed-configs';
import PageEdit from './PageEdit.vue';

@Component({components: { PageEdit }})
export default class PageList extends Vue
{
    @Prop({type: Array, required: true})
    pages!: BaseDashboardPage[];

    @Prop({type: Object, required: false})
    dashboard!: Dashboard | null;

    configReactivitySwitch = false;
    createModalOpen = false;
    selectedPageType = DashboardPageType.Invalid;
    keyOffset = 0;

    get pageTypeNames()
    {
        const opts: { text: string; value: DashboardPageType; disabled?: boolean }[] = 
            [{ text: 'Select a page type', value: DashboardPageType.Invalid, disabled: true }];
        
        for(let i = DashboardPageType.Invalid + 1; i < DashboardPageType.Count; ++i)
        {
            // Gets the names
            opts.push({ text: DashboardPageType[i], value: i });
        }
        return opts;
    }

    createPage()
    {
        const type = this.selectedPageType;
        const factory = pageEditorComponents.getDataFactory(type);
        if (factory)
        {
            // Create the page and push it into the list
            this.pages.push(factory());
            this.selectedPageType = DashboardPageType.Invalid;
            this.createModalOpen = false;
            ++this.keyOffset;
        }
        else
        {
            alert(`Error: No factory for page type ${DashboardPageType[type]}.  Please notify the developers of this error.`);
        }
    }

    copyPage(idx: number)
    {
        this.pages.push(BaseDashboardPage.clone(this.pages[idx]));
        ++this.keyOffset;
    }

    deletePage(idx: number)
    {
        this.pages.splice(idx, 1);
        ++this.keyOffset;
    }

    swapPages(a: number, b: number)
    {
        const aval = this.pages[a];
        const bval = this.pages[b];
        this.pages[a] = bval;
        this.pages[b] = aval;
        ++this.keyOffset;
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}
