



















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Gem from './Gem.vue';

@Component({
    components: {
        Gem,
    }
})
export default class GemDetails extends Vue
{
    @Prop(Boolean) readonly value!: boolean;
    @Prop(Array) readonly labels!: Array<string>;
    @Prop(Array) readonly data!: Array<number>;
    @Prop(Boolean) readonly useData2!: boolean;

    show = false;

    @Watch('value')
    onChangeValue()
    {
        this.show = this.value;
    }

    @Watch('show')
    onChangeShow()
    {
        this.$emit('input', this.show);
    }

    mounted()
    {
        this.show = this.value;
    }
}
