






















import { Component, Prop, Vue } from 'vue-property-decorator';

const _d1d3d4AsRGB : [number, number, number] = [0xd1 / 255, 0xd3 / 255, 0xd4 / 255];
const _a7a9acAsRGB : [number, number, number] = [0xa7 / 255, 0xa9 / 255, 0xac / 255];
const _6d6e71AsRGB : [number, number, number] = [0x6d / 255, 0x6e / 255, 0x71 / 255];

const sRGBtoLinear = (v: number) => v <= 0.04045 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
const linearTosRGB = (v: number) => v <= 0.0031308 ? v * 12.92 : 1.055 * v ** (1 / 2.4) - 0.055;

export const srgbToLinearColor = (rgb: [number, number, number]) : [number, number, number] =>
    rgb.map(sRGBtoLinear) as [number, number, number];

export const linearTosRGBColor = (rgb: [number, number, number]) : [number, number, number] =>
    rgb.map(linearTosRGB) as [number, number, number];

const hexStringToRGB = (hex: string): [number, number, number] =>
{
    if (hex.startsWith('#'))
    {
        hex = hex.slice(1);
    }
    
    if (hex.length === 3)
    {
        const val = parseInt(hex, 16);
        return [val, val, val];
    }

    if (hex.length !== 6 && hex.length !== 8)
    {
        return [hex.length, 0, 0];
    }
    
    const v1 = parseInt(hex.slice(0, 2), 16) / 255;
    const v2 = parseInt(hex.slice(2, 4), 16) / 255;
    const v3 = parseInt(hex.slice(4, 6), 16) / 255;
    
    // inverse srgb transform (gamma correction)
    return srgbToLinearColor([v1, v2, v3]);
};

const rgbToStyleString = (rgb: [number, number, number]) =>
    `rgb(${linearTosRGBColor(rgb).map(v => Math.round(v * 255)).join(',')}`;

// const rgbToHexString = (rgb: [number, number, number]) =>
//     '#' + rgb.map(v => Math.round(v * 255).toString(16).padStart(2, '0')).join('');

const mulColors = (a: [number, number, number], b: [number, number, number]) : [number, number, number] =>
    a.map((v, i) => v * b[i]) as [number, number, number];

let idGen = 0;

@Component
export default class SliderArrow extends Vue
{
    @Prop({type: Number, default: 32}) size!: number;
    @Prop({type: String, default: '#ffffff'}) color!: string;

    instanceId = 0;
    mounted()
    {
        this.instanceId = idGen++;
    }

    get baseColor()
    {
        return this.color;
    }

    get linearGradient1StartColor()
    {
        return rgbToStyleString(hexStringToRGB(this.baseColor) ?? [1,1,1].join(','));
    }

    get linearGradient1EndColor()
    {
        return rgbToStyleString(mulColors(hexStringToRGB(this.baseColor) ?? [1,1,1], _d1d3d4AsRGB));
    }

    get linearGradient2StartColor()
    {
        return rgbToStyleString(mulColors(hexStringToRGB(this.baseColor) ?? [1,1,1], _a7a9acAsRGB));
    }

    get linearGradient2EndColor()
    {
        return rgbToStyleString(mulColors(hexStringToRGB(this.baseColor) ?? [1,1,1], _6d6e71AsRGB));
    }

    get poly1Style()
    {
        // Convert above linear gradient to a CSS style gradient, including the transform
        return {
            fill: `url(#${this.instanceId}-linear-gradient)`,
            transform: 'translate(0, 108) scale(1, -1)',
            'stroke-width': '0px',
        };
    }

    get poly2Style()
    {
        // Convert above linear gradient to a CSS style gradient, including the transform
        return {
            fill: `url(#${this.instanceId}-linear-gradient-2)`,
            transform: 'translate(0, 108) scale(1, -1)',
            'stroke-width': '0px',
        };
    }
}
