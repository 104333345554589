


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageRangeFilter, DashboardPageFilterType } from '@/models/hcad/shared/dashboard';
import { filterEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';

@Component
export default class RangeConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageRangeFilter;

    refreshVals()
    {
        this.minValue = `${this.filter.min}`;
        this.maxValue = `${this.filter.max}`;
    }

    mounted()
    {
        this.refreshVals();
    }

    onNumFieldBlur()
    {
        if (this.minValue.length == 0)
        {
            this.minValue = '0';
        }

        if (this.maxValue.length == 0)
        {
            this.maxValue = '0';
        }

        const parsedMin = Number.parseFloat(this.minValue);
        const parsedMax = Number.parseFloat(this.maxValue);

        if (!Number.isNaN(parsedMin))
        {
            this.filter.min = parsedMin;
        }
        if (!Number.isNaN(parsedMax))
        {
            this.filter.max = parsedMax;
        }
        this.refreshVals();
    }

    minValue = '';
    maxValue = '';

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=>
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    validate()
    {
        return validateRefs(this.$refs);
    }
}

filterEditorComponents
    .registerComponent(DashboardPageFilterType.YearsEmployedRange, RangeConfigEdit)
    .registerDataFactory(DashboardPageFilterType.YearsEmployedRange, ()=>new DashboardPageRangeFilter(DashboardPageFilterType.YearsEmployedRange, 0, 10, 'Years Employed', 'years'))
;
