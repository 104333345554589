import User from "@/models/User";
export const dashboardAuthorizedRoles = ['owner', 'developer', 'blade', 'admin-content', 'admin', 'manager'];
export const dashboardAdminAuthorizedRoles = ['owner', 'developer'];
export function hasAnyRoles(user: User, roles: string[]): boolean
{
    return roles.some(role=> user.roles.some(({_id})=>_id === role));
}
export function isHcadUser(user: User): boolean
{
    if (user?.forceCanViewDashboards) return true;
    return hasAnyRoles(user, dashboardAuthorizedRoles);
}
export function isHcadAdmin(user: User): boolean
{
    return hasAnyRoles(user, dashboardAdminAuthorizedRoles);
}