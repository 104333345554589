





import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageType, Dashboard, DashboardGroupManagerDashboardPage } from '@/models/hcad/shared/dashboard';
import { pageEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';
import FieldList from '../FieldList.vue';

@Component({ components: { FieldList } })
export default class DashboardGroupManagerPageConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    page!: DashboardGroupManagerDashboardPage;

    @Prop({type: Object, required: false})
    dashboard!: Dashboard;

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}

pageEditorComponents
    .registerComponent(DashboardPageType.DashboardGroupManager, DashboardGroupManagerPageConfigEdit)
    .registerDataFactory(DashboardPageType.DashboardGroupManager, ()=>new DashboardGroupManagerDashboardPage)
;
