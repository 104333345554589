/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */

export function assetBasePath() 
{
    return 'https://assets.redmeru.com/';
}
  
export function defaultEnvironmentBasePath() 
{
    return `https://assets.redmeru.com/${process.env.DEFAULT_ASSET_ENVIRONMENT || 'master'}`;
}
  
export function resolveAssetPath(path: string, oldBaseDir: string) 
{
    if (!path || path === '' || path.startsWith('https://')) 
    {
        return encodeURI(path);
    }
    return `${defaultEnvironmentBasePath()}/${oldBaseDir}/${path}`;
}
  
export function resolvedAssetPathToRelativePath(path: string) 
{
    const base = assetBasePath();
    const p = path.substring(base.length + 1);
    const idx = p.indexOf('/');
    if (idx < 0) return undefined;
    return p.substring(idx + 1);
}

// Not bothering with preloading logic