























































































































// eslint-disable-next-line no-unused-vars
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import dashModule from '@/store/modules/DashboardModule';
import { questions, capabilityNameToQuestionsMap } from '@/copy/interview-questions';
import { IndividualDataTableDashboardPage } from '@/models/hcad/shared/dashboard';

@Component
export default class InterviewGuide extends Vue
{
    @Prop({ type: String, required: true })
    readonly defaultCapability!: string;

    @Prop({type: Object, required: true})
    page!: IndividualDataTableDashboardPage;

    selectedCapabilityIndex = 0;
    selectedQuestionIndex = 0;

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get interviewQuestionsLabel()
    {
        if (this.page.interviewQuestionLabelOverride && this.page.interviewQuestionLabelOverride.trim().length > 0)
        {
            return this.page.interviewQuestionLabelOverride;
        }
        return 'Interview Questions';
    }

    get capabilities()
    {
        return this.dashboard.capabilities.flatMap(v=>v.capabilities);
    }

    get selectedCapability()
    {
        return ((this.selectedCapabilityIndex >= 0 && this.selectedCapabilityIndex < this.capabilities.length)
            ? this.capabilities[this.selectedCapabilityIndex]
            : null);
    }

    get selectedCapabilityName()
    {
        return this.selectedCapability ? this.selectedCapability.name : '';
    }

    get interviewQuestions()
    {
        if (this.selectedCapabilityName)
        {
            const iqKeys = capabilityNameToQuestionsMap.get(this.selectedCapabilityName);
            if (iqKeys)
            {
                return iqKeys.map((iqKey) => 
                {
                    const questionHTML = questions.get(iqKey);
                    return questionHTML ? questionHTML : '';
                });
            }
        }
        return [];
    }

    @Watch('selectedCapabilityIndex')
    async onSelectedCapabilityIndexChanged() // fixes loss of slider on question tab when changing capability tab
    {
        this.selectedQuestionIndex = -1;
        await Vue.nextTick();
        this.selectedQuestionIndex = 0;
    }

    beforeMount()
    {
        this.selectedCapabilityIndex = this.capabilities.findIndex(c => c.name === this.defaultCapability);
    }
}
