import { RouteConfig } from 'vue-router';
import PassthroughContainer from '@/views/containers/PassthroughContainer.vue';
import LoginView from '@/views/auth/LoginView.vue';
import { requireNotLoggedIn } from '../middleware/auth';

const authRoutes: Array<RouteConfig> = [
    {
        path: '/auth',
        name: 'authRoot',
        component: PassthroughContainer,
        beforeEnter: requireNotLoggedIn,

        children: [
            {
                path: 'login',
                component: LoginView
            }
        ]
    }
];

export default authRoutes;