





import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageType, Dashboard, GroupDataTableDashboardPage } from '@/models/hcad/shared/dashboard';
import { pageEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';
import FieldList from '../FieldList.vue';

@Component({ components: { FieldList } })
export default class GroupDataTablePageConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    page!: GroupDataTableDashboardPage;

    @Prop({type: Object, required: false})
    dashboard!: Dashboard;

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}

pageEditorComponents
    .registerComponent(DashboardPageType.GroupDataTable, GroupDataTablePageConfigEdit)
    .registerDataFactory(DashboardPageType.GroupDataTable, ()=>new GroupDataTableDashboardPage)
;
