









































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { resolveAssetPath, resolvedAssetPathToRelativePath } from '@/utils/asset-util';

import assetModule from '@/store/modules/AssetModule';
import AssetSelectInputDirectoryEntry from './assets/AssetSelectInputDirectoryEntry.vue';

@Component({components: {Directory: AssetSelectInputDirectoryEntry}})
export default class AssetSelectInput extends Vue
{
    @Prop({type: Boolean})
    readonly autofocus!: boolean;

    @Prop({type: Boolean})
    readonly disabled!: boolean;

    @Prop({type: String, required: false})
    readonly label!: string | null;

    @Prop({type: String, default: ''})
    readonly value!: string;

    get getEnabledAssetManifests()
    {
        return assetModule.getEnabledAssetManifests;
    }

    // Data
    filter = '';
    modalVisible = false;
    hierarchy: string[] = [];

    // Methods
    isLegacyAsset() 
    {
        return this.value && this.value !== '' && !this.value.startsWith('https://assets.redmeru.com');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleClick(assetObj: any)
    {
        if (assetObj.LocalPath === this.localValuePath) return;
        this.$emit('input', assetObj.URL);
        this.modalVisible = false;
    }

    isAssetImage(asset: string) 
    {
        const lower = asset.toLowerCase();
        return lower.endsWith('png') || lower.endsWith('jpg') || lower.endsWith('jpeg');
    }

    isAssetVideo(asset: string) 
    {
        const lower = asset.toLowerCase();
        return lower.endsWith('mov') || lower.endsWith('mp4');
    }

    getIconFromAsset(asset: string) 
    {
        if (this.isAssetImage(asset)) return 'fa-file-image';
        if (this.isAssetVideo(asset)) return 'fa-file-video';
        return 'fa-file';
    }

    resetHierarchy() 
    {
        this.hierarchy.splice(0, this.hierarchy.length);
    }

    popToIndex(idx: number)
    {
        this.hierarchy.splice(idx + 1, this.hierarchy.length);
    }

    show() 
    {
        this.filter = '';
        const localPath = this.localValuePath;
        this.resetHierarchy();

        if (localPath && localPath !== '') 
        {
            const split = localPath.split('/');
            for (let i = 0; i < split.length - 1; ++i) 
            {
                this.hierarchy.push(split[i]);
            }
            this.$nextTick(() => 
            {
                if (this.$refs.container && this.$refs.scrollTarget)
                {
                    setTimeout(() => 
                    {
                        (this.$refs.container as HTMLElement).scrollTop = (this.$refs.scrollTarget as HTMLDivElement[])[0].scrollHeight;
                        console.log('scrolled');
                    }, 0);
                }
            });
        }
        this.modalVisible = true;
    }

    // Computed
    get resolvedValuePath() 
    {
        // Not supporting legacy assets here
        return resolveAssetPath(this.value, /* this.legacyManifestPath */ '');
    }
    
    get shortenedValuePath() 
    {
        const arr = this.resolvedValuePath.split('/');
        return arr[arr.length - 1];
    }

    get localValuePath() 
    {
        return resolvedAssetPathToRelativePath(this.resolvedValuePath);
    }

    get displayValue() 
    {
        return this.localValuePath;
    }

    get currentPath() 
    {
        return this.hierarchy.join('/');
    }

    get currentFolders() 
    {
        return assetModule.listDirectoriesAtPath(this.currentPath);
    }

    get currentAssets() 
    {
        return assetModule.listAssetsAtPath(this.currentPath);
    }

    @Watch('value')
    watchValue()
    {
        if (this.isLegacyAsset()) 
        {
            this.$emit('input', this.resolvedValuePath);
        }
    }
}
