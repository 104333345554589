

















































































































































































































































































































































































/*eslint-disable no-unused-vars */
import { Component, Vue, Prop } from 'vue-property-decorator';
import { RadarChart } from 'vue-chart-3';
import ElementQueries from 'css-element-queries';
import dashModule from '@/store/modules/DashboardModule';

function remapValue(val: number, min: number, max: number)
{
    return (val - min) / (max - min);
}

let globalIdx = 0;

@Component({
    components: {
        RadarChart,
    }
})
export default class Gem extends Vue
{
    @Prop(Array) readonly labels!: Array<string>;
    @Prop({ type: Array }) readonly data!: Array<number>;
    @Prop({ type: Array }) readonly data2!: Array<number>;
    @Prop(Boolean) readonly tableMode!: boolean;
    @Prop(Boolean) readonly darkOverlay!: boolean;
    @Prop(Boolean) readonly noTooltips!: boolean;
    @Prop(Boolean) readonly invisibilityFix!: boolean;
    @Prop(Boolean) readonly detailsMode!: boolean;
    @Prop(Boolean) readonly forceDetailModeScale!: boolean;
    @Prop(Boolean) readonly clickable!: boolean;
    @Prop(Boolean) readonly forceOverlayAlwaysVisible!: boolean;
    @Prop(Boolean) readonly displayClickHintOnHover!: boolean;
    @Prop(Boolean) readonly noOverlayIcons!: boolean;
    @Prop(Boolean) readonly disableHover!: boolean;
    
    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    textTestElement: SVGTextElement | null = null;

    computeTextLength(text: string)
    {
        const el = this.$refs['text-test-element'] as SVGTextElement | undefined;
        if (!el) 
        {
            console.warn('Gem could not find text test element');
            return 0;
        }

        el.textContent = text;
        return el.getComputedTextLength();
    }

    get capabilityList()
    {
        const caps = [];
        for(const cat of this.dashboard.capabilities)
        {
            for (const capability of cat.capabilities)
            {
                caps.push(capability);
            }
        }
        return caps;
    }

    calcIconBackground(idx: number)
    {
        return this.capabilityList[idx].color;
    }

    doesIconUseURL(idx: number)
    {
        return !!this.capabilityList[idx].iconUrl;
    }

    calcIconHref(idx: number)
    {
        return this.capabilityList[idx].iconUrl || '';
    }

    calcIconClass(idx: number)
    {
        return this.capabilityList[idx].iconClass || '';
    }

    calcIconColor(idx: number)
    {
        return this.capabilityList[idx].color || 'white';
    }

    maskIdx = 0;
    resizeSensor: ElementQueries.ResizeSensor | null = null;
    svgWidth = 0;
    svgHeight = 0;

    startingTris = [[0,0],[0,0],[0,0]]
    prevDataTrisInternal: number[][] | null = null;
    prevData2TrisInternal: number[][] | null = null;

    get prevDataTris()
    {
        if (this.prevDataTrisInternal) return this.prevDataTrisInternal;
        return this.startingTris;
    }

    vData2Tris()
    {
        if (this.prevData2TrisInternal) return this.prevData2TrisInternal;
        return this.startingTris;
    }

    get prevDataTriStr()
    {
        return this.prevDataTris.map(tri=>tri.join(','));
    }

    get prevData2TriStr()
    {
        return '';//this.prevData2Tris.map(tri=>tri.join(','));
    }

    get shouldOverlayBeWhite()
    {
        return !this.darkOverlay && (this.tableMode || !this.detailsMode);
    }

    get shouldUseFullHoverOverlay()
    {
        return true;//!this.tableMode || this.forceOverlayAlwaysVisible;
    }

    isOverlayHovered = false;

    get hasDataAndData2()
    {
        return this.data && this.data.length && this.data2 && this.data2.length;
    }

    get detailDisplayValues()
    {
        if (this.data)
        {
            return this.data.map(n => Math.round(n * 10) / 10);
        }
        return this.data2.map(n => Math.round(n * 10) / 10);
    }

    computeTextAlign(pt: number[])
    {
        const x = pt[0] / this.svgWidth;
        if (Math.abs(x) < 0.001)
        {
            return 'middle';
        }
        else if (x < 0)
        {
            return 'end';
        }
        return 'start';
    }

    get scaleRatio()
    {
        const w = this.svgWidth;
        const h = this.svgHeight;

        // Constants based on iteration
        if (w < h)
        {
            return Math.max(w / 844, 0.0001);
        }
        return Math.max(h / 407, 0.0001);
    }

    sign(n: number)
    {
        return Math.sign(n);
    }

    sqr(n : number)
    {
        return n * n;
    }

    min(a : number, b: number)
    {
        return Math.min(a, b);
    }

    normalize(pt: number[], tgLen = 1)
    {
        const len = Math.sqrt(pt[0] * pt[0] + pt[1] * pt[1]);
        return [pt[0] / len * tgLen, pt[1] / len * tgLen];
    }

    get stopOpacity()
    {
        // As of the time of implementation, this means that it is semitransparent ONLY in the group optimization view.
        return (this.tableMode && !this.forceDetailModeScale) ? 0.8 : 1;
    }

    get minValue()
    {
        let val = 0;
        // for(const dat of this.data)
        // {
        //     if (dat < val)
        //     {
        //         val = dat;
        //     }
        // }

        // for(const dat of this.data2)
        // {
        //     if (dat < val)
        //     {
        //         val = dat;
        //     }
        // }
        return val;
    }

    get maxValue()
    {
        let val = 9;
        // for(const dat of this.data)
        // {
        //     if (dat > val)
        //     {
        //         val = dat;
        //     }
        // }

        // for(const dat of this.data2)
        // {
        //     if (dat > val)
        //     {
        //         val = dat;
        //     }
        // }
        return val;
    }

    computeExteriorPoints(dataSource: number[])
    {
        const scale = 0.9;
        const res: number[][] = [];
        
        const min = this.minValue;
        const max = this.maxValue;

        // Pulling this out as a slight optimization
        for(let i = 0; i < dataSource.length; ++i)
        {
            const theta = i / dataSource.length * Math.PI * 2 + Math.PI * 0.9;

            const perc0 = remapValue(dataSource[i], min, max);
            const x0Norm = -Math.sin(theta) * perc0 * scale;
            const y0Norm = Math.cos(theta) * perc0 * scale;
            
            res.push([x0Norm, y0Norm]);
        }
        return res;
    }

    computeUnscaledTrianglesFromPoints(points: number[][])
    {
        const res: number[][][] = [];
        for(let i = 0; i < points.length; ++i)
        {
            const ii = (i + 1) % points.length;
            
            const x0Norm = points[i][0];
            const y0Norm = points[i][1];

            const x1Norm = points[ii][0];
            const y1Norm = points[ii][1];
            
            res.push([[0,0], [x0Norm, y0Norm], [x1Norm, y1Norm]]);
        }
        return res;
    }

    computeUnscaledTriangles(dataSource: number[])
    {
        return this.computeUnscaledTrianglesFromPoints(this.computeExteriorPoints(dataSource));
    }

    get dataPoints()
    {
        const width = Math.min(this.svgWidth, this.svgHeight) / 2;
        const height = width;

        const unscaledPoints = this.computeExteriorPoints(this.data);
        
        const res = unscaledPoints.map(point=>[point[0] * width, point[1] * height]);
        return res;
    }
    
    get data2Points()
    {
        const width = Math.min(this.svgWidth, this.svgHeight) / 2;
        const height = width;

        const unscaledPoints = this.computeExteriorPoints(this.data2);
        
        const res = unscaledPoints.map(point=>[point[0] * width, point[1] * height]);
        return res;
    }

    get detailPoints()
    {
        if (this.data && this.data.length) return this.dataPoints;
        return this.data2Points;
    }

    get detailTriangles()
    {
        if (this.data && this.data.length) return this.dataTriangles;
        return this.data2Triangles;
    }

    get normalizedDetailPoints()
    {
        return this.detailPoints.map(pt=>this.normalize(pt));
    }

    get fullOverlayInnerCirclePoints()
    {
        const scale = 0.3;
        return this.detailPoints.map(pt=>[pt[0] * scale, pt[1] * scale]);
    }

    get fullOverlayInnerCircleTriangles()
    {
        return this.computeUnscaledTrianglesFromPoints(this.fullOverlayInnerCirclePoints).map(tris=>tris.join(','));
    }

    get fullOverlayOuterPoints()
    {
        const intermediate = this.fullOverlayInnerCirclePoints.map((pt, idx)=>
        {
            const obj = {pt: [Math.sign(pt[0]) * this.svgWidth / 2 * 0.5, pt[1]], idx, nrm: pt};
            obj.nrm = this.normalize(pt);
            return obj;
        });

        const sorter = (a: typeof intermediate[0], b: typeof intermediate[0])=> 
        {
            const val = a.pt[1] - b.pt[1];
            if (Math.abs(val) < this.svgHeight / 30) return a.nrm[1] - b.nrm[1];
            return val;
        };

        const left = intermediate.filter(pt=>pt.pt[0] < 0).sort(sorter);
        const right = intermediate.filter(pt=>pt.pt[0] >= 0).sort(sorter);

        const computeYValues = (tab: typeof left) =>
        {
            const increment = this.svgHeight / tab.length;
            for(let i = 0; i < tab.length; ++i)
            {
                tab[i].pt[1] = (increment * i - this.svgHeight / 2) * 0.8;
            }
        };
        
        computeYValues(left);
        computeYValues(right);

        const clampXValues = (tab: typeof left) =>
        {
            if (tab.length === 0) return;

            const ref = tab[0].pt[0];
            const maxLen = tab.map(a=>
            {
                if (this.noOverlayIcons)
                {
                    return this.computeTextLength(`${this.labels[a.idx]}: ${this.detailDisplayValues[a.idx]}`);
                }
                const numberWidth = this.computeTextLength(`${this.detailDisplayValues[a.idx]}`);
                const numberInnerPadding = 5;
                const iconWidth = 32;
                const iconInnerPadding = 32;
                const labelWidth = this.computeTextLength(`${this.labels[a.idx]}`);
                const labelInnerPadding = 10;

                return labelWidth + labelInnerPadding + numberWidth + numberInnerPadding + iconWidth + iconInnerPadding;
            }).reduce((a, b)=>Math.max(a, b));
            const bound = this.svgWidth / 2;
            const furthestPoint = Math.abs(ref) + maxLen + 10;
            const offset = Math.min(Math.max(furthestPoint - bound, 0) * 1.5, (bound - maxLen));

            for(let i = 0; i < tab.length; ++i)
            {
                tab[i].pt[0] -= Math.sign(ref) * offset;
            }

            // console.log({ref, maxLen, bound, furthestPoint, offset});
        };

        clampXValues(left);
        clampXValues(right);

        return left.concat(right).sort((a,b)=>a.idx - b.idx).map(val=>val.pt);
    }

    get fullOverlayOuterLines()
    {
        const innerPoints = this.fullOverlayInnerCirclePoints;
        const outerPoints = this.fullOverlayOuterPoints;

        const res = [];
        for(let i = 0; i < innerPoints.length; ++i)
        {
            const intermediate = [outerPoints[i][0] * 0.5, outerPoints[i][1]];
            res.push([innerPoints[i], intermediate]);
            res.push([intermediate, outerPoints[i]]);
        }
        // console.log('A//////');
        // console.log(innerPoints);
        // console.log(outerPoints);
        // console.log(res);
        // console.log('B\\\\\\\\\\');
        return res;
    }

    get fullOverlayInnerCircleLines()
    {
        const res: number[][][] = [];
        const points = this.fullOverlayInnerCirclePoints;

        for(let i = 0; i < points.length; i++)
        {
            const ii = (i + 1) % points.length;
            const p0 = points[i];
            const p1 = points[ii];
            res.push([p0, p1]);
        }
        return res;
    }

    get dataTriangles()
    {
        const width = Math.min(this.svgWidth, this.svgHeight) / 2;
        const height = width;

        const unscaledTriangles = this.computeUnscaledTriangles(this.data);
        return unscaledTriangles.map(tri=>tri.map(point=>[point[0] * width, point[1] * height]).join(','));
    }

    get data2Triangles()
    {
        const width = Math.min(this.svgWidth, this.svgHeight) / 2;
        const height = width;

        const unscaledTriangles = this.computeUnscaledTriangles(this.data2);
        return unscaledTriangles.map(tri=>tri.map(point=>[point[0] * width, point[1] * height]).join(','));
    }
    
    get dataOutline()
    {
        const width = Math.min(this.svgWidth, this.svgHeight) / 2;
        const height = width;

        const unscaledTriangles = this.computeUnscaledTriangles(this.data);
        return unscaledTriangles.map(tri=>tri.slice(1).map(point=>[point[0] * width, point[1] * height]));
    }

    get dataInline()
    {
        const width = Math.min(this.svgWidth, this.svgHeight) / 2;
        const height = width;

        const unscaledTriangles = this.computeUnscaledTriangles(this.data);
        return unscaledTriangles.map(tri=>tri.slice(0, 2).map(point=>[point[0] * width, point[1] * height]));
    }

    get data2Outline()
    {
        const width = Math.min(this.svgWidth, this.svgHeight) / 2;
        const height = width;

        const unscaledTriangles = this.computeUnscaledTriangles(this.data2);
        return unscaledTriangles.map(tri=>tri.slice(1).map(point=>[point[0] * width, point[1] * height]));
    }

    get data2Inline()
    {
        const width = Math.min(this.svgWidth, this.svgHeight) / 2;
        const height = width;

        const unscaledTriangles = this.computeUnscaledTriangles(this.data2);
        return unscaledTriangles.map(tri=>tri.slice(0, 2).map(point=>[point[0] * width, point[1] * height]));
    }

    showRadar = true;


    get parentStyles()
    {
        return {
            position: 'relative',
            'min-height': '78px',
            'min-width': '85px',
            margin: '0 auto',
            height:
                (this.forceDetailModeScale)
                    ? '225px'
                    : ((this.detailsMode)
                        ? '550px'
                        : ((this.invisibilityFix)
                            ? '78px'
                            : ((this.tableMode)
                                ? '120px'
                                // -48px to account for the fixed label in the attrition columns
                                : 'calc(30vh - 48px)'))),
            width:
                (this.detailsMode || this.forceDetailModeScale)
                    ? '100%'
                    : ((this.invisibilityFix)
                        ? undefined
                        : ((this.tableMode)
                            ? '120px'
                            : '100%')),
            cursor: this.clickable ? 'pointer' : undefined,
            "background-color": (this.tableMode || this.detailsMode)
                ? 'transparent'
                : 'rgba(65,65,65,1)'
        // Casting to any to prevent the Vetur template error
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any;
    }

    loaded = false;
    async mounted()
    {
        this.maskIdx = ++globalIdx;

        let ticks = 0;
        while (this && (!this.$refs.svg || !this.$refs['text-test-element']))
        {
            if (ticks > 100) break;
            await this.$nextTick();
            ++ticks;
        }

        this.loaded = true;
        this.resizeSensor = new ElementQueries.ResizeSensor(this.$refs.svg as Element, ()=>
        {
            this.svgWidth = (this.$refs.svg as HTMLDivElement).clientWidth;
            this.svgHeight = (this.$refs.svg as HTMLDivElement).clientHeight;
        });
    }
}
