// This is just kind of a convenience thing
import Axios from 'axios';
import { options, postProcessInstance } from '../config/AxiosOptions';

const axios = Axios.create(
    options
);

postProcessInstance(axios);

export default axios;