import axios from '@/utils/axios';
import { VuexModule, Module, Action } from 'vuex-class-modules';
import { Dashboard } from '@/models/hcad/shared/dashboard';

@Module
class DashboardGroupModule extends VuexModule
{
    @Action
    async getById(id: string)
    {
        try
        {
            const resp = await axios.get<DashboardGroup>(`/dashboardgroups/${id}`);
            return Object.assign(new DashboardGroup, resp.data);
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async getAllInDashboard(dashboardId: string)
    {
        try
        {
            const resp =  await axios.get<DashboardGroup[]>(`/dashboardgroups/dashboard/${dashboardId}`);
            for(let i = 0; i < resp.data.length; ++i)
            {
                resp.data[i] = Object.assign(new Dashboard, resp.data[i]);
            }
            return resp.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async getAllAvailableUsersForDashboardGroup(baselineId: string)
    {
        try
        {
            return (await axios.get<User[]>(`/dashboardgroups/${baselineId}/availableusers`)).data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async create(args: { dashboardId: string, dashboardGroup: DashboardGroup })
    {
        const { dashboardId, dashboardGroup } = args;
        try
        {
            const res =  await axios.post<DashboardGroup>(`/dashboardgroups/${dashboardId}`, { dashboardGroup });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async multicreate(args: { dashboardId: string, dashboardGroups: DashboardGroup[] })
    {
        const { dashboardId, dashboardGroups } = args;
        try
        {
            const res =  await axios.post<DashboardGroup[]>(`/dashboardgroups/${dashboardId}/multi`, { dashboardGroups });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async update(dashboardGroup: DashboardGroup)
    {
        try
        {
            const res =  await axios.patch<DashboardGroup>(`/dashboardgroups/${dashboardGroup._id}`, { dashboardGroup: dashboardGroup });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async invite(args: { id: string, members: DashboardGroupMember[] })
    {
        const { id: dashboardGroupId, members } = args;
        try
        {
            const res =  await axios.patch<DashboardGroup>(`/dashboardgroups/${dashboardGroupId}/invite`, { members });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async remind(args: { id: string, members: DashboardGroupMember[] })
    {
        const { id: dashboardGroupId, members } = args;
        try
        {
            const res =  await axios.patch<DashboardGroup>(`/dashboardgroups/${dashboardGroupId}/remind`, { members });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async checkAssessed(dashboardGroupId: string)
    {
        try
        {
            const res =  await axios.patch<DashboardGroup>(`/dashboardgroups/${dashboardGroupId}/checkassessed`);
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async delete(id: string)
    {
        try
        {
            await axios.delete(`/dashboardgroups/${id}`);
            return true;
        }
        catch(err)
        {
            console.error(err);
        }
        return false;
    }
}

// Register the module
import store from '../index';
import DashboardGroup, { DashboardGroupMember } from '@/models/hcad/shared/dashboard-group';
import User from '@/models/User';
const dashboardGroupModule = new DashboardGroupModule({store, name: 'dashboardGroup'});
export default dashboardGroupModule;