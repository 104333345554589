







































































































































































































































































/*eslint-disable no-unused-vars */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ElementQueries from 'css-element-queries';
import dashModule from '@/store/modules/DashboardModule';
import { IndividualDataTableDashboardPage } from '@/models/hcad/shared/dashboard';
import { BaselineInspectionResult } from '@/store/modules/RoleBaselineModule';
import interpolate from 'color-interpolate';

function rgbToRGBA(rgbColor: string, alpha: number)
{
    const matches = rgbColor.match(/\d+/g);
    if (!matches) return rgbColor;
    const [r, g, b] = matches.map(Number);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

let globalIdAlloc = 0;

type Point = [number, number];
type Normal = [number, number];
type Triangle = [Point, Point, Point];

// start point, control point 1, control point 2, end point
type Curve = [Point, Point, Point, Point];

// radius, start point, end point
type Arc = [number, Point, Point];

function easeInOutQuad(t: number): number
{
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

@Component
export default class CapabilityWheel extends Vue
{
    @Prop({type: Object, required: true})
    readonly page!: IndividualDataTableDashboardPage;

    @Prop({ type: Array })
    readonly data!: Array<number>;

    @Prop({ type: Object, default: null })
    readonly inspectionResult!: BaselineInspectionResult | null;

    @Prop({type: Boolean, required: true})
    readonly selectedUserIsFit!: boolean

    get shouldDisplayFit()
    {
        return this.inspectionResult !== null;
    }

    get value()
    {
        if (!this.inspectionResult) return 0;
        
        const values: number[] = [];

        let index = 0;
        for(const cap of this.capabilityList)
        {
            values.push(this.data[index]);
            index += cap.subCapabilities.length + 1;
        }

        const weightedValueScores: number[] = [];
        for(let i = 0; i < values.length; ++i)
        {
            weightedValueScores.push(this.inspectionResult.sampleFieldFromRaw(i, values[i]) * this.inspectionResult.fields[i].weight);
        }

        const totalWeightedScore = weightedValueScores.reduce((acc, val) => acc + val, 0);
        const threshold = this.inspectionResult.threshold;
        const realValue = Math.min(totalWeightedScore / threshold, 1);
        
        // Workaround for the fact that the samples are lossy, meaning we may not get the exact result.
        // AFter the computations so the getter picks up the things it needs to react to.
        return Math.max(this.selectedUserIsFit ? 1 : 0, realValue);
    }

    computeRawSlicePoint(perc: number): Point
    {
        const angleOffset = Math.PI / 2 * 3;
        const twoPi = Math.PI * 2;
        const angle = perc * twoPi + angleOffset;
        return [Math.cos(angle), Math.sin(angle)] as Point;
    }

    computeRawSliceTangent(perc: number): Normal
    {
        const angleOffset = Math.PI / 2 * 3;
        const twoPi = Math.PI * 2;
        const angle = perc * twoPi + angleOffset;

        // Tangent = x,y derivatives.
        // Derivative of sin is cos, and derivative of cos is -sin
        return [-Math.sin(angle), Math.cos(angle)] as Normal;
    }

    computeRawSliceArcAsCurve(arcn: number, numArcs: number): Curve
    {
        const startPerc = arcn / numArcs;
        const endPerc = (arcn + 1) / numArcs;
        const startPoint = this.computeRawSlicePoint(startPerc);
        const endPoint = this.computeRawSlicePoint(endPerc);
        const startTangent = this.computeRawSliceTangent(startPerc);
        const endTangent = this.computeRawSliceTangent(endPerc);

        // Based on https://stackoverflow.com/a/27863181
        const ctrlPtDist = (4/3) * Math.tan(Math.PI / (2 * numArcs));
        const ctrlPtOne = [startPoint[0] + ctrlPtDist * startTangent[0], startPoint[1] + ctrlPtDist * startTangent[1]];
        const ctrlPtTwo = [endPoint[0] - ctrlPtDist * endTangent[0], endPoint[1] - ctrlPtDist * endTangent[1]];

        return [startPoint, ctrlPtOne, ctrlPtTwo, endPoint] as Curve;
    }

    computeTransformedArc(circleOrigin: Point, circleRadius: number, startPerc: number, endPerc: number): Arc
    {
        const startPoint = this.transformPoint(this.computeRawSlicePoint(startPerc), circleRadius, circleOrigin);
        const endPoint = this.transformPoint(this.computeRawSlicePoint(endPerc), circleRadius, circleOrigin);
        return [circleRadius, startPoint, endPoint] as Arc;
    }

    arcToPath(arc: Arc): string
    {
        const [radius, start, end] = arc;
        const largeArcFlag = 0;
        return `M${start.join(' ')} A${radius} ${radius} 0 ${largeArcFlag} 1 ${end.join(' ')}`;
    }

    filledArcToPath(arc: Arc): string
    {
        const circleOrigin = this.circleOrigin;
        const [radius, start, end] = arc;
        const largeArcFlag = 0;
        
        // Arc, but include a line to and from the center point
        return `M${circleOrigin.join(' ')} L${start.join(' ')} A${radius} ${radius} 0 ${largeArcFlag} 1 ${end.join(' ')} L${circleOrigin.join(' ')}`;
    }

    computeRawSliceStartEndPoint(startPerc: number, endPerc: number, minRange = 0, maxRange = 1): [Point, Point]
    {
        const angleOffset = Math.PI / 2 * 3;
        const twoPi = Math.PI * 2;

        // Note: These additions are a hack to fix the seams.  Need to figure out what's actually going on there.
        const minAngle = Math.max(minRange, startPerc) * twoPi + angleOffset - 0.01;
        const maxAngle = Math.min(maxRange, endPerc) * twoPi + angleOffset + 0.01;

        const p1: Point = [Math.cos(minAngle), Math.sin(minAngle)];
        const p2: Point = [Math.cos(maxAngle), Math.sin(maxAngle)];

        // Project both points on the bounding square
        const p1Len = Math.max(Math.abs(p1[0]), Math.abs(p1[1]));
        const p2Len = Math.max(Math.abs(p2[0]), Math.abs(p2[1]));

        p1[0] /= p1Len;
        p1[1] /= p1Len;
        p2[0] /= p2Len;
        p2[1] /= p2Len;
        return [p1, p2];
    }

    computeRawSliceMaskTriangle(triId: number, minClipPerc: number, maxClipPerc: number): Triangle | null
    {
        const minRange = triId / 8;
        const maxRange = (triId + 1) / 8;

        if ((minClipPerc) > maxRange) return null;
        if ((maxClipPerc) < minRange) return null;

        return ([[0, 0], ...this.computeRawSliceStartEndPoint(minClipPerc, maxClipPerc, minRange, maxRange)]);
    }

    computeUnscaledRadialBoundingTriangles(minAngle: number, maxAngle: number): Triangle[]
    {
        const res: Triangle[] = [];

        // Two triangles per quadrant
        for(let triId = 0; triId < 8; ++triId)
        {
            const tri = this.computeRawSliceMaskTriangle(triId, minAngle, maxAngle);
            if (tri) 
            {
                res.push(tri);
            }
        }
        return res;
    }

    curveToPath(curve: Curve): string
    {
        return `M${curve[0].join(' ')} C${curve[1].join(' ')} ${curve[2].join(' ')} ${curve[3].join(' ')}`;
    }

    transformPoint(pt: Point, scale: number, offset: Point): Point
    {
        return [pt[0] * scale + offset[0], pt[1] * scale + offset[1]];
    }

    transformTriangles(triangles: Triangle[], scale: number, offset: Point): Triangle[]
    {
        return triangles.map(tri =>
        {
            return tri.map(pt =>
            {
                return this.transformPoint(pt, scale, offset);
            });
        }) as Triangle[];
    }

    get unscaledRadialMaskTrianglesRaw() : Triangle[]
    {
        return this.computeUnscaledRadialBoundingTriangles(0, this.drawAngle);
    }

    get radialMaskTrianglesRaw()
    {
        const scale = this.outerCircleRadius;
        return this.unscaledRadialMaskTrianglesRaw.map(tri =>
        {
            return tri.map(pt =>
            {
                return [pt[0] * scale, pt[1] * scale];
            });
        });
    }

    get radialMaskTrianglesForSVG()
    {
        const origin = this.circleOrigin;
        return this.radialMaskTrianglesRaw.map(tri =>
        {
            return tri.map(pt =>
            {
                return `${pt[0] + origin[0]},${pt[1] + origin[1]}`;
            }).join(' ');
        });
    }

    id = -1;
    
    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get circleOrigin() : Point
    {
        return [0, -20];
    }

    get outerCircleRadius()
    {
        return Math.min(this.svgWidth, this.svgHeight) / 2 - 20;
    }

    get fillCircleRadius()
    {
        return this.outerCircleRadius;
    }

    get innerCircleRadius()
    {
        return this.outerCircleRadius * 0.95;
    }

    get capabilitySliceRadius()
    {
        return this.innerCircleRadius - 30;
    }

    textTestElement: SVGTextElement | null = null;

    computeTextLength(text: string)
    {
        const el = this.$refs['text-test-element'] as SVGTextElement | undefined;
        if (!el)
        {
            console.warn('Gem could not find text test element');
            return 0;
        }

        el.textContent = text;
        return el.getComputedTextLength();
    }

    get capabilityList()
    {
        const caps = [];
        for(const cat of this.dashboard.capabilities)
        {
            for (const capability of cat.capabilities)
            {
                caps.push(capability);
            }
        }
        return caps;
    }

    get labeledData()
    {
        const values: { label: string, iconUrl: string | null, iconClass: string | null, color: string, subcapabilities: { label: string, value: number }[] }[] = [];
        const min = this.dashboard.capabilityRange[0];
        const max = this.dashboard.capabilityRange[1];

        let index = 0;
        for(const cap of this.capabilityList)
        {
            // Skip the top level capability value
            ++index;

            const subcaps = [];
            for(const subcap of cap.subCapabilities)
            {
                const remapped = (this.data[index] - min) / (max - min);
                subcaps.push({ label: subcap.name, value: remapped });
            }
            values.push({ label: cap.name, iconUrl: cap.iconUrl, iconClass: cap.iconClass, color: cap.color, subcapabilities: subcaps });
        }
        return values;
    }

    debuggingTextPath = false;
    debuggingSubcapabilityArcs = true;
    get capabilityDrawData()
    {
        const values: { 
            label: string,
            color: string,
            triangles: Triangle[],
            textCurve: Curve
            subcapabilities: {
                label: string,
                value: number,
                originPoint: Point,
                radiusPoint: Point,
                arcs: { color: string, arc: Arc }[],
                valueArcs: { color: string, arc: Arc }[],
                isFlipped: boolean
            }[]
        }[] = [];
        const topLevel = [...this.capabilityList];
        
        let dataIndex = 0;
        const numTopLevel = topLevel.length;
        const increment = 1 / numTopLevel;
        // console.log({numTopLevel, increment});

        for(let currentTopLevelIdx = 0; currentTopLevelIdx < numTopLevel; ++currentTopLevelIdx)
        {
            const cap = topLevel[currentTopLevelIdx];
            const triangles = this.transformTriangles(
                this.computeUnscaledRadialBoundingTriangles(currentTopLevelIdx * increment, (currentTopLevelIdx + 1) * increment),
                this.capabilitySliceRadius,
                this.circleOrigin
            );
            
            if (!triangles) continue;

            const start = currentTopLevelIdx * increment;
            const end = (currentTopLevelIdx + 1) * increment;
            const rawPath = this.computeRawSliceArcAsCurve(currentTopLevelIdx, numTopLevel);
            const radius = (this.capabilitySliceRadius + this.outerCircleRadius) / 2 * 0.97;
            const path = rawPath.map(pt => this.transformPoint(pt, radius, this.circleOrigin)) as Curve;
            if (start > 0.24 && start < 0.74)
            {
                path.reverse();
            }

            dataIndex += 1;

            // console.log({i: currentTopLevelIdx, start, end});
        
            let localSubcapIdx = 0;
            let capCopy = cap;
            values.push({ label: cap.name, color: cap.color, triangles, textCurve: path, subcapabilities: cap.subCapabilities.map((subcap) => 
            {
                let currentLocalSubcapIdx = localSubcapIdx++;
                const dataIdx = dataIndex++;
                const label = subcap.name;
                const value = this.data[dataIdx];

                const interpolator = (currentLocalSubcapIdx / cap.subCapabilities.length) + 1 / cap.subCapabilities.length / 2;
                const radiusPerc = start + (end - start) * interpolator;
                const rawRadiusPoint = this.computeRawSlicePoint(radiusPerc);
                const radiusPoint = this.transformPoint(rawRadiusPoint, this.capabilitySliceRadius, this.circleOrigin);
                const originPoint = this.circleOrigin;

                const numRadialArcs = 9;
                const baseColor = capCopy.color;

                // Interpolate color between white and the capability color
                const arcs: { color: string, arc: Arc}[] = [];
                const valueArcs: { color: string, arc: Arc}[] = [];

                const localArcStart = (currentLocalSubcapIdx) / cap.subCapabilities.length;
                const localArcEnd = (currentLocalSubcapIdx + 1) / cap.subCapabilities.length;
                const globalArcStart = start + localArcStart * (end - start);
                const globalArcEnd = start + localArcEnd * (end - start);

                // Decrement index for proper ordering
                for(let arcIdx = numRadialArcs - 1; arcIdx >= 0; --arcIdx)
                {
                    const rawInterpolator = arcIdx / (numRadialArcs - 1);
                    const radius = rawInterpolator * this.capabilitySliceRadius;
                    const arc = this.computeTransformedArc(this.circleOrigin, radius, globalArcStart, globalArcEnd);

                    // Bias the interpolation as we don't want pure white
                    const bias = 0.5;
                    const colorInterpolator = ((rawInterpolator) + bias) / (1 + bias);
                    const color = interpolate(['white', baseColor])(colorInterpolator);

                    arcs.push({ color, arc });
                }

                // we need to add subcapability ranges
                const range = subcap.range ?? this.dashboard.capabilityRange;
                const min = range[0];
                const max = range[1];
                const normalizedValue = (value - min) / (max - min);
                
                const drawNotchedValueArcs = false;
                if (drawNotchedValueArcs)
                {
                    const numValueArcs = Math.floor((numRadialArcs - 1) * normalizedValue);
                    for(let arcIdx = numValueArcs ; arcIdx >= 0; --arcIdx)
                    {
                        const rawInterpolator = arcIdx / (numRadialArcs - 1);
                        const radius = rawInterpolator * this.capabilitySliceRadius;
                        const arc = this.computeTransformedArc(this.circleOrigin, radius, globalArcStart, globalArcEnd);

                        const minInterpVal = 0.1;
                        const maxInterpVal = 0.7;
                        const colorInterpolator = rawInterpolator * (maxInterpVal - minInterpVal) + minInterpVal;
                        const color = interpolate(['#666', baseColor])(colorInterpolator);
                        valueArcs.push({ color: rgbToRGBA(color, 0.7), arc });
                    }
                }
                else
                {
                    const rawInterpolator = normalizedValue;
                    const radius = rawInterpolator * this.capabilitySliceRadius;
                    const arc = this.computeTransformedArc(this.circleOrigin, radius, globalArcStart, globalArcEnd);

                    const color = interpolate(['#333', baseColor])(0.2);
                    valueArcs.push({ color: rgbToRGBA(color, 0.4), arc });
                }
                
                const res = { label, value, originPoint, radiusPoint, arcs, valueArcs, isFlipped: false };
                if (radiusPerc > 0.5)
                {
                    const old = res.originPoint;
                    res.originPoint = res.radiusPoint;
                    res.radiusPoint = old;
                    res.isFlipped = true;
                }
                return res;
            })});
        }
        // console.log(values);
        return values;
    }

    resizeSensor: ElementQueries.ResizeSensor | null = null;
    svgWidth = 0;
    svgHeight = 0;

    loaded = false;

    runningAnimIdx = 0;
    drawAngle = 0;

    get displayAngle()
    {
        return Math.round(this.drawAngle * 100);
    }

    @Watch('value')
    async onValueChanged()
    {
        const animIdx = ++this.runningAnimIdx;
        const start = this.drawAngle;
        const end = this.value;
        const duration = 1000;
        const startTime = Date.now();
        const endTime = startTime + duration;

        while (Date.now() < endTime)
        {
            console.log(`animating from ${start} to ${end}`);
            if (this.runningAnimIdx !== animIdx) return;
            const linearT = (Date.now() - startTime) / duration;
            const interpT = easeInOutQuad(linearT);
            this.drawAngle = start + (end - start) * interpT;
            await new Promise(resolve => requestAnimationFrame(resolve));
        }
        this.drawAngle = end;
    }

    async mounted()
    {
        this.id = globalIdAlloc++;

        let ticks = 0;
        while (this && (!this.$refs.svg || !this.$refs['text-test-element']))
        {
            if (ticks > 100) break;
            await this.$nextTick();
            ++ticks;
        }

        this.loaded = true;
        this.resizeSensor = new ElementQueries.ResizeSensor(this.$refs.svg as Element, ()=>
        {
            this.svgWidth = (this.$refs.svg as HTMLDivElement).clientWidth;
            this.svgHeight = (this.$refs.svg as HTMLDivElement).clientHeight;
        });

        this.onValueChanged();
    }

    beforeDestroy()
    {
        if (this.resizeSensor) this.resizeSensor.detach();
        this.runningAnimIdx = -1;
    }
}
