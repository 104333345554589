
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Dashboard } from '../../../models/hcad/shared/dashboard';

@Component
export default class DashboardListDeleteDialogue extends Vue
{
    @Prop({ type: Object, required: true })
    item!: Dashboard;

    modalOpen = false;
}
