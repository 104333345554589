
































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { LineChart } from 'vue-chart-3';

import SliderArrow from '../../components/SliderArrow.vue';
import Gem from '../../components/Gem.vue';
import CapabilityWheel from '../../components/CapabilityWheel.vue';
import InterviewGuide from '../../components/InterviewGuide.vue';
import { BaseDashboardPageField, Capability, Dashboard, DashboardPageFieldType, IndividualDataTableDashboardPage, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '@/models/hcad/shared/queries';
import CapabilityIcon from './individual-data-table-renderers/CapabilityIcon.vue';
import roleBaselineModule, { BaselineInspectionResult } from '@/store/modules/RoleBaselineModule';
import RoleBaseline from '@/models/hcad/shared/role-baseline';

import { Chart } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { ChartConfiguration } from 'chart.js';

const clamp01 = (val: number) => Math.min(1, Math.max(0, val));

@Component({
    components: {
        Gem,
        LineChart,
        CapabilityIcon,
        CapabilityWheel,
        InterviewGuide,
        SliderArrow
    }
})
export default class IndividualDataTableModalContent extends Vue
{
    @Prop({type: Object, required: true})
    page!: IndividualDataTableDashboardPage;

    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Boolean})
    isChartReady!: boolean;

    @Prop({type: Boolean})
    dashboardLoading!: boolean;
    
    @Prop({type: Array, required: true})
    selectedUserCapabilities!: number[];

    @Prop({type: Array, required: true})
    selectedUserAllMetrics!: number[];
    
    @Prop({type: Array, required: true})
    capabilities!: Capability[];

    @Prop({type: Object, required: true})
    selectedUser!: UserInfoAndMetrics<IndividualDataTableUserData>

    @Prop({type: Boolean, required: true})
    selectedUserIsFit!: boolean;

    @Prop({type: Object})
    selectedBaseline!: RoleBaseline | null;

    @Prop({type: Array, required: true, default: () => []})
    targetCapabilities!: number[];

    @Prop({type: Array, required: true, default: () => []})
    roleRecommendations!: string[];

    usingOldScoreStyle = false;
    newScoreStyleSize = 64;

    disableWrap = true;

    handleResize()
    {
        this.disableWrap = (window.innerWidth > 1400);
    }

    get capabilitiesImpactLabel()
    {
        if (this.page.capabilityImpactLabelOverride && this.page.capabilityImpactLabelOverride.trim().length > 0)
        {
            return this.page.capabilityImpactLabelOverride;
        }
        return 'Capabilities Impact';
    }

    get relativelyHighestCapability()
    {
        const normalizedScores = this.selectedUserCapabilities.map((a, idx) => 
            ({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                value: a / (this.capabilities[idx].range ? (this.capabilities[idx].range!)[1] : this.dashboard.capabilityRange[1]),
                idx
            })
        );

        const topLevel = this.dashboard.capabilities.map(a=>a.capabilities).flat();
        const sorted = normalizedScores.sort((a, b) => b.value - a.value);
        return topLevel[sorted[0].idx];
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    computeFieldProgress(cap: Capability, idx: number, _: number)
    {
        if (!this.baselineInspectionData)
        {
            return 0;
        }
        
        const val = this.selectedUserCapabilities[idx];
        const capMin = cap.range ? cap.range[0] : this.dashboard.capabilityRange[0];
        const capMax = cap.range ? cap.range[1] : this.dashboard.capabilityRange[1];
        const valRescaled = (val - capMin) / (capMax - capMin);

        let min = Infinity;
        let max = -Infinity;

        const fieldData = this.baselineInspectionData.fields[idx];
        for (let i = 0; i < fieldData.samples.length; ++i)
        {
            const val = fieldData.samples[i];
            if (val < min)
            {
                min = val;
            }
            if (val > max)
            {
                max = val;
            }
        }

        const sampled = clamp01(this.baselineInspectionData.sampleField(idx, valRescaled));
        return (sampled - min) / (max - min);
    }
    
    computeScoreStyle(cap: Capability, idx: number)
    {
        const val = this.selectedUserCapabilities[idx];
        const min = cap.range ? cap.range[0] : this.dashboard.capabilityRange[0];
        const max = cap.range ? cap.range[1] : this.dashboard.capabilityRange[1];

        // resolving the SVG not being fully centered and going off the left end of sliders
        const minVal = 0.01;
        const valRescaled = Math.max((val - min) / (max - min), minVal);
        return {
            position: 'absolute',
            left: `${valRescaled * 100}%`,
            transform: `translate(-${this.usingOldScoreStyle ? 11 : this.newScoreStyleSize / 2}px, -${this.usingOldScoreStyle ? 6 : (this.newScoreStyleSize * 2 / 6)}px)`,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    computeTargetGradientStyle(cap: Capability, idx: number, _: number)
    {
        const result = {
            position: 'relative',
            background: `#A5B3D1`,
            width: '100%',
            height: '16px',
            outline: '1px solid black',
            'border-radius': '16px',
        };
        
        if (this.baselineInspectionData)
        {
            const shouldInterpolate = true;
            const shouldUseThreshold = true;
            
            let min = Infinity;
            let max = -Infinity;

            const fieldData = this.baselineInspectionData.fields[idx];
            for (let i = 0; i < fieldData.samples.length; ++i)
            {
                const val = fieldData.samples[i];
                if (val < min)
                {
                    min = val;
                }
                if (val > max)
                {
                    max = val;
                }
            }

            const minNoFitColor: [number, number, number] = [58 / 255, 75 / 255, 110 / 255];
            const maxNoFitColor: [number, number, number] = [118 / 255, 153 / 255, 224 / 255];
            
            const minUnfitColor: [number, number, number] = [0.5, 0, 0];
            const maxUnfitColor: [number, number, number] = [1, 0, 0];
            
            const minFitColor: [number, number, number] = [1, 0.3, 0];
            const maxFitColor: [number, number, number] = [0, 1, 0];

            const toRGB = (raw: [number, number, number]) => `rgb(${raw.map(a=>Math.floor(a * 255)).join(', ')})`;

            if (shouldInterpolate)
            {
                const gradientColors = fieldData.samples.map(a=> 
                {
                    const minColor = shouldUseThreshold
                        ? a > fieldData.threshold ? minFitColor : minUnfitColor
                        : minNoFitColor;
                    
                    const maxColor = shouldUseThreshold
                        ? a > fieldData.threshold ? maxFitColor : maxUnfitColor
                        : maxNoFitColor;

                    const interpolator = (a - min) / (max - min);
                    const minColorWeight = 1 - interpolator;
                    const maxColorWeight = interpolator;
                    const color: [number, number, number] = [
                        minColor[0] * minColorWeight + maxColor[0] * maxColorWeight,
                        minColor[1] * minColorWeight + maxColor[1] * maxColorWeight,
                        minColor[2] * minColorWeight + maxColor[2] * maxColorWeight
                    ];
                    return toRGB(color);
                });
                result.background = `linear-gradient(to right, ${gradientColors.join(', ')})`;
                // console.log(result.background);
            }
            else
            {
                const gradientColors = fieldData.samples.map(a=>a > fieldData.threshold ? toRGB(maxFitColor) : toRGB(maxUnfitColor));
                result.background = `linear-gradient(to right, ${gradientColors.join(', ')})`;
                // console.log(result.background);
            }
        }

        return result;
    }

    shouldRenderFieldInModal(field: BaseDashboardPageField)
    {
        switch(field.type)
        {
        // case DashboardPageFieldType.UserEmail:
        // case DashboardPageFieldType.UserName:
        case DashboardPageFieldType.Company:
        case DashboardPageFieldType.Location:
        case DashboardPageFieldType.DateCompleted:
        case DashboardPageFieldType.Department:
        // case DashboardPageFieldType.TimeInOrganization:
            return true;
        }
        return false;
    }

    baselineSetIdx = 0;
    baselineInspectionData: BaselineInspectionResult | null = null;
    animatingIn = false;

    get animPercent()
    {
        return this.animatingIn ? 0 : 1;
    }

    activeChart: Chart<"pie", number[], string> | null = null;
    async updateChart()
    {
        console.log('updateChart() start');
        let attempts = 0;
        while(this.$refs.chart === undefined)
        {
            console.log('waiting');
            await this.$nextTick();
            ++attempts;
            if (attempts > 10) throw new Error('Could not find chart ref');
        }
        
        console.log('updateChart() found ref');
        const target = this.$refs.chart as HTMLCanvasElement;
        const ctx = target.getContext('2d');
        if (!ctx) throw new Error('Could not get 2d context');

        const realData = this.baselineInspectionData
            ? this.baselineInspectionData.fields.map(a=>a.weight)
            : this.selectedUserCapabilities.map((_, idx) => idx === 0 ? 1 : 0)
        ;
        
        // Using this for the actual chart because otherwise tiny values are invisible
        const offsetData = this.baselineInspectionData ? realData.map(a=>a + 0.05) : realData;

        // normalize data
        const sum = realData.reduce((a, b) => a + b, 0);
        const normalized = realData.map(a => a / sum);
        
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formatter = (_: any, ctx: Context) =>
        {
            if (this.baselineInspectionData)
            {
                const value = normalized[ctx.dataIndex];
                return Math.floor(value * 100) + '%';
            }
            else
            {
                if (ctx.dataIndex === 0)
                {
                    return 'No Baseline Selected';
                }
                return '';
            }
        };

        const config: ChartConfiguration<"pie", number[], string> = {
            type: 'pie',
            data: {
                labels: this.capabilities.map(a=>a.name),
                datasets: [{
                    data: offsetData,
                    backgroundColor: this.capabilities.map(a=>this.baselineInspectionData ? a.color : 'grey'),
                    label: 'Capabilities',
                }]
            },
            options: {
                layout: {
                    padding: {
                        bottom: 0,
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 0,
                        width: 0
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        position: 'bottom',
                    },
                    datalabels: {
                        formatter,
                        color: 'white',
                        font: {
                            size: this.baselineInspectionData ? 20 : 16
                        },
                        align: this.baselineInspectionData ? 'end' : 'center',
                    },
                    tooltip: {
                        displayColors: this.baselineInspectionData ? true : false,
                        callbacks: {
                            label: (context) =>
                            {
                                if (this.baselineInspectionData)
                                {
                                    const idx = context.dataIndex;
                                    const value = normalized[idx];
                                    return `${this.capabilities[idx].name}: ${Math.floor(value * 100)}%`;
                                }
                                else
                                {
                                    return 'Select a baseline to display the impact of capabilities.';
                                }
                            },
                        }
                    }
                },
                rotation: -90,
                circumference: 180,
                borderColor: 'black',
                color: 'white',
            },
        };

        if (this.activeChart)
        {
            // Update chart
            this.activeChart.data.datasets[0].data = config.data.datasets[0].data;
            this.activeChart.data.datasets[0].backgroundColor = config.data.datasets[0].backgroundColor;
            if (this.activeChart.options?.plugins?.datalabels && config.options?.plugins?.datalabels)
            {
                this.activeChart.options.plugins.datalabels.align = config.options.plugins.datalabels.align;
                this.activeChart.options.plugins.datalabels.formatter = config.options.plugins.datalabels.formatter;
                this.activeChart.options.plugins.datalabels.font = config.options.plugins.datalabels.font;
            }

            if (this.activeChart.options?.plugins?.tooltip && config.options?.plugins?.tooltip)
            {
                this.activeChart.options.plugins.tooltip.displayColors = config.options.plugins.tooltip.displayColors;
                this.activeChart.options.plugins.tooltip.callbacks = config.options.plugins.tooltip.callbacks;
            }
        }
        else
        {
            this.activeChart = new Chart(ctx, config);
        }
        this.activeChart.update();
        console.log('chart created');
    }

    loading = false;
    // Watch selectedBaseline for changes and, if not null, load the inspection data
    @Watch('selectedBaseline')
    async onSelectedBaselineChange()
    {
        if (this.selectedBaseline)
        {
            this.loading = true;
            try
            {
                this.baselineInspectionData = await roleBaselineModule.inspectBaseline(this.selectedBaseline._id);
            }
            finally
            {
                this.loading = false;
            }
        }
        else
        {
            this.baselineInspectionData = null;
        }
        this.baselineSetIdx++;

        // don't bother awaiting
        this.updateChart();

        this.animatingIn = true;
        await new Promise(r => setTimeout(r, 1));
        this.animatingIn = false;
    }

    created()
    {
        window.addEventListener('resize', this.handleResize);
    }

    async mounted()
    {
        this.onSelectedBaselineChange();
    }

    beforeDestroy()
    {
        window.removeEventListener('resize', this.handleResize);
        if (this.activeChart)
        {
            this.activeChart.destroy();
            this.activeChart = null;
        }
    }
}
