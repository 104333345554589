










import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
// import validationRules from '@/utils/validation-rules';

@Component({components: {}})
export default class VNumberField extends Vue
{
    @Prop({ type: Boolean, default: false }) readonly integer!: boolean;
    @Prop(Number) readonly value!: number;
    @Prop(String) readonly label!: string;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop({type: Array, required: false}) readonly rules!: any[];

    isInitializing = false;
    fieldModel = '';

    @Watch('value')
    onValueChanged(newVal: number)
    {
        this.fieldModel = `${newVal}`;
    }

    changeValue(value: number)
    {
        this.$emit('input', value);
        this.fieldModel = `${this.value}`;
    }

    onBlur()
    {
        if (!this.fieldModel)
        {
            this.changeValue(0);
            return;
        }

        const trimmed = this.fieldModel.trim();
        
        if (trimmed.length === 0)
        {
            this.changeValue(0);
            return;
        }

        let num = 0;
        if (this.integer)
        {
            num = Number.parseInt(this.fieldModel);
        }
        else
        {
            num = Number.parseFloat(this.fieldModel);
        }

        if (isNaN(num))
        {
            this.changeValue(0);
            return;
        }

        this.changeValue(num);
    }

    mounted()
    {
        this.onValueChanged(this.value);
    }

    validate(refresh: boolean)
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.$refs.field as any).validate(refresh);
    }
}
