






































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import ValueBars from '@/components/ValueBars.vue';
import Gem from '../../../components/Gem.vue';
import GemDetails from '../../../components/GemDetails.vue';
import dashModule from '@/store/modules/DashboardModule';

@Component({
    components: {
        ValueBars,
        Gem,
        GemDetails,
    }
})
export default class ClassificationAnalysisColumn extends Vue
{
    @Prop({ default: 'STAYED 2-5 YEARS' }) readonly classificationName!: string;
    @Prop({ default: 0 }) readonly classificationSize!: number;
    @Prop({ default: [] }) readonly significantFeatures!: Array<boolean>;
    @Prop({ default: [] }) readonly featureImportance!: Array<number>;
    @Prop({ default: [] }) readonly averageMetrics!: Array<number>;
    // @Prop({ default: false }) readonly loading!: boolean;

    gemDetailsOpen = false;

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get capabilities()
    {
        return this.dashboard.capabilities.flatMap(v=>v.capabilities);
    }

    get namedSignificant()
    {
        if (!(this.significantFeatures))
        {
            return [];
        }
        const namedSignificant = this.significantFeatures.map((f, i) =>
        {
            if (i >= this.capabilities.length) return { name: '', significant: f };
            return { name: this.capabilities[i].name, significant: f };
        });
        return namedSignificant;
    }

    get namedFactors()
    {
        if (!(this.significantFeatures) || !(this.featureImportance))
        {
            return [];
        }
        const impacts = this.featureImportance.map(fi => fi - 1);
        const namedFactors = this.namedSignificant.map((v, index) =>
        {
            const result: { name: string, significant: boolean, impact: number } = Object.assign({ impact: 0 }, v);
            if (impacts.length > index)
            {
                result.impact = impacts[index];
            }
            return result;
        }).filter(v=>v.name);
        // console.log(namedFactors);
        namedFactors.sort((a, b) =>
        {
            // if (a.significant && !(b.significant)) return -1;
            // if (!(a.significant) && b.significant) return 1;
            return Math.abs(b.impact) - Math.abs(a.impact);
        });
        // hack to correct significance
        const firstSignificantIndex = namedFactors.findIndex(f=>f.significant);
        if (firstSignificantIndex >= 0)
        {
            for (let i = 0; i < firstSignificantIndex; ++i)
            {
                namedFactors[i].significant = true;
            }
            for (let i = 0; i < namedFactors.length; ++i)
            {
                if (namedFactors[i].impact === 0) namedFactors[i].significant = false;
            }
        }
        console.log(namedFactors);
        return namedFactors;
    }
    
    selectedSort = 0;
    get namedFactorsCustomSort()
    {
        if (!(this.namedFactors) || !(this.namedFactors.length))
        {
            return [];
        }
        const namedFactorsCustomSort = this.namedFactors.map(v=>v);
        namedFactorsCustomSort.sort((a, b) =>
        {
            if (this.selectedSort > 0) return b.impact - a.impact; // positive
            if (this.selectedSort < 0) return a.impact - b.impact; // negative
            return Math.abs(b.impact) - Math.abs(a.impact); // neutral
        });
        return namedFactorsCustomSort.slice(0, 6);
    }

    barStyle(index: number)
    {
        return `width: ${
            90*(Math.abs(this.namedFactorsCustomSort[index].impact) / (Math.abs(this.namedFactors[0].impact) || 1))
        }%; height: 5px; background-color: ${
            (this.namedFactorsCustomSort[index].impact > 0)
                ? 'rgb(10, 210, 10)'
                : ((this.namedFactorsCustomSort[index].impact < 0) ? 'rgb(230, 10, 10)' : 'rgb(0, 180, 170)') 
        }; margin: auto;`;
    }

    get mostImportant()
    {
        return (this.namedFactors.length > 0 && this.namedFactors[0].significant) ? this.namedFactors[0].name : undefined;
    }

    get mostPositive()
    {
        const pos = this.namedFactors.find((f) => f.significant && f.impact > 0);
        return (pos && pos.name) ? pos.name : undefined;
    }

    get mostNegative()
    {
        const neg = this.namedFactors.find((f) => f.significant && f.impact < 0);
        return (neg && neg.name) ? neg.name : undefined;
    }

    get unimportantCount()
    {
        return this.namedFactors.filter(v => !(v.significant)).length;
    }

    get generatedBaseText()
    {
        const mostPositiveText = this.mostPositive
            ? `The most positively correlated behavioral factor was: ${this.mostPositive}.`
            : 'The available data did not conclusively find a positively correlated behavioral factor.';
        const mostNegativeText = this.mostNegative
            ? `The most negatively correlated behavioral factor was: ${this.mostNegative}.`
            : 'The available data did not conclusively find a negatively correlated behavioral factor.';
        const unimportant = this.unimportantCount
            ? `${this.unimportantCount} of the behavioral factors were confirmed as uncorrelated to this outcome.`
            : 'The available data did not conclusively find any behaviors that were not correlated to this outcome.';
        return `${mostPositiveText}\n\n${mostNegativeText}\n\n${unimportant}`;
    }
}
