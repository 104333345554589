




























import { Component, Vue, Prop } from 'vue-property-decorator';

import { 
    DashboardPageFilterType,
    DashboardPageSelectFilter,
    SelectFilterQueryData,
} from '@/models/hcad/shared/dashboard';
import { filterRuntimeComponents } from '@/utils/typed-configs';
import dashModule from '../store/modules/DashboardModule';
import { FilterValueType } from '../models/hcad/shared/queries';

import InfoButton from '@/components/InfoButton.vue';

@Component({components: {InfoButton}})
export default class FilterSelect extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageSelectFilter;

    @Prop({type: Object, required: true}) 
    value!: SelectFilterQueryData;

    values: FilterValueType[] | null = null;

    async mounted()
    {
        if (this.filter.useValuesFromDataSource)
        {
            this.values = await dashModule.getDefaultFilterValuesForActiveDashboard(this.filter.type);
            this.$set(this, 'values', this.values);
        }
    }

    get options()
    {
        if (this.filter.useValuesFromDataSource && this.values !== null)
        {
            return this.values;
        }
        return this.filter.options;
    }
}

filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.LocationSelect, FilterSelect)
    .registerDataFactory(DashboardPageFilterType.LocationSelect,
        ()=>new SelectFilterQueryData(DashboardPageFilterType.LocationSelect))
;
filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.DepartmentSelect, FilterSelect)
    .registerDataFactory(DashboardPageFilterType.DepartmentSelect,
        ()=>new SelectFilterQueryData(DashboardPageFilterType.DepartmentSelect))
;
filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.FunctionSelect, FilterSelect)
    .registerDataFactory(DashboardPageFilterType.FunctionSelect,
        ()=>new SelectFilterQueryData(DashboardPageFilterType.FunctionSelect))
;
filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.JobCodeSelect, FilterSelect)
    .registerDataFactory(DashboardPageFilterType.JobCodeSelect,
        ()=>new SelectFilterQueryData(DashboardPageFilterType.JobCodeSelect))
;
filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.CompanySelect, FilterSelect)
    .registerDataFactory(DashboardPageFilterType.CompanySelect,
        ()=>new SelectFilterQueryData(DashboardPageFilterType.CompanySelect))
;
