import axios from '@/utils/axios';
import { VuexModule, Module, Action } from 'vuex-class-modules';
import { Dashboard } from '@/models/hcad/shared/dashboard';

type BaselineInspectionResultRaw = {
    threshold: number,
    fields: {
        weight: number,
        threshold: number,
        sampleMin: number,
        sampleMax: number,
        samples: number[]
    }[]
};

export class BaselineInspectionResult
{
    constructor(raw: BaselineInspectionResultRaw)
    {
        this.raw = raw;
    }

    get threshold() 
    {
        return this.raw.threshold; 
    }

    get fields() 
    {
        return this.raw.fields; 
    }

    sampleFieldFromRaw(field: number, sample: number)
    {
        if (this.fields.length <= field) return -1;

        const fieldData = this.fields[field];

        const numSamples = fieldData.samples.length;
        const localSample = (sample - fieldData.sampleMin) / (fieldData.sampleMax - fieldData.sampleMin);
        if (localSample < 0) return fieldData.samples[0];
        if (localSample >= 1) return fieldData.samples[numSamples - 1];
        return this.sampleField(field, localSample);
    }

    sampleField(field: number, localSample: number)
    {
        if (this.fields.length <= field) return -1;

        const fieldData = this.fields[field];
        
        // convert the sample to 0-1 range based on the min and max
        const numSamples = fieldData.samples.length;
        // const localSample = (sample - fieldData.sampleMin) / (fieldData.sampleMax - fieldData.sampleMin);
        if (localSample < 0) return fieldData.samples[0];
        if (localSample >= 1) return fieldData.samples[numSamples - 1];

        // find the two samples to interpolate between
        const firstSampleIndex = Math.floor(localSample * (numSamples - 1));
        const secondSampleIndex = firstSampleIndex + 1;

        const firstSample = fieldData.samples[firstSampleIndex];
        const secondSample = fieldData.samples[secondSampleIndex];

        // interpolate
        const interpolationFactor = (localSample - firstSampleIndex / (numSamples - 1)) * (numSamples - 1);
        return firstSample + (secondSample - firstSample) * interpolationFactor;
    }

    private readonly raw: BaselineInspectionResultRaw;
}

@Module
class RoleBaselineModule extends VuexModule
{
    @Action
    async getById(id: string)
    {
        try
        {
            const resp = await axios.get<RoleBaseline>(`/rolebaselines/${id}`);
            return Object.assign(new RoleBaseline, resp.data);
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async getAllInDashboard(dashboardId: string)
    {
        try
        {
            const resp =  await axios.get<RoleBaseline[]>(`/rolebaselines/dashboard/${dashboardId}`);
            for(let i = 0; i < resp.data.length; ++i)
            {
                resp.data[i] = Object.assign(new Dashboard, resp.data[i]);
            }
            return resp.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async getAllAvailableUsersForBaseline(baselineId: string)
    {
        try
        {
            return (await axios.get<User[]>(`/rolebaselines/${baselineId}/availableusers`)).data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async inspectBaseline(baselineId: string)
    {
        try
        {
            const raw = (await axios.get<BaselineInspectionResultRaw>(`/rolebaselines/${baselineId}/inspect`)).data;
            if (!raw) return null;
            return new BaselineInspectionResult(raw);
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async create(args: { dashboardId: string, baseline: RoleBaseline })
    {
        const { dashboardId, baseline } = args;
        try
        {
            const res =  await axios.post<RoleBaseline>(`/rolebaselines/${dashboardId}`, { baseline });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async update(baseline: RoleBaseline)
    {
        try
        {
            const res =  await axios.patch<RoleBaseline>(`/rolebaselines/${baseline._id}`, { baseline: baseline });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async invite(args: { id: string, members: RoleBaselineMember[] })
    {
        const { id: baselineId, members } = args;
        try
        {
            const res =  await axios.patch<RoleBaseline>(`/rolebaselines/${baselineId}/invite`, { members });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async remind(args: { id: string, members: RoleBaselineMember[] })
    {
        const { id: baselineId, members } = args;
        try
        {
            const res =  await axios.patch<RoleBaseline>(`/rolebaselines/${baselineId}/remind`, { members });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async checkAssessed(baselineId: string)
    {
        try
        {
            const res =  await axios.patch<RoleBaseline>(`/rolebaselines/${baselineId}/checkassessed`);
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async delete(id: string)
    {
        try
        {
            await axios.delete(`/rolebaselines/${id}`);
            return true;
        }
        catch(err)
        {
            console.error(err);
        }
        return false;
    }
}

// Register the module
import store from '../index';
import RoleBaseline, { RoleBaselineMember } from '@/models/hcad/shared/role-baseline';
import User from '@/models/User';
const roleBaselineModule = new RoleBaselineModule({store, name: 'roleBaseline'});
export default roleBaselineModule;
