




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerContext, getIndex, getValue } from '../../../utils/typed-configs';

@Component
export default class TimeInOrgRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: number;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    get displayTime()
    {
        return displayTime(this.value);
    }
}

function displayTime(v: number)
{
    const years = Math.floor(v);
    const months = Math.floor((v - years) * 12);
    const yearString = (years > 0) ? `${years} year${years > 1 ? 's' : ''}` : '';
    const monthString = (months > 0) ? `${(years > 0) ? ' ' : ''}${months} month${months > 1 ? 's' : ''}` : '';
    return `${yearString}${monthString}`;
}

function toString(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext)
{
    const index = getIndex(fieldIdx, fieldType, a);
    const aValue = getValue(a, index);
    return aValue ? displayTime(aValue as number) : '';
}

function sort(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, b: FieldViewerContext)
{
    const index = getIndex(fieldIdx, fieldType, a);
    const aValue = getValue(a, index);
    const bValue = getValue(b, index);
    const aDefined = (aValue != null);
    const bDefined = (bValue != null);
    if (aDefined && !bDefined) return -1;
    if (!aDefined && bDefined) return 1;
    if (!aDefined && !bDefined) return 0;
    const aval = aValue as number;
    const bval = bValue as number;
    return (aval) - (bval);
}

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.TimeInOrganization, TimeInOrgRenderer)
    .registerDataFactory(DashboardPageFieldType.TimeInOrganization, () => ({ toString, sort }));
