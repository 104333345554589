import { RouteConfig } from 'vue-router';
import DashboardPage from '@/views/dashboard/DashboardPage.vue';
import DefaultContainer from '@/views/containers/DefaultContainer.vue';
import { requireIsAdmin, requireLoggedIn } from '../middleware/auth';

const childObjects: Array<RouteConfig> = [
    {
        path: 'page/:index',
        props: true,
        component: DashboardPage
    },
    {
        path: 'page/:index/:targetId',
        props: true,
        component: DashboardPage
    },
];

const dashboardRoutes: Array<RouteConfig> = [
    {
        path: '/dashboard',
        name: 'dashboardRoot',
        redirect: 'dashboard/page/0',
        component: DefaultContainer,
        beforeEnter: requireLoggedIn,

        children: childObjects
    },
    {
        path: '/dashboard/:dashid',
        props: true,
        name: 'dashboardRootWithID',
        redirect: 'dashboard/:dashid/page/0',
        component: DefaultContainer,

        // Requiring admin to view any dashboard
        beforeEnter: requireIsAdmin,

        children: childObjects
    }
];

export default dashboardRoutes;