

































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import auth from '@/store/modules/AuthModule';
import { Dashboard } from '../../models/hcad/shared/dashboard';
import dashModule from '../../store/modules/DashboardModule';
import activeEnvironment from '@/config/Environment';

@Component
export default class HeaderBar extends Vue
{
    @Prop({ type: Object, default: ()=>[] })
    dashboard!: Dashboard | null;

    @Prop({ type: Boolean, default: false })
    hideTabs!: boolean;

    showDashboardList = false;

    activeDashboardNullable: Dashboard | null = null;

    get activeDashboard()
    {
        return this.activeDashboardNullable as Dashboard;
    }

    get shouldDisplayAdminButton()
    {
        return auth.isHcadAdmin;
    }

    get title()
    {
        return this.dashboard ? this.dashboard.environmentSettings.headerText : 'Dashboard';
    }

    get logo()
    {
        return ((this.dashboard && this.dashboard.environmentSettings.headerLogo.length > 0)
            ? this.dashboard.environmentSettings.headerLogo
            : null);
    }

    get pageList()
    {
        return this.dashboard ? this.dashboard.pages : [];
    }

    get busy_visual()
    {
        // Wrapper so that we can add other bits in here
        return this.busy;
    }

    get busy()
    {
        return this.loading;
    }

    get loggedIn()
    {
        return auth.isSignedIn;
    }

    get canSelectDashboard()
    {
        // TODO:
        // if (!auth.activeUser) return false;
        // return isHcadUser(auth.activeUser);
        return true;
    }

    get userName()
    {
        return auth.activeUser ? `${auth.activeUser.firstName} ${auth.activeUser.lastName}` : '';
    }

    dashboardList: Dashboard[] = [];

    async loadDashboardList()
    {
        this.dashboardList.length = 0;
        const resp = await dashModule.getAllDashboards();
        if (resp)
        {
            for(const dash of resp)
            {
                // Ignore internal dashboards
                if (dash.internal) continue;

                this.dashboardList.push(dash);
            }
        }
    }

    loading = true;
    async mounted()
    {
        this.loading = true;
        try
        {
            await this.loadDashboardList();
            // TODO: Load environment properties
        }
        catch (er)
        {
            console.error(`Failed to load - ${er}`);
        }
        this.loading = false;
    }

    async logout()
    {
        await auth.logout();
    }

    toggleDashboardList()
    {
        console.log(this.showDashboardList);
        this.showDashboardList = !(this.showDashboardList);
    }

    openDashboard(dash: Dashboard)
    {
        let url = '#/dashboard/' + dash._id;
        if (document.title !== 'Loading...'
            && document.title !== activeEnvironment.DefaultTabText
            && (this.activeDashboardNullable && !this.activeDashboard.environmentSettings.tabText))
        {
            url = `/?rnd=${Date.now()}&tabtext=` + document.title + url;
        }
        else
        {
            url = `/?rnd=${Date.now()}` + url;
        }
        window.open(url, '_self');
    }
}
