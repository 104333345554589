



































import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseDashboardPageFilter, ControlGroup, DashboardPageFilterType } from '@/models/hcad/shared/dashboard';
import { filterEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';

// TODO: Automate this...?
require('./filter-configurators/MetricsToggleConfigEdit.vue');
require('./filter-configurators/RangeConfigEdit.vue');
require('./filter-configurators/RoieBaselineConfigEdit.vue');
require('./filter-configurators/SelectConfigEdit.vue');

@Component
export default class FilterEdit extends Vue
{
    @Prop({type: Object, required: true})
    filter!: BaseDashboardPageFilter;

    @Prop({type: Array, required: true})
    groups!: ControlGroup[];

    groupSelectorReactivitySwitch = 0;

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=>
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    get typeString()
    {
        return DashboardPageFilterType[this.filter.type];
    }

    get editorComponent()
    {
        return filterEditorComponents.getComponent(this.filter.type);
    }

    onGroupsChanged()
    {
        if (this.groups.length === 0)
        {
            this.filter.groupId = '';
        }
        else if (this.groups.every(g => g.id !== this.filter.groupId))
        {
            this.filter.groupId = this.groups[0].id;
        }
        
        this.groupSelectorReactivitySwitch = this.groupSelectorReactivitySwitch ? 0 : 1;
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}
