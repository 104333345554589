






























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, BaseDashboardPageField } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import { fieldEditorComponents } from '@/utils/typed-configs';
import FieldEdit from './FieldEdit.vue';

@Component({components: { FieldEdit }})
export default class PageList extends Vue
{
    @Prop({type: Boolean, default: false})
    // eslint-disable-next-line @typescript-eslint/ban-types
    enableCopy!: Boolean;

    @Prop({type: Array, required: true})
    fields!: BaseDashboardPageField[];

    @Prop({type: Array})
    disallowedTypes?: DashboardPageFieldType[];

    configReactivitySwitch = false;
    createModalOpen = false;
    selectedFieldType = DashboardPageFieldType.Invalid;
    keyOffset = 0;

    getFieldTypeName(type: DashboardPageFieldType)
    {
        return DashboardPageFieldType[type];
    }

    get fieldSelectOptions()
    {
        const opts: { text: string; value: DashboardPageFieldType; disabled?: boolean }[] = 
            [{ text: 'Select a field type', value: DashboardPageFieldType.Invalid, disabled: true }];
        
        for (let i = DashboardPageFieldType.Invalid + 1; i < DashboardPageFieldType.Count; ++i)
        {
            if (this.disallowedTypes && this.disallowedTypes.includes(i))
            {
                continue;
            }
            // Gets the names
            opts.push({ text: DashboardPageFieldType[i], value: i });
        }
        return opts;
    }

    createField()
    {
        const type = this.selectedFieldType;
        const factory = fieldEditorComponents.getDataFactory(type);
        if (factory)
        {
            // Create the page and push it into the list
            this.fields.push(factory());
            this.selectedFieldType = DashboardPageFieldType.Invalid;
            this.createModalOpen = false;
            ++this.keyOffset;
        }
        else
        {
            this.fields.push(new BaseDashboardPageField(type));
            this.selectedFieldType = DashboardPageFieldType.Invalid;
            this.createModalOpen = false;
            ++this.keyOffset;
        }
    }

    copyField(idx: number)
    {
        this.fields.push(BaseDashboardPageField.clone(this.fields[idx]));
        ++this.keyOffset;
        this.$emit('copyindex', idx);
    }

    deleteField(idx: number)
    {
        this.fields.splice(idx, 1);
        ++this.keyOffset;
        this.$emit('deleteindex', idx);
    }

    swapFields(a: number, b: number)
    {
        const aval = this.fields[a];
        const bval = this.fields[b];
        this.fields[a] = bval;
        this.fields[b] = aval;
        ++this.keyOffset;
        this.$emit('swaporder', [a, b]);
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}
