












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardDataSourceConfig, Dashboard } from '../../../../models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import DataSourceConfigEdit from './DataSourceConfigEdit.vue';
import dataSourceModule from '@/store/modules/DataSourceModule';
import { dataSourceEditComponents } from '@/utils/typed-configs';

@Component({components: { DataSourceConfigEdit }})
export default class DataSourceConfigList extends Vue
{
    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Object, required: true})
    configs!: DashboardDataSourceConfig;
    configReactivitySwitch = false;

    configTypes: string[] = []
    loading = false;

    deleteConfig(type: string)
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.configs as any)[type] = null;
        this.configReactivitySwitch = !this.configReactivitySwitch;
    }

    configureDataSource(idx: number)
    {
        try
        {
            const type = this.configTypes[idx];
            const data = dataSourceEditComponents.getDataFactory(type);
            if (data)
            {
                const created = data();
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.configs as any)[type] = created;
                this.errorMsg = '';
            }
            else
            {
                alert(`No data factory found for ${type}.  Please report this error to the developers.`);
            }
        }
        finally
        {
            this.configReactivitySwitch = !this.configReactivitySwitch;
        }
    }

    get configArray()
    {
        if (this.configReactivitySwitch && !this.configReactivitySwitch)
        {
            console.log('should not be possible...?');
        }

        const res = [];
        res.length = this.configTypes.length;

        for(let i = 0; i < this.configTypes.length; ++i)
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res[i] = (this.configs as any)[this.configTypes[i]];
        }
        return res;
    }

    async load(refresh: boolean)
    {
        this.loading = true;
        try
        {
            this.configTypes.length = 0;
            const res = await dataSourceModule.getDataSourceTypes(refresh);
            if (res === null) return;

            for(const k of res)
            {
                this.configTypes.push(k);
            }
        }
        finally
        {
            this.loading = false;
        }
    }

    async mounted()
    {
        await this.load(false);
    }

    errorMsg = '';
    validate()
    {
        this.errorMsg = '';
        let found = false;
        for(const cfg of this.configArray)
        {
            if (cfg) 
            {
                found = true;
                break;
            }
        }

        if (!found)
        {
            this.errorMsg = 'You must configure at least one data source';
            return false;
        }
        return validateRefs(this.$refs);
    }
}
