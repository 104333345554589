


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceDepartmentConfig, MockDataSourceFunctionConfig } from '../../../../../../models/hcad/shared/dashboard';

@Component
export default class MockFunctionCopyDialog extends Vue
{
    @Prop({type: Object, required: true})
    readonly func!: MockDataSourceFunctionConfig;

    @Prop({type: Object, required: true})
    readonly sourceDepartment!: MockDataSourceDepartmentConfig;

    @Prop({type: Array, required: true})
    readonly departments!: MockDataSourceDepartmentConfig[];

    targetDepartment: MockDataSourceDepartmentConfig | null = null;

    mounted()
    {
        this.$set(this, 'targetDepartment', this.sourceDepartment);
    }

    copy()
    {
        if (this.targetDepartment)
        {
            this.targetDepartment.functionSet.push(MockDataSourceFunctionConfig.clone(this.func));
            this.isShown = false;
        }
    }

    get options()
    {
        return this.departments.map(dept=>
        {
            return {
                text: dept.name,
                value: dept
            };
        });
    }

    isShown = false;

    show()
    {
        this.targetDepartment = this.sourceDepartment;
        this.isShown = true;
    }

    hide()
    {
        this.isShown = false;
    }
}
