








































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { 
    Capability,
    Dashboard,
    DashboardPageFilterType,
    DashboardPageRoleBaselineFilter,
    DashboardPageType,
    MetricsToggleQueryData,
    RoleBaselineQueryData,
} from '@/models/hcad/shared/dashboard';
import { filterRuntimeComponents } from '@/utils/typed-configs';
import { RoleBaselineDefinitionValueType, RoleBaselineFilterValueType, RoleBaselineReferenceType } from '@/models/hcad/shared/queries';
import dashModule from '../store/modules/DashboardModule';
import roleBaselineModule from '@/store/modules/RoleBaselineModule';
import MetricsToggle from './MetricsToggle.vue';
import InfoButton from '@/components/InfoButton.vue';
import filterPresetModule from '@/store/modules/FilterPresetModule';

// Not sure why it thinks these are unused...?
// eslint-disable-next-line no-unused-vars
enum SelectedRoleBaselineMode
{
    // eslint-disable-next-line no-unused-vars
    CultureFit = 0,
    // eslint-disable-next-line no-unused-vars
    CultureAdd = 1,
}

const logScope: string[] = [];
const enableLogging = false;
function pushLogScope(scope: string)
{
    if (enableLogging)
    {
        logScope.push(scope);
    }
}

function popLogScope()
{
    if (enableLogging)
    {
        logScope.pop();
    }
}

function log(...arr: unknown[])
{
    if (enableLogging) 
    {
        if (logScope && logScope.length > 0)
        {
            console.log(logScope, ...(arr).map(a=>
            {
                if (typeof a === 'object' && a !== null)
                {
                    // Deep clone to get rid of Vue reactivity data
                    return JSON.parse(JSON.stringify(a));
                }
                return a;
            }));
        }
        else
        {
            console.log(...arr);
        }
    }
}

@Component({
    components: {
        MetricsToggle,
        InfoButton
    }
})
export default class FilterRoleBaseline extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageRoleBaselineFilter;

    @Prop({type: Object, required: true}) 
    value!: RoleBaselineQueryData;

    activeDashboardNullable: Dashboard | null = null;


    oldValue: RoleBaselineFilterValueType | null = null;

    selectedBaseline: string | null = null;

    metricToggleData: MetricsToggleQueryData = new MetricsToggleQueryData(DashboardPageFilterType.MetricsToggle);

    isCombinedMode = false;

    isMounting = false;

    combineModeOptions = [
        { text: 'Expand Selection', value: 'or' },
        { text: 'Reduce Selection', value: 'and' },
    ];

    get shouldBypassWatchers()
    {
        return filterPresetModule.isLoadingNewPreset || this.isMounting;
    }

    @Watch('selectedBaseline')
    onSelectedBaselineChanged(newValue: string | null, oldValue: string | null)
    {
        if (this.shouldBypassWatchers)
        {
            log('nowatch:selectedBaseline');
            return;
        }
        log('watch:selectedBaseline');
        if (newValue !== oldValue)
        {
            const oldTargetValue = this.value.baseline.value as (RoleBaselineReferenceType);
            const metricValues = Array.isArray(oldTargetValue) ? oldTargetValue : (
                oldTargetValue && oldTargetValue.metrics ? oldTargetValue.metrics : []
            );

            this.value.baseline.value = { baseline: newValue, metrics: metricValues };
        }
    }

    @Watch('value.baseline.value', {
        deep: true
    })
    onBaselineFilterStateChanged(newValue: RoleBaselineReferenceType | null) // for external changes
    {
        if (this.shouldBypassWatchers)
        {
            log('nowatch:value.baseline.value');
            return;
        }
        log('watch:value.baseline.value');
        if (this.selectedMode === SelectedRoleBaselineMode.CultureFit && !Array.isArray(newValue) && this.selectedBaseline !== newValue?.baseline)
        {
            this.selectedBaseline = newValue?.baseline || null;
        }
    }

    @Watch('selectedMetrics')
    onSelectedMetricsChanged(newValue: [string, number][], oldValue: [string, number][])
    {
        if (this.shouldBypassWatchers)
        {
            log('nowatch:selectedMetrics');
            return;
        }
        log('watch:selectedMetrics');
        if (newValue !== oldValue)
        {
            if (this.selectedMode === SelectedRoleBaselineMode.CultureAdd)
            {
                log(newValue);
                this.value.baseline.value = newValue;
            }
            else
            {
                const asRefType = this.value.baseline.value as RoleBaselineReferenceType;
                if (Array.isArray(asRefType))
                {
                    this.value.baseline.value = newValue;
                }
                else
                {
                    asRefType.metrics = newValue;
                }
            }
        }
    }

    // Aliases for the template
    CultureFitMode = SelectedRoleBaselineMode.CultureFit;
    CultureAddMode = SelectedRoleBaselineMode.CultureAdd;

    selectedMode = SelectedRoleBaselineMode.CultureFit;

    @Watch('selectedMode')
    onselectedModeChange()
    {
        if (this.shouldBypassWatchers)
        {
            log('nowatch:selectedMode');
            return;
        }
        
        log('watch:selectedMode');
        if (this.selectedMode === SelectedRoleBaselineMode.CultureFit)
        {
            pushLogScope('selectedMode:CultureFit');
            
            const old = this.oldValue;

            log({old});
            this.oldValue = this.value.baseline;
            if (old && !old.manual && typeof old.value === 'object')
            {
                this.value.baseline = old;
            }
            else
            {
                this.value.baseline = { manual: false, value: { baseline: null, metrics: [] }, broadenFitSlider: 0, cultureAddBroadenFitSlider: 0.3, combineMode: 'or' };
            }

            this.value.isManual = false;
            this.value.baseline.manual = false;
            popLogScope();
        }
        else
        {
            const old = this.oldValue;
            this.oldValue = this.value.baseline;
            if (old)
            {
                this.value.baseline = old;
            }
            else
            {
                this.value.baseline = { manual: true, value: this.selectedMetrics, broadenFitSlider: 0, cultureAddBroadenFitSlider: 0.3, combineMode: 'or' };
            }

            this.value.isManual = true;
            this.value.baseline.manual = true;
        }
        this.$set(this.value, 'baseline', this.value.baseline);
    }

    get baselineLiteralData()
    {
        return this.value.baseline.value as RoleBaselineDefinitionValueType;
    }

    get selectedMetrics(): [string, number][]
    {
        return this.metricToggleData.toValue();
    }

    get canOpenBaseline()
    {
        return this.baselineManagerPageIndex >= 0; // && this.selectedBaselineName != null;
    }

    get baselineManagerPageIndex()
    {
        if (dashModule.activeDashboard)
        {
            return dashModule.activeDashboard.pages.findIndex(p => p.type === DashboardPageType.RoleBaselinePage);
        }
        return -1;
    }

    get selectedBaselineName()
    {
        if (this.selectedBaseline == null)
        {
            return null;
        }

        const b = this.baselineOptions.find(bo => bo.value === this.selectedBaseline);
        if (b)
        {
            return b.text;
        }
        return null;
    }

    get canSelectDashboard()
    {
        // TODO
        return true;
    }

    get categories()
    {
        return this.activeDashboardNullable ? this.activeDashboardNullable.capabilities : [];
    }

    populateMetricToggleData(selectedMetrics: [string, number][])
    {
        const selectedCapabilities: Capability[] = [];
        const checkAndAddCapability = (cap: Capability, parentCap?: Capability | null) =>
        {
            if (selectedMetrics && selectedMetrics.length)
            {
                selectedMetrics.forEach((selectedMetric) =>
                {
                    if (cap.name === selectedMetric[0])
                    {
                        cap.targetValue = selectedMetric[1];
                        cap.selected = true;
                        if (parentCap)
                        {
                            parentCap.expanded = true;
                        }
                        selectedCapabilities.push(cap);
                    }
                });
            }
        };
        this.categories.forEach(({ capabilities }) =>
        {
            capabilities.forEach((cap) =>
            {
                cap.selected = false;
                cap.expanded = false;
                cap.subCapabilities.forEach((subcap) =>
                {
                    subcap.selected = false;
                });
            });
        });
        this.categories.forEach(({ capabilities }) =>
        {
            capabilities.forEach((cap) =>
            {
                checkAndAddCapability(cap);
                cap.subCapabilities.forEach((subcap) => checkAndAddCapability(subcap, cap));
            });
        });
        return selectedCapabilities;
    }

    onClickOpenBaseline()
    {
        const path = (this.canSelectDashboard && dashModule.activeDashboard && dashModule.activeDashboard._id)
            ? `/dashboard/${dashModule.activeDashboard._id}/page/${this.baselineManagerPageIndex}`
            : `/dashboard/page/${this.baselineManagerPageIndex}`;
        if (this.baselineManagerPageIndex >= 0)
        {
            this.$router.push({
                path: path,
                query: this.selectedBaselineName ? { startingSelected: this.selectedBaselineName } : undefined,
            });
        }
    }

    async mounted()
    {
        log('role baseline filter mounted');
        this.isMounting = true;
        try
        {
            // const startFilterData = this.value;
            if (this.filter)
            {
                this.activeDashboardNullable = await dashModule.getActiveDashboard();

                const baselines = await roleBaselineModule.getAllInDashboard(this.activeDashboardNullable._id);
                const values = await dashModule.getAllDefaultFiltersForActiveDashboard();

                if (!values || !baselines)
                {
                    alert('Failed to load baseline filter data');
                    return;
                }

                this.baselineOptions = baselines.map(a => ({ text: a.name, value: a._id }));
                if (values[DashboardPageFilterType.DepartmentSelect])
                    this.departmentOptions = values[DashboardPageFilterType.DepartmentSelect].map(a=>a?.valueOf() as string);
                if (values[DashboardPageFilterType.FunctionSelect])
                    this.functionOptions = values[DashboardPageFilterType.FunctionSelect].map(a=>a?.valueOf() as string);
                if (values[DashboardPageFilterType.JobCodeSelect])
                    this.jobCodeOptions = values[DashboardPageFilterType.JobCodeSelect].map(a=>a?.valueOf() as string);
                if (values[DashboardPageFilterType.LocationSelect])
                    this.locationOptions = values[DashboardPageFilterType.LocationSelect].map(a=>a?.valueOf() as string);

                // log('state cleanup for presets');
                if (this.baselineOptions.every(({ value }) => this.selectedBaseline !== value))
                {
                    this.selectedBaseline = null;
                }
                
                if (this.value.isManual)
                {
                    this.selectedBaseline = null;
                    this.selectedMode = SelectedRoleBaselineMode.CultureAdd;
                    this.isCombinedMode = false;
                    const selectedMetrics = (this.value.baseline.value as [string, number][]);
                    if (selectedMetrics)
                    {
                        this.metricToggleData.selectedMetrics = this.populateMetricToggleData(selectedMetrics);
                    }
                }
                else
                {
                    this.selectedMode = SelectedRoleBaselineMode.CultureFit;
                    if (this.value && this.value.baseline
                        && (this.value.baseline.value as RoleBaselineReferenceType)
                        && (this.value.baseline.value as RoleBaselineReferenceType).metrics
                        && (this.value.baseline.value as RoleBaselineReferenceType).metrics.length)
                    {
                        this.isCombinedMode = true;
                        this.metricToggleData.selectedMetrics =
                            this.populateMetricToggleData((this.value.baseline.value as RoleBaselineReferenceType).metrics);
                    }
                    else
                    {
                        this.isCombinedMode = false;
                    }

                    if (this.value && this.selectedBaseline !== (this.value.baseline.value as RoleBaselineReferenceType).baseline)
                    {
                        this.selectedBaseline = (this.value.baseline.value as RoleBaselineReferenceType).baseline;
                    }
                }
                
                this.onselectedModeChange();

                this.isMounting = false;
                if (this.selectedBaseline)
                {
                    this.onSelectedBaselineChanged(this.selectedBaseline, null);
                }
                this.onSelectedMetricsChanged(this.selectedMetrics, []);
            }
        }
        finally
        {
            this.isMounting = false;
        }

        if (!this.value.baseline.combineMode) this.value.baseline.combineMode = 'or';
        if (!this.value.baseline.cultureAddBroadenFitSlider) this.value.baseline.cultureAddBroadenFitSlider = 0.3;
        if (!this.value.baseline.broadenFitSlider) this.value.baseline.broadenFitSlider = 0;
    }

    baselineOptions: { text: string, value: string }[] = [];
    departmentOptions: string[] = [];
    functionOptions: string[] = [];
    jobCodeOptions: string[] = [];
    locationOptions: string[] = [];
}

filterRuntimeComponents
    .registerComponent(DashboardPageFilterType.RoleBaseline, FilterRoleBaseline)
    .registerDataFactory(DashboardPageFilterType.RoleBaseline,
        ()=>new RoleBaselineQueryData(DashboardPageFilterType.RoleBaseline))
;
