import UserInfo from './user-info';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RoleBaselineDefinitionValueType = {
    department: string | null,
    function: string | null,
    jobCode: string | null,
    location: string | null,
    yearRange: [number, number]
};

export type RoleBaselineReferenceType =  { baseline: string | null, metrics: [string, number][] };
export type RoleBaselineFilterValueType = {
    manual: boolean;
    broadenFitSlider: number;
    cultureAddBroadenFitSlider: number;
    combineMode: 'none' | 'and' | 'or';
    value:
        RoleBaselineDefinitionValueType |
        RoleBaselineReferenceType |
        [string, number][]
}

export type FilterValueType = number | number[] | string | string[] | [string, number][] | Date | RoleBaselineFilterValueType | null;

export class QueryContextViewerQuery
{
  filterValues: FilterValueType[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageData: any;
}

export class MetricContext
{
  metricIdxMap: Map<string, number> = new Map<string, number>();
}

export class UserMetricData
{
    set(context: MetricContext, key: string, value: number)
    {
        if (this.values.length <= context.metricIdxMap.size)
        {
            this.values.length = context.metricIdxMap.size;
        }

        if (context.metricIdxMap.has(key))
        {
            this.values[context.metricIdxMap.get(key) as number] = value;
            return true;
        }
        return false;
    }

    get(context: MetricContext, key: string)
    {
        const idx = context.metricIdxMap.get(key);
        if (!idx) return 0;
        if (this.values.length < idx) return this.values[idx];
        return 0;
    }

    values: number[] = [];
}

export class UserInfoAndMetrics<PageDataT>
{
    user: UserInfo = new UserInfo();

    metrics: UserMetricData = new UserMetricData();

    pageData: PageDataT | null = null;
}

export class DashboardDataResult<ResultT>
{
    static create<T>(context: MetricContext, data: T)
    {
        const res = new DashboardDataResult<T>();
        res.data = data;

        let max = 0;
        for (const val of context.metricIdxMap.values())
        {
            if (val > max) max = val;
        }

        if (max > 0)
        {
            res.metrics.length = max + 1;
            for (const [key, val] of context.metricIdxMap)
            {
                res.metrics[val] = key;
            }
        }

        return res;
    }

    createMetricContext()
    {
        const ctxt = new MetricContext();
        for (let i = 0; i < this.metrics.values.length; ++i)
        {
            ctxt.metricIdxMap.set(this.metrics[i], i);
        }
        return ctxt;
    }

    metrics: string[] = [];

    data!: ResultT;
}
