
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceConfig, MockDataSourceDepartmentConfig, randomId, Dashboard } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import MockDepartmentEdit from './MockDepartmentEdit.vue';

@Component({components: {MockDepartmentEdit}})
export default class MockDepartmentList extends Vue
{
    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Object, required: true})
    config!: MockDataSourceConfig;

    createDepartment()
    {
        const dept = new MockDataSourceDepartmentConfig();
        let found = false;
        do
        {
            // Guarantee no duplicate IDs
            for(const dep of this.config.departments)
            {
                if (dept.id === dep.id)
                {
                    dept.id = randomId();
                    found = true;
                    break;
                }
            }
        } while(found);
        this.config.departments.push(dept);
    }

    keyOffset = 0;
    swapDepartments(a: number, b: number)
    {
        const aval = this.config.departments[a];
        const bval = this.config.departments[b];
        this.config.departments[a] = bval;
        this.config.departments[b] = aval;
        ++this.keyOffset;
    }

    copyDepartment(idx: number)
    {
        this.config.departments.push(MockDataSourceDepartmentConfig.clone(this.config.departments[idx]));
        ++this.keyOffset;
    }

    deleteDepartment(a: number)
    {
        this.config.departments.splice(a, 1);
        ++this.keyOffset;
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}
