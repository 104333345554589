

































import { Component, Vue, Prop } from 'vue-property-decorator';
import HeaderBar from '@/components/hcad/HeaderBar.vue';
import auth from '../../store/modules/AuthModule';
import { Dashboard } from '../../models/hcad/shared/dashboard';
import dashModule from '../../store/modules/DashboardModule';
import assetModule from '../../store/modules/AssetModule';

@Component({components: {HeaderBar}})
export default class AdminContainer extends Vue
{
    @Prop({ type: String, default: null })
    dashid!: string | null;

    activeDashboardNullable: Dashboard | null = null;

    get activeDashboard()
    {
        if (this.activeDashboardNullable) return this.activeDashboardNullable;

        const dash = new Dashboard();
        dash.environmentSettings.headerText = 'HCAD Admin Panel (No Dashboard Selected)';
        return dash;
    }

    get pagesToDisplay()
    {
        return this.activeDashboard.pages;
    }

    get busy_visual()
    {
        // Wrapper so that we can add other bits in here
        return this.busy;
    }

    get busy()
    {
        return this.loading;
    }

    get loggedIn()
    {
        return auth.isSignedIn;
    }

    get userName()
    {
        return auth.activeUser ? `${auth.activeUser.firstName} ${auth.activeUser.lastName}` : '';
    }

    async loadDashboard()
    {
        this.loading = true;
        if (this.dashid)
        {
            this.activeDashboardNullable = await dashModule.getDashboardById({ id: this.dashid, setActive: true });
        }
        else
        {
            this.activeDashboardNullable = await dashModule.getActiveDashboard();
        }

        this.loading = true;
    }

    loading = true;
    async mounted()
    {
        this.loading = true;
        try
        {
            await assetModule.loadAssetData();
            await this.loadDashboard();
        }
        catch(er)
        {
            console.error(`Failed to load - ${er}`);
        }
        this.loading = false;
    }
}
