


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceDepartmentConfig, MockDataSourceFunctionConfig, MockDataSourceJobCodeConfig } from '../../../../../../models/hcad/shared/dashboard';

@Component
export default class MockJobCodeCopy extends Vue
{
    @Prop({type: Object, required: true})
    readonly code!: MockDataSourceJobCodeConfig;

    @Prop({type: Object, required: true})
    readonly sourceFunction!: MockDataSourceFunctionConfig;

    @Prop({type: Array, required: true})
    readonly departments!: MockDataSourceDepartmentConfig[];

    targetFunction: MockDataSourceFunctionConfig | null = null;

    mounted()
    {
        this.$set(this, 'targetFunction', this.sourceFunction);
    }

    copy()
    {
        if (this.targetFunction)
        {
            this.targetFunction.jobs.push(MockDataSourceJobCodeConfig.clone(this.code));
            this.isShown = false;
        }
    }

    get options()
    {
        return this.departments.map(dept=>
        {
            return dept.functionSet.map(func=>
            {
                return {
                    text: dept.name + ' / ' + func.name,
                    value: func
                };
            });
        }).flat();
    }

    isShown = false;

    show()
    {
        this.targetFunction = this.sourceFunction;
        this.isShown = true;
    }

    hide()
    {
        this.isShown = false;
    }
}
