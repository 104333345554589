




























































import { Component, Vue, Prop } from 'vue-property-decorator';
import {
    DashboardPageFilterType,
    DashboardPageRoleBaselineFilter,
    DashboardPageMetricsToggleFilter
} from '@/models/hcad/shared/dashboard';
import { filterEditorComponents } from '@/utils/typed-configs';
import { validateRefs } from '@/utils/validate-refs';
import MetricsToggleConfigEdit from './MetricsToggleConfigEdit.vue';

@Component({
    components: {
        MetricsToggleConfigEdit,
    }
})
export default class RoleBaselineConfigEdit extends Vue
{
    @Prop({type: Object, required: true})
    filter!: DashboardPageRoleBaselineFilter;

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=>
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    validate()
    {
        return validateRefs(this.$refs);
    }

    created()
    {
        if (this.filter.metricToggleConfig == null)
        {
            this.filter.metricToggleConfig =
                new DashboardPageMetricsToggleFilter(DashboardPageFilterType.MetricsToggle, true, false, '');
        }
    }
}

filterEditorComponents
    .registerComponent(DashboardPageFilterType.RoleBaseline, RoleBaselineConfigEdit)
    .registerDataFactory(DashboardPageFilterType.RoleBaseline, ()=>new DashboardPageRoleBaselineFilter(DashboardPageFilterType.RoleBaseline, true, 'Baseline'))
;
