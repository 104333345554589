import User from '../../User';
import { IndividualDataTableUserData } from './dashboard';
import { UserInfoAndMetrics } from './queries';
import RoleBaseline from './role-baseline';

export class DashboardGroupMember
{
    bladeUser?: User | null = null;

    userId?: string | null = null;

    firstName?: string | null = null;

    lastName?: string | null = null;

    fullName?: string | null = null;

    /** the only required user info */
    email = '';

    invited = false;

    /** allows dynamically including info like location, department, performance, seniority, etc. */
    userdata: {
        /** will be dynamically matched to our various filters and/or matched for use by the fit computation */
        fieldName: string,
        fieldValue: string,
    }[] = [];

    // populated by backend but not currently saved in the database due to synchronization edge case concerns
    assessed = false;
}

export default class DashboardGroup
{
    _id = '';

    name = 'New Dashboard Group';

    dashboard = '';

    members: DashboardGroupMember[] = [];

    checkLinks = false;

    exploratory = false;

    updated: Date = new Date();
}

export class ComputedRoleData
{
    capabilities: number[] = [];

    frequency = 0;
}

export class GroupDataTableQueryResult
{
    groups: DashboardGroup[] = [];

    users: UserInfoAndMetrics<IndividualDataTableUserData>[] = [];

    baselines: RoleBaseline[] = [];

    roles: ComputedRoleData[] = [];
}
