import axios from '@/utils/axios';
import activeEnvironment from '@/config/Environment';
import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules';
import { Dashboard, DashboardPageFilterType } from '@/models/hcad/shared/dashboard';

class CachedDefaultFiltersType
{
    filters: FilterValueType[][] = []
    activeDashboardId = '';
}

@Module
class DashboardModule extends VuexModule
{
    activeDashboard: Dashboard | null = null;

    @Mutation
    private setActiveDashboard(dash: Dashboard)
    {
        this.activeDashboard = dash;

        if (this.activeDashboard && this.activeDashboard.environmentSettings)
        {
            const tabText = this.activeDashboard.environmentSettings.tabText;
            if (tabText && tabText.length > 0)
            {
                document.title = tabText;
            }
            else
            {
                document.title = activeEnvironment.DefaultTabText;
            }
        }
    }

    cachedDefaultFilters: CachedDefaultFiltersType | null = null;

    @Mutation
    private cacheDefaultFilters(value: CachedDefaultFiltersType | null)
    {
        this.cachedDefaultFilters = value;
    }
    
    @Action
    async getAllDefaultFiltersForActiveDashboard()
    {
        if (!this.activeDashboard) return null;
        if (this.cachedDefaultFilters && this.cachedDefaultFilters.activeDashboardId === this.activeDashboard._id)
        {
            return this.cachedDefaultFilters.filters;
        }

        try
        {
            const res = await axios.get<FilterValueType[][]>(`datasources/filtervalues/${this.activeDashboard._id}`);
            const cache = new CachedDefaultFiltersType();
            cache.activeDashboardId = this.activeDashboard._id;
            cache.filters = res.data;
            this.cacheDefaultFilters(cache);
            console.log('caching filters');
            return cache.filters;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async getBladeDisplayValues(args: { dashid: string; type: DashboardPageFilterType; })
    {
        const { dashid, type } = args;
        try
        {
            const resp = await axios.get<string[]>(`/blade/displayvalues/${dashid}/${type}`);
            return resp.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return [];
    }

    @Action
    async getDefaultFilterValuesForActiveDashboard(type: DashboardPageFilterType)
    {
        const cache = await this.getAllDefaultFiltersForActiveDashboard();
        if (cache && cache.length >= type)
        {
            return cache[type];
        }
        return null;
    }

    get getDefaultFilterValuesForActiveDashboardSync()
    {
        return (type: DashboardPageFilterType)=>
        {
            const cache = this.cachedDefaultFilters;
            if (cache
                && this.activeDashboard
                && cache.activeDashboardId === this.activeDashboard._id
                && cache.filters.length >= type)
            {
                return cache.filters[type];
            }
            return null;
        };
    }

    @Action
    async getActiveDashboard(forceReload = false)
    {
        if (!forceReload && this.activeDashboard) return this.activeDashboard;
        try
        {
            const res = await axios.get<Dashboard>('/dashboards/active');
            const dash = Object.assign(new Dashboard, res.data);
            // await this.getAllDefaultFiltersForActiveDashboard();
            this.setActiveDashboard(dash);
            return dash;
        }
        catch(err)
        {
            console.error(err);
        }

        // TODO: Remove this
        // eslint-disable-next-line no-constant-condition
        if (true)
        {
            // Generating a garbage fallback dashboard in case there's
            // nothing to test against.
            const dash = new Dashboard();
            dash.name = 'Invalid Dashboard';
            dash.environmentSettings.headerText = 'Invalid Dashboard';
            dash.environmentSettings.headerLogo = '';
            this.setActiveDashboard(dash);
            return dash;
        }
    }

    @Action
    async getDashboardById(args: {id: string; setActive?: boolean | undefined })
    {
        const { id, setActive } = args;
        try
        {
            const resp =  await axios.get<Dashboard>(`/dashboards/${id}`);
            const dash = Object.assign(new Dashboard, resp.data);
            if (setActive)
            {
                await this.getAllDefaultFiltersForActiveDashboard();
                this.setActiveDashboard(dash);
            }
            return dash;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async getAllDashboards()
    {
        try
        {
            const dash =  await axios.get<Dashboard[]>('/dashboards/');
            for(let i = 0; i < dash.data.length; ++i)
            {
                dash.data[i] = Object.assign(new Dashboard, dash.data[i]);
            }
            return dash.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async createDashboard(dash: Dashboard)
    {
        try
        {
            const res =  await axios.post<Dashboard>('/dashboards/', { dashboard: dash });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async updateDashboard(dash: Dashboard)
    {
        try
        {
            const res =  await axios.patch<Dashboard>(`/dashboards/${dash._id}`, { dashboard: dash });
            return res.data;
        }
        catch(err)
        {
            console.error(err);
        }
        return null;
    }

    @Action
    async deleteDashboard(id: string)
    {
        try
        {
            await axios.delete(`/dashboards/${id}`);
            return true;
        }
        catch(err)
        {
            console.error(err);
        }
        return false;
    }
}

// Register the module
import store from '../index';
import { FilterValueType } from '@/models/hcad/shared/queries';
const dashModule = new DashboardModule({store, name: 'dashboard'});
export default dashModule;