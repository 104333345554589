






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({components: {}})
export default class AssetSelectInputDirectoryEntry extends Vue
{
    @Prop({type: String, default: ''})
    readonly name!: string;

    emitClicked()
    {
        this.$emit('click');
    }
}
