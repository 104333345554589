import { RouteConfig } from 'vue-router';
import DashboardRoutes from './dashboard';
import AdminContainer from '@/views/containers/AdminContainer.vue';
import { requireIsAdmin } from '../middleware/auth';

const adminRoutes: Array<RouteConfig> = [
    {
        path: '/admin',
        redirect: 'admin/dashboard',
        name: 'adminRoot',
        props: true,
        component: AdminContainer,
        beforeEnter: requireIsAdmin,

        children: [...DashboardRoutes]
    }
];
export default adminRoutes;