


















































import { Component, Vue } from 'vue-property-decorator';
import auth from '@/store/modules/AuthModule';

@Component
export default class LoginView extends Vue
{
    form = {
        email: '',
        password: ''
    }

    emailRules = [
        (v: string) => !!v || 'Email is required',
        (v: string) => /.+@.+/.test(v) || 'Email must be valid',
    ]

    showPass = false;

    loading = false;

    async login() 
    {
        this.loading = true;
        try
        {
            await auth.login(this.form);
            if (auth.isSignedIn)
            {
                this.$router.push('/');
            }
        }
        catch(err)
        {
            alert('Failed to log in.  Please ensure that your email and password are correct.');
            console.error(err);
        }
        finally
        {
            this.loading = false;
        }
    }
}
