


























































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CapabilityCategory, Capability } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import CapabilityEdit from './CapabilityEdit.vue';
import AssetSelectInput from '@/components/AssetSelectInput.vue';

@Component({components: { CapabilityEdit, AssetSelectInput }})
export default class CapabilityConfigList extends Vue
{
    @Prop({type: Array, required: true})
    categories!: CapabilityCategory[];

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=>
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    keyOffset = 0;

    capKeyOffset = 0;

    subcapKeyOffset = 0;

    createCategory()
    {
        this.categories.push(new CapabilityCategory);
    }

    createCapability(category: CapabilityCategory)
    {
        category.capabilities.push(new Capability);
    }

    createSubCapability(capability: Capability)
    {
        capability.subCapabilities.push(new Capability);
    }

    copyCategory(idx: number)
    {
        // Assigning the object so we get the vtable
        this.categories.push(CapabilityCategory.clone(this.categories[idx]));
        ++this.keyOffset;
    }

    copyCapability(category: CapabilityCategory, idx: number)
    {
        // Assigning the object so we get the vtable
        category.capabilities.push(Capability.clone(category.capabilities[idx]));
        ++this.capKeyOffset;
    }

    copySubCapability(capability: Capability, idx: number)
    {
        // Assigning the object so we get the vtable
        capability.subCapabilities.push(Capability.clone(capability.subCapabilities[idx]));
        ++this.subcapKeyOffset;
    }

    deleteCategory(idx: number)
    {
        this.categories.splice(idx, 1);
        ++this.keyOffset;
    }

    deleteCapability(category: CapabilityCategory, idx: number)
    {
        category.capabilities.splice(idx, 1);
        ++this.capKeyOffset;
    }

    deleteSubCapability(capability: Capability, idx: number)
    {
        capability.subCapabilities.splice(idx, 1);
        ++this.subcapKeyOffset;
    }

    swapCategory(a: number, b: number)
    {
        const aval = this.categories[a];
        this.categories[a] = this.categories[b];
        this.categories[b] = aval;
        ++this.keyOffset;
    }

    swapCapability(category: CapabilityCategory, a: number, b: number)
    {
        const aval = category.capabilities[a];
        category.capabilities[a] = category.capabilities[b];
        category.capabilities[b] = aval;
        ++this.capKeyOffset;
    }

    swapSubCapability(capability: Capability, a: number, b: number)
    {
        const aval = capability.subCapabilities[a];
        capability.subCapabilities[a] = capability.subCapabilities[b];
        capability.subCapabilities[b] = aval;
        ++this.subcapKeyOffset;
    }

    errorMsg = '';
    validate()
    {
        return validateRefs(this.$refs);
    }
}
