import { RouteConfig } from "vue-router";
import PassthroughContainer from '@/views/containers/PassthroughContainer.vue';
import DashboardEdit from '@/views/admin/dashboard/DashboardEdit.vue';
import DashboardList from '@/views/admin/dashboard/DashboardList.vue';

const dashboardRoutes: Array<RouteConfig> = [
    {
        path: 'dashboard',
        redirect: 'dashboard/list',
        component: PassthroughContainer,
        children: [
            {
                path: 'new',
                name: 'newDashboard',
                component: DashboardEdit
            },
            {
                path: 'edit/:targetId',
                props: true,
                name: 'dashboardEdit',
                component: DashboardEdit
            },
            {
                path: 'list',
                name: 'dashboardList',
                component: DashboardList
            }
        ]
    }
    
];

export default dashboardRoutes;