




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, FieldValueType, IndividualDataTableUserData, BaseDashboardPage, Dashboard } from '@/models/hcad/shared/dashboard';
import { fieldViewerComponents, FieldViewerContext, getIndex, getValue } from '@/utils/typed-configs';
import { UserInfoAndMetrics } from '../../models/hcad/shared/queries';

// TODO: Automate this...?
require('./individual-data-table-renderers/CapabilitiesRenderer.vue');
require('./individual-data-table-renderers/DateCompletedRenderer.vue');
// require('./individual-data-table-renderers/MatchPercentRenderer.vue');
require('./individual-data-table-renderers/LongevityPredictionRenderer.vue');
require('./individual-data-table-renderers/TimeInOrgRenderer.vue');
require('./individual-data-table-renderers/UserEmailRenderer.vue');
require('./individual-data-table-renderers/UserNameRenderer.vue');

function defaultToString(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext)
{
    const index = getIndex(fieldIdx, fieldType, a);
    const aValue = getValue(a, index);
    return aValue ? `${aValue}` : '';
}

function defaultStringSort(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, b: FieldViewerContext)
{
    const index = getIndex(fieldIdx, fieldType, a);
    const aValue = getValue(a, index);
    const bValue = getValue(b, index);
    const aDefined = (aValue != null);
    const bDefined = (bValue != null);
    if (aDefined && !bDefined) return -1;
    if (!aDefined && bDefined) return 1;
    if (!aDefined && !bDefined) return 0;
    const aval = `${a[0][index][1]}`;
    const bval = `${b[0][index][1]}`;
    return -aval.localeCompare(bval);
}

const defaultFactory = () => ({ toString: defaultToString, sort: defaultStringSort });

// Default sorters
fieldViewerComponents
    .registerDataFactory(DashboardPageFieldType.Location, defaultFactory)
    .registerDataFactory(DashboardPageFieldType.JobCode, defaultFactory)
    .registerDataFactory(DashboardPageFieldType.Department, defaultFactory)
    .registerDataFactory(DashboardPageFieldType.Function, defaultFactory)
;

@Component
export default class IndividualDataTableRowRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: FieldValueType;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    @Prop({type: Object, required: true})
    page!: BaseDashboardPage;

    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    get component()
    {
        return fieldViewerComponents.getComponent(this.type);
    }
}
