/* eslint-disable @typescript-eslint/no-explicit-any */

export const questions = new Map<any, string>([
    [0, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Can you share an example of a time when you had to unexpectedly help someone due to the absence of someone else?</div>
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>Were any others involved?</li>
        <li>How did the person you helped react to your assistance?</li>
        <li>Would you do anything differently today?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>A decision toward fulfilling the person's potential needs hints at Execution and Savvy. An answer that suggests connecting the person to others who might better help hints at Collaboration.</div>
    `],
    [1, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Share a story about a time at work when you were asked your opinion in order to help resolve a difference of opinion between two other people.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What contributed to your opinion?</li>
        <li>Were the people at the same job level as each other?</li>
        <li>Were the people at the same job level as you?</li>
        <li>Did the people execute based on your opinion?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>A decision toward blending the opinions of the two people into a compromise hints at Collaboration. A decision toward supporting a person at a more senior job level hints at Execution and Savvy.</div>
    `],
    [2, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Describe a time when you were a part of a group that fell significantly short of meeting an important goal.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>How much impact did you individually have on the goal outcome?</li>
        <li>How did you react after learning about missing the goal?</li>
        <li>Did you interact with any other group members?</li>
        <li>Would you do anything differently in the future?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual focused on their own work or prepared themself for the worst outcome may hint at Execution. An answer demonstrating that the individual made recommendations to others hints at Innovation and Collaboration.</div>
    `],
    [3, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Walk me through a time when you intentionally tried to learn something new instead of continuing work on something familiar.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>How did it feel?</li>
        <li>Did you encounter any obstacles? What were they?</li>
        <li>What was the outcome of your learning attempt?</li>
        <li>Given another opportunity, would you do it again?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual was willing to try something new without much difficulty may suggest a type of corporate Innovation that favors novel challenges and demonstrates a growth mindset.</div>
    `],
    [4, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Please describe a time in which you had to work on a project that didn't have well-defined instructions.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>In your opinion, what information was missing?</li>
        <li>Did you involve any other people?</li>
        <li>What resources did you have to aid your work?</li>
        <li>How did you feel about the outcome of the project?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual was successfully able to seek out additional resources or expertise in order to improve project outcomes hints at Savvy.</div>
    `],
    [5, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Share a story about a situation when you specifically chose to engage others to work with you on a project that needed to be completed quickly--instead of taking on the project all by yourself.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>Why did you involve others?</li>
        <li>What were the outcomes related to involving others?</li>
        <li>Would you approach the project any differently if you had to do it again?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual has chosen to engage others on a project with a quick deadline instead of taking it on by themself suggests one who may value the behavior of Collaboration to engage others in change to produce better project outcomes.</div>
    `],
    [6, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Can you describe an example of a time when you proactively shared an idea with someone in leadership?</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What prompted you to share the idea?</li>
        <li>What was your approach for communicating the idea?</li>
        <li>What are your observations about your experience sharing the idea with leadership?</li>
        <li>Would you do anything differently next time?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that indicates the individual has successfully experienced sharing an unsolicited idea to leadership suggests an orientation toward Execution that relates to assuredness, decisiveness, and independence.</div>
    `],
    [7, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Can you tell me about a time in which you chose between working on a project that was similar to what you've encountered in the past, and a project that was new and would require some research and experimentation?</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What did you consider in making your project choice?</li>
        <li>Would you make the same project choice again?</li>
        <li>What factors might cause you to change your project choice in the future?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that demonstrates that the individual has previously chosen a new project over a familiar project hints at a willingness to Innovate and their potential attraction to novel experiences and an internal self-belief.</div>
    `],
    [8, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Please describe a time when you encountered two different approaches to solving a problem, and then offered a solution that blended the approaches.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What resources did you use to suggest a solution?</li>
        <li>Who else was involved?</li>
        <li>What was the outcome of the blended solution?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An answer that demonstrates that the individual has previously evaluated different approaches and successfully offered a blended solution shows a kind of Savvy around maximizing resources and supporting multiple perspectives since there was no clear "right answer" approach.</div>
    `],
    [9, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Can you share an example of a time when you recognized that a close, trusted colleague of yours who was normally outgoing had become quiet and withdrawn?</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>Did you take any action upon this observation? What was the action?</li>
        <li>Did you interact with any others in the process?</li>
        <li>Would you change your reaction to the situation if it happened again?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>The way in which the individual answers can hint at their tendency toward Collaboration. Someone who inquires privately and directly with the colleague may be more Collaborative and likely to recognize prosocial norms, demonstrate empathy, and be perceptive of others' behavior.</div>
    `],
    [10, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Tell me about a time when you were a part of a group that was informed of a new technology initiative that would change how you do some of your work.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What were your initial reactions to the change announcement?</li>
        <li>Did you take any steps to prepare for the change? What were they?</li>
        <li>If you had been in charge of rolling out the change initiative, would you have done anything differently?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>An individual who indicates taking a proactive approach to educate themself about the requirements of the change may be expressing Execution, corporate Savvy or both. Execution can be characterized by being an early adopter. Corporate Savvy may be expressed by recognizing what would be desirable for the organization or prioritizing firm needs over individual needs.</div>
    `],
    [11, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Share a story about when you have had multiple tasks to complete while being exposed to some distraction.</div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>What was your approach for completing the tasks?</li>
        <li>Have you encountered this type of scenario frequently? If so, has your process for managing your approach been consistent?</li>
        <li>Describe how you have felt in this type of situation in the past.</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>For individuals who indicate past experience with creating focused task time away from distraction, we have found a correlation to Innovation through an understanding of the need to create a process to improve creativity and focus.</div>
    `],
    [12, `
    <div style="font-weight: bold;">Potential Question Prompt:</div>
    <div>Walk me through a past experience in which you were a part of team that performed well enough to be given increased work as a result. </div> 
    <br/>
    <div style="font-weight: bold;">Related Follow-Up Questions to Gather Context:</div>
    <ul style="margin: 0;">
        <li>How did your team respond to the increased work?</li>
        <li>What was your individual role in the team's response?</li>
        <li>How did you feel about the team's increased workload?</li>
        <li>If you were in charge, would you have done anything differently?</li>
    </ul>
    <br/>
    <div style="font-weight: bold;">Internal Interviewer Notes:</div>
    <div>For individuals who indicated a tendency to try and rework the team's processes in anticipation of increased workload, they may be demonstrating Collaboration in terms of awareness and empathy for trying to manage team and organizational needs.</div>
    `],

    [13, `"你做得非常好！通過模擬遊戲，可以看出你非常樂意和團隊溝通，也會主動提出自己的看法，這點是非常重要的。

在工作協作上，也能看出你非常遵從指令，這對於工作效率有很大的作用。

在表達想法和邏輯思維上，你也表現得很不錯，如能夠在表達技巧上採用更直接和清晰的對答，將會有更大的幫助。

以下是一些可以改善你在工作協作和表達上的技巧提升課程:

1.《高效溝通技巧》：學習如何在各種情境下更有效地交流。
2.《協作與團隊合作》：提升你在團隊中的協作能力和互動技巧。
3.《增強說服力與表達力》：學習如何更自信和清晰地表達自己的想法。"`.replace(/\n/g, '<br/>')],

    [14, `"你的表現非常棒！在模擬遊戲中，你展現了出色的問題解決能力和敏銳的思考能力。你能迅速找到問題的根源，並提出創新且有效的解決方案，這一點非常值得讚賞。

你還能運用邏輯思維來分析和處理複雜的情況，這對於任何工作都是非常重要的。如果你能進一步提升你的批判性思維和快速決策能力，將會使你在職業生涯中更上一層樓。

以下是一些可以幫助你提升認知和解決問題技能的課程：

1.《創意思維與問題解決》：學習如何發掘創新解決方案，並提升你的問題解決能力。
2.《邏輯思維與決策》：提高你的邏輯分析能力，並學習如何在壓力下快速做出明智的決策。
3.《批判性思維訓練》：培養你的批判性思維能力，幫助你更有效地分析和解決問題。"""`.replace(/\n/g, '<br/>')],
    [15, `"你在工作核心技能上的表現令人印象深刻！通過模擬遊戲，我們看到你具備很強的基本工作技能，無論是時間管理、任務執行，還是資源利用，你都做得很好。

你能夠高效地完成任務，並且在面對挑戰時也能冷靜應對。如果你能在某些具體技能上進一步提升，比如高效寫作或數據分析，這將使你在工作中更加如魚得水。

以下是一些可以提升你工作核心技能的課程：

1.《高效時間管理》：學習如何更好地規劃和管理你的工作時間。
2.《專業寫作技巧》：提高你的商業寫作和溝通技巧。
3.《數據分析基礎》：掌握基本的數據分析技能，提升你的工作效率。"""`.replace(/\n/g, '<br/>')],
    [16, `"你的工作態度和團隊動力非常積極！在模擬遊戲中，你展示了強烈的工作熱情和積極的態度。你不僅樂於助人，還能很好地激勵和影響你的團隊成員，這對於團隊合作非常重要。

你總是願意接受新的挑戰，並且能夠在困難時刻保持積極的心態。如果你能進一步提升你的領導力和團隊管理能力，將會對你的職業發展有很大幫助。

以下是一些可以提升你工作態度和團隊動力的課程：

1.《領導力培訓》：學習如何成為一名優秀的領導者，激勵和管理你的團隊。
2.《情緒智力與自我管理》：提高你的情緒智力，幫助你在壓力下保持積極的心態。
3.《高效團隊合作》：學習如何建立和維護高效的團隊合作關係。"`.replace(/\n/g, '<br/>')],
]);

export const capabilityNameToQuestionsMap = new Map<string, any[]>([
    ['Execution', [0, 1, 2, 6, 10]],
    ['Innovation', [2, 3, 7, 11]],
    ['Collaboration', [0, 1, 2, 5, 9, 12]],
    ['Savvy', [0, 1, 4, 8, 10]],

    ['人際交往技能', [13]],
    ['認知和解決問題技能', [14]],
    ['工作核心技能', [15]],
    ['工作態度和團隊動力', [16]],
]);
