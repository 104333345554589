




































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { BaseDashboardPage, DashboardPageType, Dashboard } from '../../../../models/hcad/shared/dashboard';
import { pageEditorComponents } from '../../../../utils/typed-configs';
import { validateRefs } from '../../../../utils/validate-refs';
import FilterList from './FilterList.vue';
import { ControlGroup } from '../../../../models/hcad/shared/dashboard';

// TODO: Automate this...?
require('./page-configurators/BenchmarkPageConfigEdit.vue');
require('./page-configurators/IndividualPageConfigEdit.vue');
require('./page-configurators/RoleBaselinePageConfigEdit.vue');
require('./page-configurators/AttritionAnalysisPageConfigEdit.vue');
require('./page-configurators/DashboardGroupManagerPageConfigEdit.vue');
require('./page-configurators/GroupDataTablePageConfigEdit.vue');

@Component({components: {FilterList}})
export default class PageEdit extends Vue
{
    @Prop({type: Object, required: true})
    page!: BaseDashboardPage;

    @Prop({type: Object, required: false})
    dashboard!: Dashboard | null;

    keyOffset = 0;

    get pageTypeOptions()
    {
        return [
            {
                text: this.typeString,
                value: null
            }
        ];
    }

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=>
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    get typeString()
    {
        return DashboardPageType[this.page.type];
    }

    get editorComponent()
    {
        return pageEditorComponents.getComponent(this.page.type);
    }

    get groups()
    {
        // undefined check needed for backward compat... not sure if this method of fixing might cause issues
        if (!(this.page.filterConfig.groups))
        {
            this.page.filterConfig.groups = [];
        }
        return this.page.filterConfig.groups;
    }

    updateFilterListComponent()
    {
        const fl = this.$refs['filterlist'] as Vue;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (fl) (fl as any).onGroupsChanged();
    }

    onGroupLabelChanged()
    {
        this.updateFilterListComponent();
    }

    onClickAddGroup()
    {
        this.groups.push(new ControlGroup());
        ++this.keyOffset;
        if (this.groups.length === 1) this.$forceUpdate(); // TODO: don't get why this is needed
        this.updateFilterListComponent();
    }

    deleteGroup(idx: number)
    {
        this.groups.splice(idx, 1);
        ++this.keyOffset;
        this.updateFilterListComponent();
    }

    swapGroup(a: number, b: number)
    {
        const bval = this.groups[b];
        this.groups[b] = this.groups[a];
        this.groups[a] = bval;
        ++this.keyOffset;
    }

    validate()
    {
        return validateRefs(this.$refs);
    }
}
