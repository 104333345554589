



































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Capability, ValueColor } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import AssetSelectInput from '@/components/AssetSelectInput.vue';
import dashModule from '@/store/modules/DashboardModule';

@Component({components: { AssetSelectInput }})
export default class CapabilityEdit extends Vue
{
    @Prop({type: Object, required: true})
    capability!: Capability;

    rules = {
        required: (value: string) => (!!value && value.trim().length > 0) || 'This field is required',
        requiredNumber: (value: number) => (!!value) || 'This field is required',
        url: () => true,
        requiredOrOther: (other: string | null) => (value: string)=> 
        {
            if (!!other && other.trim().length > 0) return true;
            return (!!value && value.trim().length > 0) || 'This field is required';
        }
    };

    get isRangeEnabled()
    {
        return this.capability.range !== null && this.capability.range.length == 2;
    }

    rangeCheckbox = false;
    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    @Watch('rangeCheckbox')
    onRangeCheckboxChange(val: boolean)
    {
        if (val)
        {
            if (this.capability.range == null || this.capability.range.length != 2)
            {
                this.capability.range = [this.dashboard.capabilityRange[0], this.dashboard.capabilityRange[1]];
            }
        }
        else
        {
            this.capability.range = null;
        }
    }

    mounted()
    {
        if (this.isRangeEnabled)
        {
            this.rangeCheckbox = true;
        }
    }

    keyOffset = 0;

    createValueColor()
    {
        this.capability.valueColors.push(new ValueColor);
    }

    deleteValueColor(idx: number)
    {
        this.capability.valueColors.splice(idx, 1);
        ++this.keyOffset;
    }

    errorMsg = '';
    validate()
    {
        return validateRefs(this.$refs);
    }
}
