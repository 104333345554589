var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","absolute":"","color":"rgb(38, 39, 43)","dark":"","clipped-left":""},scopedSlots:_vm._u([(!_vm.hideTabs && _vm.loggedIn && _vm.pageList.length > 0)?{key:"extension",fn:function(){return [_c('div',{staticStyle:{"position":"relative","display":"flex","flex-flow":"row nowrap","justify-content":"flex-start","align-items":"center","background-color":"rgb(51, 52, 57)","width":"calc(100% + 32px)","margin":"-4px -16px"}},[_c('v-btn',{staticStyle:{"height":"45px","background-color":"rgb(51, 52, 57)","z-index":"10000"},on:{"click":_vm.toggleDashboardList}},[_c('v-icon',[_vm._v("mdi-apps")])],1),_c('div',{staticStyle:{"border":"1px solid rgb(0, 180, 170)","width":"0","height":"40px","margin":"0 8px"}}),(_vm.showDashboardList)?_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","background":"transparent","height":"100vh","width":"100vw"},on:{"click":function($event){_vm.showDashboardList = false}}}):_vm._e(),_c('div',{class:_vm.showDashboardList ? 'dash-list' : 'dash-list-hidden',staticStyle:{"position":"absolute","top":"100%","left":"0","background":"black","z-index":"10000"}},_vm._l((_vm.dashboardList),function(dash,dashidx){return _c('div',{key:("" + dashidx),staticClass:"dash-sidebar-link",on:{"click":function($event){return _vm.openDashboard(dash)}}},[_c('div',[_vm._v(_vm._s(dash.name))])])}),0),_c('v-tabs',{attrs:{"slider-size":"4","background-color":"rgb(51, 52, 57)"}},[_c('v-tabs-slider',{attrs:{"color":"rgb(0, 180, 170)"}}),_vm._l((_vm.pageList),function(pg,idx){return [(!pg.hideInTabBar)?_c('v-tab',{key:idx,staticStyle:{"background-color":"rgb(51, 52, 57)"},style:({
                            color: (_vm.$route.params['index'] === ("" + idx)) ? 'white' : 'rgb(162, 164, 174)',
                        }),attrs:{"to":(_vm.canSelectDashboard && _vm.dashboard && _vm.dashboard._id)
                            ? ("/dashboard/" + (_vm.dashboard._id) + "/page/" + idx)
                            : ("/dashboard/page/" + idx)}},[_vm._v(_vm._s(pg.name))]):_vm._e()]})],2)],1)]},proxy:true}:null],null,true)},[(_vm.logo)?_c('img',{staticStyle:{"max-height":"100%","max-width":"100%"},attrs:{"src":_vm.logo}}):_c('div',{staticStyle:{"width":"200px","height":"45px"}}),_c('v-spacer'),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),(_vm.loggedIn)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"height":"40"}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"color":"grey","size":"40"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-circle ")])],1),_c('div',{staticStyle:{"margin-left":"8px"}},[_vm._v(_vm._s(_vm.userName))]),_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2470827937)},[_c('v-list',[(_vm.shouldDisplayAdminButton)?_c('v-list-item',{on:{"click":function($event){return _vm.$router.push('/admin')}}},[_c('v-list-item-title',[_vm._v("Admin")])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }