



































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import {
    DashboardPageType,
    GroupDataTableDashboardPage, 
    IndividualDataTableUserData, 
    // FieldValueType,
    DashboardPageFieldType,
    DashboardPageFilterType,
    MetricsToggleQueryData,
    BaseFilterQueryData,
    RoleBaselineQueryData
} from '@/models/hcad/shared/dashboard';

import { pageViewerComponents, /* fieldViewerComponents */ } from '@/utils/typed-configs';
import dataSourceModule from '@/store/modules/DataSourceModule';
import dashModule from '@/store/modules/DashboardModule';
import { UserInfoAndMetrics, FilterValueType } from '@/models/hcad/shared/queries';
import CapabilitiesRenderer from './individual-data-table-renderers/CapabilitiesRenderer.vue';
import DashboardGroup, { DashboardGroupMember, GroupDataTableQueryResult } from '@/models/hcad/shared/dashboard-group';
import Gem from '../../components/Gem.vue';
import GemDetails from '../../components/GemDetails.vue';
import dashboardGroupModule from '@/store/modules/DashboardGroupModule';

@Component({
    components: {
        CapabilitiesRenderer,
        Gem,
        GemDetails,
    }
})
export default class GroupDataTable extends Vue
{
    @Prop({type: Object, required: true})
    page!: GroupDataTableDashboardPage;

    @Prop({type: Number, required: true})
    index!: number;

    @Prop({type: Array, required: true})
    filterState!: BaseFilterQueryData[];

    @Prop({type: Array, required: true})
    desiredMetrics!: string[];

    gemDetailsOpen = false;
    gemDetails: number[] = [];
    useData2 = false;

    groupExplorationModalOpen = false;
    groupExplorationTableFilter = '';
    groupExplorationTableLoading = false;
    groupExplorationTableSortBy = '0';
    groupExplorationTableSortDesc = true;
    groupExplorationTableItemsPerPage = 25;
    groupExplorationNameInput = '';
    groupExplorationMembers: string[] = []; // emails

    DashboardPageFieldType = DashboardPageFieldType;

    selectedGroup: DashboardGroup | null = null;

    /** role index -> role metrics, written by python API */
    roleMetricCache = new Map<number, number[]>();
    /** email -> user metrics, written by dashboard data source */
    userMetricCache = new Map<string, number[]>();
    /** group name -> email -> role index, written by solver */
    groupRolesCache = new Map<string, Map<string, number>>();
    /** email -> array of calculated distances, indexed by role index */
    distanceNormalizedPercentageCache = new Map<string, number[]>()
    normalizationMax = 22.45; // sqrt(14*((9-3)^2))

    searchText = '';

    sortBy = '0';
    sortDesc = true;

    itemsPerPage = 25;
    subItemsPerPage = 25;

    get footerProps()
    {
        return {
            'items-per-page-options': [10, 25, 50, 100],
        };
    }

    tableHeight()
    {
        return window.innerWidth > 878 ? 'calc(100vh - 300px)' : 'calc(100vh - 355px)';
    }

    subTableHeight()
    {
        return window.innerWidth > 878 ? 'calc(100vh - 300px)' : 'calc(100vh - 355px)';
    }

    onResize()
    {
        this.$forceUpdate();
    }

    @Watch('filterState', { deep: true })
    async onNewFilters()
    {
        await this.refresh();
    }

    openGem(data: number[])
    {
        console.log('openGem');
        this.useData2 = false;
        this.gemDetails = data;
        this.gemDetailsOpen = true;
    }

    openRole(data: number[])
    {
        console.log('openRole');
        this.useData2 = true;
        this.gemDetails = data;
        this.gemDetailsOpen = true;
    }

    customSort(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        items: any[],
        sortBy: number[],
        sortDescTab: boolean[]
    )
    {
        // Only support sorting with a single element
        if (sortBy.length != 1 || items.length === 0) return items;

        const sortDesc = sortDescTab[0];
        const fieldIdx = sortBy[0];
        const itemKeys = Object.keys(items[0]);
        if (itemKeys.length <= fieldIdx) return items;
        const fieldKey = itemKeys[fieldIdx];
        // const field = this.page.fields[fieldIdx];
        // const sortFactory = fieldViewerComponents.getDataFactory(field.type);
        // if (!sortFactory) return items;

        // const sortFn = sortFactory();
        // if (!sortFn) return items;

        // console.log(fieldIdx);
        items.sort((a, b)=>
        {
            // console.log(a);
            // console.log(b);
            // const res = sortFn(fieldIdx, field.type, a, b);
            const sortReferenceA = a[fieldKey] || a[fieldIdx];
            const sortReferenceB = b[fieldKey] || b[fieldIdx];
            // console.log(sortReferenceA);
            // console.log(sortReferenceB);
            let res = 0;
            if (sortReferenceA != null && sortReferenceB == null) res = 1;
            else if (sortReferenceA == null && sortReferenceB != null) res = -1;
            else if (sortReferenceA == null && sortReferenceB == null) res = 0;
            else res = `${sortReferenceA}`.localeCompare(`${sortReferenceB}`, undefined, {numeric: true, sensitivity: 'base'});
            // console.log(res);
            if (sortDesc)
            {
                return -res;
            }
            return res;
        });

        // console.log(sortBy);
        return items;
    }

    changeSort(column: number)
    {
        if (this.sortBy == `${column}`)
        {
            this.sortDesc = !this.sortDesc;
        }
        else
        {
            this.sortBy = `${column}`;
            this.sortDesc = true;
        }
    }

    rowClass(item: { fit: string, name: string })
    {
        return `idt-clickable-row${(this.selectedGroup && this.selectedGroup.name === item.name) ? ' idt-selected-row' : ''}`;
    }

    clickedTableRow(item: { fit: string, name: string })
    {
        if (!(this.queryResult)) return;
        if (item.name)
        {
            this.selectedGroup = this.queryResult.groups.find(g => g.name === item.name) || null;
        }
    }

    hoverText(text: string)
    {
        switch (text.toLowerCase())
        {
        case 'ideal group member': return 'Based on the analyzed group data, this is the Almas Index of an Ideal Group Member for a high-performance group. A group member is matched to an Ideal Group Member automatically to maximize the overall Group Fit.';
        case 'match percent': return 'The closer the alignment (green) between Ideal Group Member and group member Gem, the higher the odds of a high-performance group.';
        case 'gem': return 'The Almas Index of each group member is shown below. Click the Gem to see the Almas Index in greater detail.';
        default: return '';
        }
    }

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get capabilities()
    {
        return this.dashboard.capabilities.flatMap(v=>v.capabilities);
    }

    groupUserRoleIndex(email: string, groupName = '')
    {
        let solve = this.groupRolesCache.get(groupName);
        if (!solve)
        {
            if (groupName && this.queryResult && this.queryResult.groups.length > 0)
            {
                const group = this.queryResult.groups.find(g => g.name === groupName);
                if (group)
                {
                    solve = this.solveRoleAssignment(group.members.map(m => m.email), groupName);
                }
            }
            else if (this.queryResult && this.queryResult.users.length > 0)
            {
                solve = this.solveRoleAssignment(this.queryResult.users.map(u => u.user.email));
            }
        }
        return solve ? solve.get(email) : undefined;
    }

    roleMetricValues(roleIndex: number)
    {
        const values: number[] = this.roleMetricCache.get(roleIndex) || [];
        if (values.length === 0)
        {
            // generate random role
            for (let i = 0; i < (this.capabilities.length || 14); ++i)
            {
                values.push(Math.round(3 + Math.random() * 6));
            }
            // this.roleMetricCache.set(roleIndex, values);
        }
        return values;
    }

    userMetricValues(email: string, metrics: number[] = [])
    {
        const values: number[] = this.userMetricCache.get(email) || [];
        if (values.length === 0)
        {
            if (metrics.length === 0)
            {
                for (let i = 0; i < (this.capabilities.length || 14); ++i)
                {
                    values.push(Math.round(3 + Math.random() * 6));
                }
            }
            else
            {
                let index = 0;
                this.capabilities.forEach(c =>
                {
                    if (metrics.length > index)
                    {
                        values.push(metrics[index]);
                        index += (1 + c.subCapabilities.length);
                    }
                });
            }
            this.userMetricCache.set(email, values);
        }
        return values;
    }

    euclideanDistanceNormalizedPercentage(roleIndex: number, metrics: number[], email: string)
    {
        // console.log(`distance calc ${email}, ${roleIndex}`);
        let cachedDistanceNormalizedPercentages = this.distanceNormalizedPercentageCache.get(email);
        if (cachedDistanceNormalizedPercentages == null)
        {
            cachedDistanceNormalizedPercentages = [];
        }
        while (cachedDistanceNormalizedPercentages.length <= roleIndex)
        {
            cachedDistanceNormalizedPercentages.push(-1);
        }
        if (cachedDistanceNormalizedPercentages[roleIndex] < 0)
        {
            const origin = this.roleMetricValues(roleIndex);
            const point = this.userMetricValues(email, metrics);
            if (origin.length != point.length) return 0; // dimensional mismatch, this should never happen
            cachedDistanceNormalizedPercentages[roleIndex] =
                100 // scaled to percentage
                    * (1 // inverted (max distance = 0; min distance = 1)
                        - (
                            // euclidean distance
                            Math.sqrt(
                                point.reduce(
                                    (prev, curr, currInd) => prev + (curr - origin[currInd])**2,
                                    0
                                )
                            )
                            // normalized
                            / this.normalizationMax
                        )
                    );
            this.distanceNormalizedPercentageCache.set(email, cachedDistanceNormalizedPercentages);
            return cachedDistanceNormalizedPercentages[roleIndex];
        }
        return cachedDistanceNormalizedPercentages[roleIndex];
    }

    get filterValues(): FilterValueType[]
    {
        return this.filterState.map(fs => fs.toValue());
    }

    get anySelectedMetrics(): boolean
    {
        return this.filterState.some((fs) =>
            fs.type === DashboardPageFilterType.MetricsToggle
            && (fs as MetricsToggleQueryData).selectedMetrics
            && (fs as MetricsToggleQueryData).selectedMetrics.length);
    }

    get isBaselineActive()
    {
        return this.filterState.some((fs) =>
        {
            if (fs.type === DashboardPageFilterType.RoleBaseline)
            {
                const typedfs = (fs as RoleBaselineQueryData);
                return typedfs.baseline.manual || typedfs.baseline.value;
            }
            return false;
        });
    }

    queryResult: GroupDataTableQueryResult | null = null;

    loading = false;

    get emailUserMap()
    {
        const map = new Map<string, UserInfoAndMetrics<IndividualDataTableUserData>>();
        if (!(this.queryResult)) return map;
        this.queryResult.users.forEach(u => map.set(u.user.email, u));
        return map;
    }

    get emailGroupsMap()
    {
        const map = new Map<string, DashboardGroup[]>();
        if (!(this.queryResult)) return map;
        this.queryResult.groups.forEach(g => g.members.forEach(m =>
        {
            const existing = map.get(m.email);
            if (existing)
            {
                existing.push(g);
            }
            else
            {
                map.set(m.email, [g]);
            }
        }));
        return map;
    }

    // /** role index -> role metrics, written by python API (for now, autogenerated) */
    // get roleMetricCache()
    // {
    //     const map = new Map<number, number[]>();
    //     if (!(this.queryResult)) return map;
    //     let largestGroupSize = 0;
    //     console.log('?');
    //     this.queryResult.groups.forEach(g => 
    //     {
    //         if (g.members.length > largestGroupSize)
    //         {
    //             largestGroupSize = g.members.length;
    //         }
    //     });
    //     console.log(largestGroupSize);
    //     for (let i = 0; i < largestGroupSize; ++i)
    //     {
    //         map.set(i, this.roleMetricValues(i));
    //     }
    //     return map;
    // }

    get tableHeaders()
    {
        return [
            {
                text: 'Fit',
                value: 'fit',
                sortable: true,
            },
            {
                text: 'Group Name',
                value: 'name',
                sortable: true,
            },
        ];
    }

    solveAchieved(candidateSolve: number[])
    {
        // console.log(candidateSolve);
        const count = new Array<number>(this.roleMetricCache.size).fill(0);
        candidateSolve.forEach((value) =>
        {
            if (value < count.length)
            {
                ++(count[value]);
            }
        });
        if (count.every(c => c < 2) || count.every(c => c > 0))
        {
            return true;
        }
        return false;
    }

    buildCandidateSolve(memberEmails: string[], sortedMatches: Map<string, { roleIndex: number, match: number }[]>)
    {
        // console.log(sortedMatches);
        return memberEmails.map(email => 
        {
            const matches = sortedMatches.get(email);
            if (!matches || matches.length === 0) return 0;
            return matches[0].roleIndex;
        });
    }

    solveRoleAssignment(memberEmails: string[], groupName = '')
    {
        // for each member, find top role.
        const sortedMatches = new Map<string, { roleIndex: number, match: number }[]>();
        memberEmails.forEach(email =>
        {
            // console.log(email);
            const user = this.emailUserMap.get(email);
            const metrics = this.userMetricValues(email, user ? user.metrics.values : []);
            // console.log(metrics);
            const matches: { roleIndex: number, match: number }[] = [];
            for (let i = 0; i < this.roleMetricCache.size; ++i)
            {
                matches.push({
                    roleIndex: i,
                    match: this.euclideanDistanceNormalizedPercentage(i, metrics, email)
                });
            }
            matches.sort((a, b) =>
            {
                return b.match - a.match; // descending
            });
            sortedMatches.set(email, matches);
        });
        // if there are repeat roles and at least one missing role,
        // compute role swap deltas and pick the lowest delta;
        // repeat until one of every role or no repeat roles.
        let candidateSolve = this.buildCandidateSolve(memberEmails, sortedMatches);
        let bestCandidateSolve = candidateSolve;
        let bestCandidateSolveConstraintScore = new Set(bestCandidateSolve).size;
        while (!(this.solveAchieved(candidateSolve)))
        {
            // this isn't necessarily the fastest algorithm nor will it always get the best result
            // but I think it's a solid middle ground to start from.
            let smallestDelta = 100; // 100 is the largest possible delta (matches go from 0 to 100)
            let smallestDeltaEmail = '';
            let smallestNewRoleDelta = 100;
            let smallestNewRoleDeltaEmail = '';
            memberEmails.forEach(email =>
            {
                const matches = sortedMatches.get(email);
                if (matches && matches.length > 1)
                {
                    const delta = (matches[0].match - matches[1].match);
                    if (delta <= smallestDelta)
                    {
                        smallestDelta = delta;
                        smallestDeltaEmail = email;
                    }
                    if (delta <= smallestNewRoleDelta && !(candidateSolve.includes(matches[1].roleIndex)))
                    {
                        smallestNewRoleDelta = delta;
                        smallestNewRoleDeltaEmail = email;
                    }
                }
            });
            // prefer a new role
            const roleSwapTargetEmail: string = smallestNewRoleDeltaEmail || smallestDeltaEmail;
            if (roleSwapTargetEmail)
            {
                const matches = sortedMatches.get(roleSwapTargetEmail);
                if (matches)
                {
                    matches.shift(); // remove top match
                    sortedMatches.set(roleSwapTargetEmail, matches); // not sure if this is necessary
                    candidateSolve = this.buildCandidateSolve(memberEmails, sortedMatches);
                    if (smallestNewRoleDeltaEmail)
                    {
                        let candidateSolveConstraintScore = new Set(candidateSolve).size;
                        if (candidateSolveConstraintScore > bestCandidateSolveConstraintScore)
                        {
                            // only update what's considered as the best solve if we've improved the constraint
                            // e.g. if the count of unique roles has increased
                            bestCandidateSolve = candidateSolve;
                            bestCandidateSolveConstraintScore = new Set(bestCandidateSolve).size;
                        }
                    }
                }
                else
                {
                    // should not be possible
                    console.error('something went horribly wrong');
                    break;
                }
            }
            else
            {
                // no way to make progress, just go with the current solve
                // (this case will be hit if there are no more matches to remove from the sorted map)
                // (also keeps the while loop limited to (number of members) * (number of roles) iterations)
                break;
            }
        }
        const solve = new Map<string, number>();
        memberEmails.forEach((email, index) => solve.set(email, bestCandidateSolve[index]));
        this.groupRolesCache.set(groupName, solve);
        return solve;
    }

    computeFitFromGroup(memberEmails: string[], groupName = '')
    {
        // let fit = 'HIGH';
        // console.error(`START ${groupName}`);
        const sumPercents = memberEmails.reduce((prev, curr, currInd) =>
        {
            // console.log(`Reached ${curr}, cur value = ${prev}`);
            const user = this.emailUserMap.get(curr);
            
            const groupUserRoleIndex = this.groupUserRoleIndex(curr, groupName);
            
            let val = 0;
            if (!user)
            {
                val = prev
                    + this.euclideanDistanceNormalizedPercentage(
                        ((groupUserRoleIndex == null) ? currInd : groupUserRoleIndex),
                        [],
                        curr,
                    );
            }
            else
            {
                val = prev
                    + this.euclideanDistanceNormalizedPercentage(
                        ((groupUserRoleIndex == null) ? currInd : groupUserRoleIndex),
                        user.metrics.values,
                        curr,
                    );
            }
            // console.log(`\tComputed ${val - prev} for ${curr}`);
            return val;
        }, 0);
        const avgPercents = sumPercents / memberEmails.length;
        // if (avgPercents > 64) fit = 'HIGH';
        // else if (avgPercents < 55) fit = 'LOW';
        // else fit = 'MEDIUM';
        // console.log(`END ${groupName} - got ${fit}`);
        // return fit;
        return avgPercents;
    }

    fitText(fit: number)
    {
        return (fit < 55) ? 'LOW' : ((fit > 64) ? 'HIGH' : 'MEDIUM');
    }

    get tableData()
    {
        if (!(this.queryResult)) return [];
        return this.queryResult.groups.map(g =>
        {
            return {
                fit: this.computeFitFromGroup(g.members.map(m => m.email), g.name),
                name: g.name,
            };
        });
    }

    get filteredTableData()
    {
        if (this.searchText.trim().length === 0) return this.tableData;

        const search = this.searchText.trim().toLowerCase();
        return this.tableData.filter(a =>
        {
            const aName = a.name.toLowerCase();
            return aName.includes(search) || search.includes(aName);
        });
    }

    get subTableHeaders()
    {
        return [
            {
                text: 'Ideal Group Member',
                value: 'baseline',
            },
            {
                text: 'Match Percent',
                value: 'match',
            },
            {
                text: 'Gem',
                value: 'metrics',
            },
            {
                text: 'Name',
                value: 'fullName',
            },
            {
                text: 'Other Groups',
                value: 'otherGroups',
            },
        ];
    }

    get subTableData()
    {
        if (!(this.selectedGroup)) return [];

        // console.log(this.emailUserMap);
        // console.log(this.emailGroupsMap);
        return this.selectedGroup.members.map(m =>
        {
            const user = this.emailUserMap.get(m.email);
            const groups = this.emailGroupsMap.get(m.email);
            const result = {
                role: undefined,
                match: undefined,
                metrics: new Array<number>(),
                fullName: m.fullName || `${m.firstName} ${m.lastName}`,
                email: m.email,
                otherGroups: '',
            };
            // console.log(user);
            // console.log(groups);
            if (this.selectedGroup)
            {
                const selectedGroupName = this.selectedGroup.name;
                if (user) 
                {
                    result.metrics = user.metrics.values;
                    result.fullName = user.user.fullName;
                    result.email = user.user.email;
                }
                if (groups) result.otherGroups = groups.filter(g => g.name !== selectedGroupName).map(g => g.name).join('\n');
            }
            return result;
        });
    }

    @Watch('selectedGroup')
    onChangeSelectedGroup()
    {
        if (this.selectedGroup)
        {
            this.groupExplorationMembers.length = 0;
            this.selectedGroup.members.forEach(m =>
            {
                const mets = this.userMetricValues(m.email);
                if (mets && mets.length
                    && this.queryResult
                    && this.queryResult.users.some(u => u.user.email === m.email && u.metrics.values.length))
                {
                    this.groupExplorationMembers.push(m.email);
                }
            });
        }
    }

    get groupExplorationTableHeaders()
    {
        return [
            {
                text: 'Name',
                value: 'fullName',
                sortable: true,
            },
            {
                text: 'Email',
                value: 'email',
                sortable: true,
            },
            {
                text: 'Archetypes',
                value: 'metrics',
            },
            {
                text: 'Estimated Match',
                value: 'match',
                sortable: true,
            },
            {
                text: 'Add To Current Group',
                value: 'isMember',
                sortable: true,
            },
        ];
    }

    get groupExplorationTableData()
    {
        if (!(this.queryResult) || this.queryResult.users.length === 0) return [];
        const data = this.queryResult.users
            .filter((u) => u.metrics && u.metrics.values && u.metrics.values.length)
            .map((u, index) =>
            {
                const groupUserRoleIndex = this.groupUserRoleIndex(u.user.email);
                const result = {
                    role: undefined,
                    match: `${Math.round(this.euclideanDistanceNormalizedPercentage(
                        ((groupUserRoleIndex == null) ? index : groupUserRoleIndex),
                        u.metrics.values,
                        u.user.email))}`,
                    user: u,
                    metrics: u.metrics.values,
                    fullName: u.user.fullName,
                    email: u.user.email,
                    isMember: this.groupExplorationMembers.includes(u.user.email),
                };
                return result;
            });
        // const metricSize = data.length ? data[0].user.metrics.values.length : -1;
        // if (this.selectedGroup)
        // {
        //     this.selectedGroup.members.forEach((m, index) =>
        //     {
        //         if (data.every(u => u.email !== m.email))
        //         {
        //             const datum = {
        //                 role: undefined,
        //                 match: `${Math.round(this.euclideanDistanceNormalizedPercentage(
        //                     ((this.groupUserRoleIndex(m.email) == null) ? index : this.groupUserRoleIndex(m.email)),
        //                     this.userMetricValues(m.email),
        //                     m.email))}`,
        //                 user: new UserInfoAndMetrics<IndividualDataTableUserData>(),
        //                 metrics: this.userMetricValues(m.email),
        //                 fullName: 
        //                     (m.bladeUser && m.bladeUser.firstName && m.bladeUser.lastName)
        //                         ? `${m.bladeUser.firstName} ${m.bladeUser.lastName}`
        //                         : ((m.fullName || (m.firstName && m.lastName))
        //                             ? (m.fullName || `${m.firstName} ${m.lastName}`)
        //                             : ''),
        //                 email: m.email,
        //                 isMember: this.groupExplorationMembers.includes(m.email),
        //             };
        //             datum.user.metrics.values = this.userMetricValues(m.email);
        //             while (datum.user.metrics.values.length < metricSize)
        //             {
        //                 datum.user.metrics.values.push(...this.userMetricValues(m.email));
        //             }
        //             data.push(datum);
        //         }
        //     });
        // }
        return data;
    }

    get filteredGroupExplorationTableData()
    {
        if (this.groupExplorationTableFilter.trim().length === 0) return this.groupExplorationTableData;

        const search = this.groupExplorationTableFilter.trim().toLowerCase();
        return this.groupExplorationTableData.filter(a =>
        {
            const aName = a.fullName.toLowerCase();
            const aEmail = a.email.toLowerCase();
            return aName.includes(search) || search.includes(aName)
                || aEmail.includes(search) || search.includes(aEmail);
        });
    }

    changeGroupExplorationTableSort(column: number)
    {
        if (this.groupExplorationTableSortBy == `${column}`)
        {
            this.groupExplorationTableSortDesc = !this.groupExplorationTableSortDesc;
        }
        else
        {
            this.groupExplorationTableSortBy = `${column}`;
            this.groupExplorationTableSortDesc = true;
        }
    }

    removeGroupExplorationMember(email: string)
    {
        this.groupExplorationMembers = this.groupExplorationMembers.filter(m => m !== email);
        this.recomputeFitFlipper = !(this.recomputeFitFlipper);
    }

    addGroupExplorationMember(email: string)
    {
        this.groupExplorationMembers.push(email);
        this.recomputeFitFlipper = !(this.recomputeFitFlipper);
    }

    recomputeFitFlipper = false;
    get groupExplorationFit()
    {
        if (this.recomputeFitFlipper && !(this.recomputeFitFlipper)) return 'MEDIUM';
        return this.fitText(this.computeFitFromGroup(this.groupExplorationMembers, '_'));
    }

    get groupManagerPageIndex()
    {
        return this.dashboard.pages.findIndex(p => p.type === DashboardPageType.DashboardGroupManager);
    }

    get canSelectDashboard()
    {
        // TODO
        return true;
    }

    navigateToGroupManager()
    {
        const path = (this.canSelectDashboard && this.dashboard && this.dashboard._id)
            ? `/dashboard/${this.dashboard._id}/page/${this.groupManagerPageIndex}`
            : `/dashboard/page/${this.groupManagerPageIndex}`;
        if (this.selectedGroup != null)
        {
            this.$router.push({
                path: path,
                query: { startingSelected: this.selectedGroup.name },
            });
        }
        else
        {
            this.$router.push(path);
        }
    }

    async tryDeleteGroupExploration()
    {
        if (this.selectedGroup == null) return;

        const confirmed = confirm(`Are you sure you want to delete this group?`);
        if (confirmed)
        {
            await dashboardGroupModule.delete(this.selectedGroup._id);
            this.selectedGroup = null;
            await this.refresh();
        }
    }

    async saveGroupExploration()
    {
        if (!(this.queryResult)) return;

        // validate
        if (!(this.groupExplorationNameInput) || this.groupExplorationNameInput.trim().length === 0)
        {
            alert('Please enter a name for the job baseline.');
            return;
        }
        else if (this.queryResult.groups.some(b => b.name === this.groupExplorationNameInput.trim()))
        {
            // TODO: this check ought to be more robust eventually
            alert(`This name is already in use by another group.`);
            return;
        }

        // create
        try
        {
            const dashboardGroup = new DashboardGroup();
            dashboardGroup.name = this.groupExplorationNameInput.trim();
            dashboardGroup.exploratory = true;
            this.groupExplorationMembers.forEach(email =>
            {
                const member = new DashboardGroupMember();
                member.email = email;
                const user = this.emailUserMap.get(email);
                if (user)
                {
                    member.fullName = user.user.fullName;
                    // TODO: link bladeUser?
                }
                dashboardGroup.members.push(member);
            });
            this.selectedGroup =
                await dashboardGroupModule.create(
                    { dashboardId: ((await dashModule.getActiveDashboard())._id), dashboardGroup }
                );
            if (this.selectedGroup)
            {
                const selectedGroupId = this.selectedGroup._id;
                if (this.queryResult.groups.every(g => g._id !== selectedGroupId))
                {
                    this.queryResult.groups.push(this.selectedGroup);
                }
            }
            this.groupExplorationModalOpen = false;
        }
        catch (err)
        {
            alert('Failed to create job baseline. Please check browser console for errors.');
            console.error(err);
        }
    }

    async refresh()
    {
        this.loading = true;
        try
        {
            const data = await dataSourceModule.queryGroupDataTablePage({
                dashboard: await dashModule.getActiveDashboard(),
                pageIdx: this.index,
                filterValues: this.filterValues
            });
            
            if (data)
            {
                this.$set(this, 'queryResult', data.data);
                if (this.queryResult)
                {
                    let largestGroupSize = 0;
                    this.queryResult.groups.forEach(g => 
                    {
                        if (g.members.length > largestGroupSize)
                        {
                            largestGroupSize = g.members.length;
                        }
                    });
                    // console.log(largestGroupSize);
                    this.roleMetricCache.clear();

                    let i = -1;
                    for(const role of this.queryResult.roles)
                    {
                        this.roleMetricCache.set(++i, role.capabilities);
                    }
                    // for (let i = this.roleMetricCache.size; i < Math.max(3, largestGroupSize + 1); ++i)
                    // {
                    //     this.roleMetricCache.set(i, this.roleMetricValues(i));
                    // }
                }
            }
            else
            {
                this.$set(this, 'queryResult', null);
            }
            // console.log(this.queryResult);
        }
        finally
        {
            this.loading = false;
        }
    }

    async mounted()
    {
        await this.refresh();
    }

    created()
    {
        window.addEventListener("resize", this.onResize);
    }

    destroyed()
    {
        window.removeEventListener("resize", this.onResize);
    }
}

pageViewerComponents
    .registerComponent(DashboardPageType.GroupDataTable, GroupDataTable)
    .registerDataFactory(DashboardPageType.GroupDataTable, ()=>new GroupDataTableDashboardPage)
;
