




import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerContext, getIndex, getValue } from '../../../utils/typed-configs';

@Component
export default class DateCompletedRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: string;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    get localeDisplay()
    {
        return localeDisplay(this.value);
    }
}

function localeDisplay(v: string)
{
    return new Date(v).toLocaleString();
}

function toString(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext)
{
    const index = getIndex(fieldIdx, fieldType, a);
    const aValue = getValue(a, index);
    return aValue ? localeDisplay(aValue as string) : '';
}

function sort(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, b: FieldViewerContext)
{
    const index = getIndex(fieldIdx, fieldType, a);
    const aValue = getValue(a, index);
    const bValue = getValue(b, index);
    const aDefined = (aValue != null);
    const bDefined = (bValue != null);
    if (aDefined && !bDefined) return -1;
    if (!aDefined && bDefined) return 1;
    if (!aDefined && !bDefined) return 0;
    const aval = new Date(aValue as string);
    const bval = new Date(bValue as string);
    return (aval.getTime()) - (bval.getTime());
}

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.DateCompleted, DateCompletedRenderer)
    .registerDataFactory(DashboardPageFieldType.DateCompleted, () => ({ toString, sort }));

