

































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { 
    DashboardPageFilterType,
    BaseDashboardPageFilter,
    DashboardPageFilterConfig,
    BaseFilterQueryData,
    SelectFilterQueryData,
    RangeFilterQueryData,
    RoleBaselineQueryData,
    MetricsToggleQueryData,
} from '@/models/hcad/shared/dashboard';
import { filterRuntimeComponents } from '@/utils/typed-configs';
import FilterPreset from '@/models/hcad/shared/filter-preset';
import FilterPresetManager from '@/components/FilterPresetManager.vue';
import filterPresetModule from '@/store/modules/FilterPresetModule';

// TODO: Automate this...?
require('./FilterRange.vue');
require('./FilterSelect.vue');
require('./FilterRoleBaseline.vue');
require('./MetricsToggle.vue');

@Component({
    components: {
        FilterPresetManager,
    },
})
export default class FilterSidebar extends Vue
{
    @Prop({ type: Object, required: true })
    filterConfig!: DashboardPageFilterConfig;

    @Prop({ type: Array, required: true })
    value!: BaseFilterQueryData[];

    hasLoadedValues = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tab: any = null;

    selectedFilterPreset: FilterPreset | null = null;

    @Watch('filterConfig.filters')
    onNewFilters(newValue: BaseDashboardPageFilter[])
    {
        this.initFilterState(newValue);
    }

    filterPresetKey = 0;

    @Watch('selectedFilterPreset')
    async onSelectedNewPreset(newPreset: FilterPreset | null, oldPreset: FilterPreset | null)
    {
        if (newPreset && (!oldPreset || newPreset.name !== oldPreset.name))
        {
            console.log('Starting preset load');
            filterPresetModule.setIsLoadingNewPreset(true);
            
            const oldPresetLog = JSON.parse(JSON.stringify(oldPreset));
            const newPresetLog = JSON.parse(JSON.stringify(newPreset));
            console.log({oldPresetLog, newPresetLog});
            // console.log('set filter values from preset');

            const updateValues = ()=>
            {
                this.value.forEach((v) =>
                {
                    switch (v.type)
                    {
                    case DashboardPageFilterType.DepartmentSelect:
                        (v as SelectFilterQueryData).selectedOption = newPreset.department;
                        break;
                    case DashboardPageFilterType.LocationSelect:
                        (v as SelectFilterQueryData).selectedOption = newPreset.location;
                        break;
                    case DashboardPageFilterType.JobCodeSelect:
                        (v as SelectFilterQueryData).selectedOption = newPreset.jobCode;
                        break;
                    case DashboardPageFilterType.FunctionSelect:
                        (v as SelectFilterQueryData).selectedOption = newPreset.function;
                        break;
                    case DashboardPageFilterType.CompanySelect:
                        (v as SelectFilterQueryData).selectedOption = newPreset.company;
                        break;
                    case DashboardPageFilterType.YearsEmployedRange:
                        (v as RangeFilterQueryData).selectedMin = newPreset.yearRange[0];
                        (v as RangeFilterQueryData).selectedMax = newPreset.yearRange[1];
                        break;
                    case DashboardPageFilterType.RoleBaseline:
                        (v as RoleBaselineQueryData).isManual = newPreset.isManual;
                        (v as RoleBaselineQueryData).baseline = JSON.parse(JSON.stringify(newPreset.baseline));
                        break;
                    case DashboardPageFilterType.MetricsToggle:
                        (v as MetricsToggleQueryData).selectedMetrics = newPreset.selectedMetrics;
                        break;
                    }
                });
            };
            updateValues();
            
            // await this.$nextTick();
            filterPresetModule.setIsLoadingNewPreset(false);

            this.filterPresetKey++;
            console.log('Finished preset load');
        }
    }

    async mounted()
    {
        if (this.filterConfig && this.filterConfig.filters)
        {
            this.initFilterState(this.filterConfig.filters);
        }
    }

    initFilterState(newValue: BaseDashboardPageFilter[])
    {
        this.hasLoadedValues = true;
        if (newValue && newValue.length)
        {
            this.value.length = 0;
            newValue.forEach((f) =>
            {
                const factory = filterRuntimeComponents.getDataFactory(f.type);
                if (factory)
                {
                    this.value.push(factory());
                }
                else
                {
                    console.error(`Error: No factory for filter type ${DashboardPageFilterType[f.type]}. Please notify the developers of this error.`);
                }
            });
        }
    }

    viewerComponent(filter: BaseDashboardPageFilter)
    {
        return filterRuntimeComponents.getComponent(filter.type);
    }

    errorString(filter: BaseDashboardPageFilter)
    {
        return `No page registered for type ${this.typeString(filter)}. Please notify the developers of this error.`;
    }

    typeString(filter: BaseDashboardPageFilter)
    {
        return DashboardPageFilterType[filter.type];
    }
}
