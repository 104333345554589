










































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { MockDataSourceConfig, MockDataSourceDepartmentConfig, MockDataSourceFunctionConfig, MockDataSourceJobCodeConfig, Dashboard } from '@/models/hcad/shared/dashboard';
import { validateRefs } from '@/utils/validate-refs';
import validationRules from '@/utils/validation-rules';
import VNumberField from '@/components/VNumberField.vue';
import MockFunctionCopy from './MockFunctionCopy.vue';
import MockJobCodeCopy from './MockJobCodeCopy.vue';
import MockJobCodeEdit from './MockJobCodeEdit.vue';

@Component({components: {VNumberField, MockJobCodeEdit, MockFunctionCopy, MockJobCodeCopy}})
export default class MockDepartmentEdit extends Vue
{
    @Prop({type: Object, required: true})
    dashboard!: Dashboard;

    @Prop({type: Object, required: true})
    department!: MockDataSourceDepartmentConfig;

    @Prop({type: Object, required: true})
    config!: MockDataSourceConfig;

    keyOffset = 0;

    createFunction()
    {
        this.department.functionSet.push(new MockDataSourceFunctionConfig());
    }

    swapFunctions(a: number, b: number)
    {
        const aval = this.department.functionSet[a];
        const bval = this.department.functionSet[b];
        this.department.functionSet[a] = bval;
        this.department.functionSet[b] = aval;
        ++this.keyOffset;
    }

    copyFunction(a: number)
    {
        this.department.functionSet.push(MockDataSourceFunctionConfig.clone(this.department.functionSet[a]));
        ++this.keyOffset;
    
    }
    deleteFunction(a: number)
    {
        this.department.functionSet.splice(a, 1);
        ++this.keyOffset;
    }

    swapJobEntries(func: MockDataSourceFunctionConfig, a: number, b: number)
    {
        const aval = func.jobs[a];
        const bval = func.jobs[b];
        func.jobs[a] = bval;
        func.jobs[b] = aval;
        this.keyOffset++;
    }

    copyJobEntry(func: MockDataSourceFunctionConfig, a: number)
    {
        func.jobs.push(MockDataSourceJobCodeConfig.clone(func.jobs[a]));
    }

    deleteJobEntry(func: MockDataSourceFunctionConfig, a: number)
    {
        func.jobs.splice(a, 1);
    }

    createJobEntry(func: MockDataSourceFunctionConfig)
    {
        func.jobs.push(
            new MockDataSourceJobCodeConfig()
        );
    }

    rules = validationRules;

    validate()
    {
        return validateRefs(this.$refs);
    }
}
