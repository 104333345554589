





























































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { parse } from 'papaparse';

import {
    DashboardPageType,
    BaseFilterQueryData,
    RoleBaselineDashboardPage,
    DashboardGroupManagerDashboardPage,
} from '@/models/hcad/shared/dashboard';

import { pageViewerComponents } from '@/utils/typed-configs';
import RoleBaseline, { RoleBaselineMember } from '@/models/hcad/shared/role-baseline';
import DashboardGroup, { DashboardGroupMember } from '@/models/hcad/shared/dashboard-group';
import roleBaselineModule from '@/store/modules/RoleBaselineModule';
import dashboardGroupModule from '@/store/modules/DashboardGroupModule';
import dashModule from '@/store/modules/DashboardModule';
import { isHcadUser } from '@/utils/role-util';
import authModule from '@/store/modules/AuthModule';
// import userModule from '@/store/modules/UserModule';
import copy from 'copy-to-clipboard';

import BaselineProgressBar from './role-baseline-components/BaselineProgressBar.vue';
import CsvSanitizer from '@/components/CSVSanitizer.vue';
import { downloadCSV } from '@/utils/csv-util';

@Component({components: {BaselineProgressBar, CsvSanitizer}})
export default class RoleBaselinePage extends Vue
{
    @Prop({type: Object, required: true})
    page!: RoleBaselineDashboardPage | DashboardGroupManagerDashboardPage;

    @Prop({type: Number, required: true})
    index!: number;

    // unused
    @Prop({type: Array, required: true})
    filterState!: BaseFilterQueryData[];

    // unused
    @Prop({type: Array, required: true})
    desiredMetrics!: string[];

    showPageDescription = false;
    pageDescriptionFocused = false;

    lessThan10WarningVisible = false;
    
    get highCount()
    {
        if (!this.selectedBaselineOrGroup) return 0;
        return this.selectedBaselineOrGroup.members.filter(m => this.getPerformance(m) === 'High').length;
    }
    
    get lowCount()
    {
        if (!this.selectedBaselineOrGroup) return 0;
        return this.selectedBaselineOrGroup.members.filter(m => this.getPerformance(m) !== 'High').length;
    }

    // These are false because the new adaptive gaussian ensemble model does not require users to classify candidates.
    get highPerfWarningVisible()
    {
        return false && this.highCount < 2;
    }

    get lowPerfWarningVisible ()
    {
        return false && this.lowCount < 2;
    }

    baselinesOrGroups: (RoleBaseline | DashboardGroup)[] = [];
    selectedBaselineOrGroup: (RoleBaseline | DashboardGroup) | null = null;

    loading = false;
    bladeUserSelectLoading = false;
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedMembers: any[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectedBladeUsers: any[] = [];

    createModalOpen = false;
    multiCreate = false;
    createModalExpandedPanels: number[] = [];
    editNameModalOpen = false;
    csvModalOpen = false;
    csvFile: File | null = null;

    bladeUserSelectModalOpen = false;

    tableFilter = '';
    bladeUserSelectTableFilter = '';

    sortBy = '0';
    bladeUserSelectSortBy = '0';
    sortDesc = true;
    bladeUserSelectSortDesc = true;

    itemsPerPage = 25;
    bladeUserSelectItemsPerPage = 10;

    onInfoButtonMouseLeave()
    {
        if (!this.pageDescriptionFocused) this.showPageDescription = false;
    }

    get csvSanitizer()
    {
        return this.$refs.csvSanitizer as CsvSanitizer;
    }

    get groupsCompletedCount()
    {
        if (this.baselinesOrGroups)
        {
            return this.baselinesOrGroups.reduce((acc, bog) => bog.members.every((m) => m.assessed) ? acc + 1 : acc, 0);
        }
        return 0;
    }

    get isGroupPage()
    {
        return (this.page.type === DashboardPageType.DashboardGroupManager);
    }

    get isBaselinePage()
    {
        return (this.page.type === DashboardPageType.RoleBaselinePage);
    }

    get dataModule()
    {
        return this.isGroupPage ? dashboardGroupModule : roleBaselineModule;
    }

    get dataName()
    {
        return this.isGroupPage ? 'Group' : 'Job Baseline';
    }

    get dataNamePlural()
    {
        return this.isGroupPage ? 'Groups' : 'Job Baselines';
    }

    get dataNameShort()
    {
        return this.isGroupPage ? 'Group' : 'Baseline';
    }

    get dataNameShortPlural()
    {
        return this.isGroupPage ? 'Groups' : 'Baselines';
    }

    get selectedBaseline()
    {
        return this.selectedBaselineOrGroup as (RoleBaseline | null);
    }

    get baselines()
    {
        return this.baselinesOrGroups as RoleBaseline[];
    }

    get selectedDashboardGroup()
    {
        return this.selectedBaselineOrGroup as (DashboardGroup | null);
    }

    get dashboardGroups()
    {
        return this.baselinesOrGroups as DashboardGroup[];
    }

    get footerProps()
    {
        return {
            'items-per-page-options': [10, 25, 50, 100],
        };
    }

    get dashboard()
    {
        if (!dashModule.activeDashboard) throw new Error('No dashboard loaded');
        return dashModule.activeDashboard;
    }

    get invitedCount()
    {
        if (this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length)
        {
            return this.selectedBaselineOrGroup.members.filter(m => m.invited).length;
        }
        return 0;
    }

    computeInviteProgress(target: RoleBaseline | DashboardGroup)
    {
        let invitedCount = 0;
        for(let i = 0; i < target.members.length; ++i)
        {
            if (target.members[i].invited) 
            {
                ++invitedCount;
            }
        }
        return 100 * invitedCount / target.members.length;
    }

    get inviteProgress()
    {
        if (this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length)
        {
            // Same logic as computeInviteProgress, but using cached properties
            return 100 * this.invitedCount / this.selectedBaselineOrGroup.members.length;
        }
        return 0;
    }

    get assessedCount()
    {
        if (this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length)
        {
            return this.selectedBaselineOrGroup.members.filter(m => m.assessed).length;
        }
        return 0;
    }

    get baselineProgress()
    {
        if (this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length)
        {
            return 100 * this.assessedCount / this.selectedBaselineOrGroup.members.length;
        }
        return 0;
    }

    get showProgressBars()
    {
        return this.selectedBaselineOrGroup && this.selectedBaselineOrGroup.members.length;
    }

    async sendInvites()
    {
        if (
            confirm(`Send emails to invite all ${
                (this.selectedMembers.length === 0) ? 'filtered' : 'selected'
            } uninvited ${this.dataName} members to complete the assessment?`)
        )
        {
            if (this.selectedBaselineOrGroup == null) return;
            const emailsToInvite = this.filteredTableData
                .filter(i => (this.selectedMembers.length === 0 || this.selectedMembers.some(m => m.email === i.email)))
                .map(i => i.email);
            const membersToInvite =
                this.selectedBaselineOrGroup.members
                    .filter(m => !m.invited && emailsToInvite.includes(m.email));
            const result = await this.dataModule.invite({ id: this.selectedBaselineOrGroup._id, members: membersToInvite });
            // console.log(result);
            Object.assign(this.selectedBaselineOrGroup, result);
            this.checkAssessed();
        }
    }

    async sendReminders()
    {
        // TODO: clientside rate limit? we could use local storage to disable the button for like 5 min or something?
        if (
            confirm(`Send emails to remind ${this.dataName} members to complete the assessment?\nEmails will be sent to all ${
                (this.selectedMembers.length === 0) ? 'filtered' : 'selected'
            } ${
                this.dataName
            } members who were previously invited but haven't yet completed the assessment.`)
        )
        {
            if (this.selectedBaselineOrGroup == null) return;
            const emailsToRemind = this.filteredTableData
                .filter(i => (this.selectedMembers.length === 0 || this.selectedMembers.some(m => m.email === i.email)))
                .map(i => i.email);
            const membersToRemind =
                this.selectedBaselineOrGroup.members
                    .filter(m => m.invited && !(m.assessed) && emailsToRemind.includes(m.email));
            const result = await this.dataModule.remind({ id: this.selectedBaselineOrGroup._id, members: membersToRemind });
            Object.assign(this.selectedBaselineOrGroup, result);
            this.checkAssessed();
        }
    }

    openCreateModal()
    {
        this.multiCreate = false;
        Vue.set(this, 'createModalExpandedPanels', []);
        this.createModalOpen = true;
        this.csvFile = null;
        this.pastedCsv = '';
    }

    openMultiCreateModal()
    {
        this.multiCreate = true;
        Vue.set(this, 'createModalExpandedPanels', [0]);
        this.createModalOpen = true;
        this.csvFile = null;
        this.pastedCsv = '';
    }

    openEditNameModal()
    {
        this.editNameModalOpen = true;
        if (this.selectedBaselineOrGroup)
        {
            this.editBaselineName = this.selectedBaselineOrGroup.name;
        }
    }

    openCSVModal()
    {
        if (this.selectedBaselineOrGroup
            && !(this.createModalOpen
                || this.editNameModalOpen
                || this.csvModalOpen
                || this.bladeUserSelectModalOpen
            )
        )
        {
            this.csvModalOpen = true;
            this.csvFile = null;
            this.pastedCsv = '';
        }
    }

    get csvTemplateLink()
    {
        let csvTemplate: string | undefined;
        if (this.isGroupPage)
        {
            if (this.createModalOpen && this.multiCreate)
            {
                csvTemplate = '"Email","Name","Groups"\n"Only high performing groups should be added here. Only e-mail and group values are required. Group values should be followed by a numeric (1, 2, etc.). If a user is in two groups, show them in both as Group 1, Group 2."';
            }
            else
            {
                csvTemplate = '"Email","Name"\n"Fill out CSV template and import it to add users."';
            }
        }
        else
        {
            csvTemplate = '"Email","Name","Months Employed"\n"Only e-mail is required."';
            // csvTemplate = '"Email","Name","Months Employed","Performance"\n"Only e-mail and performance values are required. Performance values can be High, Unknown or Low."';
        }
        return 'data:attachment/text,' + encodeURI(csvTemplate);
    }

    onDownloadCSVTemplate()
    {
        // Moved to the template
        // if (this.isGroupPage)
        // {
        //     alert('Fill out CSV template and import it to add users.');
        // }
        // else
        // {
        //     alert('Fill out CSV template and import it to add users. All columns except Email are optional and can be deleted. Performance should have the values High or Low.');
        // }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    users: any[] = [];
    async openBladeUserSelectModal()
    {
        if (!this.selectedBaselineOrGroup)
        {
            alert('No baseline or group selected');
            return;
        }

        this.bladeUserSelectModalOpen = true;
        this.bladeUserSelectLoading = true;

        const res =
            this.isGroupPage 
                ? await dashboardGroupModule.getAllAvailableUsersForDashboardGroup(this.selectedBaselineOrGroup._id)
                :  await roleBaselineModule.getAllAvailableUsersForBaseline(this.selectedBaselineOrGroup._id);
        if (res)
        {
            this.users = res;
        }
        this.bladeUserSelectLoading = false;
    }

    openMockUserSelectModal()
    {
        alert('Add Member From Mock Data Source Feature Not Yet Available');
    }

    get canModifyBaselinesAndGroups()
    {
        if (!authModule.activeUser) return false;
        return isHcadUser(authModule.activeUser);
    }

    async tryDelete()
    {
        if (this.selectedBaselineOrGroup == null) return;

        const confirmed = confirm(`Are you sure you want to delete this ${this.dataNameShort}?`);
        if (confirmed)
        {
            await this.dataModule.delete(this.selectedBaselineOrGroup._id);
            this.selectedBaselineOrGroup = null;
            await this.refresh();
        }
    }

    /** transform CSV string of format:
     *  ```
     *  header1,header2,\nrow1value1,row1value2,\nrow2value1,row2value2
     *  ```
     *  into an array of format:
     *  ```
     *  [ { header1: row1value1, header2: row1value2 }, { header1: row2value1, header2: row2value2 } ]
     *  ```
     */
    csvToArray(csv: string)
    {
        // TODO: possibly add a pre-pass to account for optional whitespace (see https://github.com/mholt/PapaParse/issues/941)
        // TODO: pass File instead of string?
        const result = parse(csv, { header: true, skipEmptyLines: true, transform: (value) => value.trim(), });
        return result.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addMemberToBaseline(memberToAdd: any, baseline: RoleBaseline | DashboardGroup)
    {
        const memberToAddEmail = memberToAdd.email || memberToAdd.Email
            || memberToAdd['Email Address'] || memberToAdd['email address']
            || memberToAdd.emailAddress || memberToAdd.EmailAddress || memberToAdd.email_address
            || memberToAdd['Login Email'] || memberToAdd['login email'];
        if (memberToAddEmail && !(baseline.members.some(m => m.email === memberToAddEmail)))
        {
            const rbm = (this.isGroupPage) ? (new DashboardGroupMember()) : (new RoleBaselineMember());
            rbm.email = memberToAddEmail;
            Object.keys(memberToAdd).forEach((key) =>
            {
                // insensitive key to whitespace/capitalization
                const iKey = key.trim().replace(/[_ ]/g, '').toLowerCase();
                const ignoreKey = ['email', 'emailaddress', 'loginemail', 'group', 'groups'].includes(iKey);
                const value = memberToAdd[key];
                if (iKey === 'fullname' || iKey === 'name') rbm.fullName = value;
                else if (iKey === 'firstname' || iKey === 'givenname') rbm.firstName = value;
                else if (iKey === 'lastname' || iKey === 'surname') rbm.lastName = value;
                else if (iKey === 'userid' || iKey === 'id') rbm.userId = value;
                else if (!ignoreKey) rbm.userdata.push({ fieldName: key, fieldValue: value });
            });
            baseline.members.push(rbm);
        }
    }

    pastedCsv = '';
    async addCSVStringToBaseline(text: string, baselineOrGroupOverride: RoleBaseline | DashboardGroup | null = null)
    {
        console.log({baselineOrGroupOverride, sbog: this.selectedBaselineOrGroup});
        const selectedBaselineOrGroup = baselineOrGroupOverride || this.selectedBaselineOrGroup;
        if (!selectedBaselineOrGroup)
            return;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const membersToAdd: any[] = [];
        try
        {
            await this.csvSanitizer.sanitize(
                text, 
                {
                    performance: (str: string)=>this.lowerCasePerformanceOptions.includes(str.toLowerCase()) ? null : 'Invalid performance value provided - must be "High", "Low", or "Unknown"',
                },
                (data)=>membersToAdd.push(...data),
                {
                    performance: (str: string)=>(str.toLowerCase() === 'unknown' ? 'low' : str)
                }
            );
        }
        catch(err)
        {
            alert(err);
            console.error(err);
            return;
        }

        console.log(membersToAdd);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        membersToAdd.forEach((memberToAdd: any) =>
        {
            this.addMemberToBaseline(memberToAdd, selectedBaselineOrGroup);
        });
        console.log(selectedBaselineOrGroup);
        await this.updateRoleBaseline(true, baselineOrGroupOverride);
        this.csvModalOpen = false;
    }

    /** used when multiCreate == true */
    createBaselinesFromCSVString(text: string): (RoleBaseline | DashboardGroup)[]
    {
        const baselineMap = new Map<string, (RoleBaseline | DashboardGroup)>();
        this.baselinesOrGroups.forEach((b) =>
        {
            baselineMap.set(b.name, b);
        });
        const membersToAdd = this.csvToArray(text);
        console.log(membersToAdd);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        membersToAdd.forEach((memberToAdd: any) =>
        {
            // header should be group, Group, groups, or Groups
            const baselineNamesString: string
                = memberToAdd[this.dataNameShortPlural] || memberToAdd[this.dataNameShortPlural.toLowerCase()];
            const baselineNames = baselineNamesString ? baselineNamesString.split(', ') : [];
            const baselineName = memberToAdd[this.dataNameShort] || memberToAdd[this.dataNameShort.toLowerCase()];
            if (baselineName)
            {
                baselineNames.push(baselineName);
            }
            if (baselineNames && baselineNames.length)
            {
                baselineNames.forEach((blName) =>
                {
                    if (!baselineMap.has(blName))
                    {
                        const b = (this.isGroupPage) ? (new DashboardGroup()) : (new RoleBaseline());
                        b.name = blName;
                        baselineMap.set(blName, b);
                    }
                    const baseline = baselineMap.get(blName);
                    if (baseline)
                    {
                        this.addMemberToBaseline(memberToAdd, baseline);
                    }
                });
            }
        });
        return Array.from(baselineMap.values());
    }

    performanceOptions = ['Unknown', 'Low', 'High'];
    get lowerCasePerformanceOptions()
    {
        return this.performanceOptions.map(p => p.toLowerCase());
    }

    selectablePerformanceOptions = ['Unknown', 'High'];
    performanceEditTempValue = '';
    performanceEditTarget: (RoleBaselineMember | DashboardGroupMember) | null = null;
    performanceEditIndex = -1;

    getPerformance(member: RoleBaselineMember | DashboardGroupMember)
    {
        if (member.userdata)
        {
            const perfIdx = member.userdata.findIndex(ud => ud.fieldName === 'performance');
            if (perfIdx >= 0)
            {
                return member.userdata[perfIdx].fieldValue;
            }
        }

        // Supporting data from either the table itself or actual members
        return (member as unknown as { performance: string }).performance || 'Unknown';
    }

    setPerformance(member: RoleBaselineMember | DashboardGroupMember, value: string)
    {
        if (member.userdata)
        {
            const perfIdx = member.userdata.findIndex(ud => ud.fieldName === 'performance');
            if (perfIdx >= 0)
            {
                member.userdata[perfIdx].fieldValue = value;
            }
            else
            {
                member.userdata.push({ fieldName: 'performance', fieldValue: value });
            }
        }
        
        // Supporting data from either the table itself or actual members
        (member as unknown as { performance: string }).performance = value;
    }

    editPerformance(member: RoleBaselineMember, index: number)
    {
        this.performanceEditTarget = member;
        this.performanceEditIndex = index;
        this.performanceEditTempValue = this.getPerformance(member);
    }

    async savePerformance(member: RoleBaselineMember)
    {
        if (!this.selectedBaselineOrGroup)
        {
            alert('No baseline or group selected');
            return;
        }

        let newPerf = this.performanceEditTempValue.trim().toLowerCase();
        // caps first character
        newPerf = newPerf.charAt(0).toUpperCase() + newPerf.slice(1);

        if (!this.performanceOptions.includes(newPerf))
        {
            alert('Invalid performance value provided - must be "High", "Low", or "Unknown"');
            return;
        }

        if (newPerf === this.getPerformance(member))
        {
            this.performanceEditTarget = null;
            this.performanceEditIndex = -1;
            return;
        }

        const tableTarget = this.performanceEditTarget;
        if (!tableTarget)
        {
            alert('No performance target');
            return;
        }

        if (this.performanceEditTarget)
        {
            const confirmed = true;//confirm(`Are you sure you want to update the performance of ${this.performanceEditTarget.email} to ${newPerf}?`);
            if (confirmed)
            {
                const baseline = this.selectedBaselineOrGroup;
                const memberIndex = baseline.members.findIndex(m => m.email === tableTarget.email);
                if (memberIndex >= 0)
                {
                    this.setPerformance(baseline.members[memberIndex], newPerf);
                    await this.updateRoleBaseline(true);
                    // await this.refresh();
                }
            }
        }
        this.performanceEditTarget = null;
        this.performanceEditIndex = -1;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async useCSV(csvStringFunction: any = this.addCSVStringToBaseline)
    {
        if (this.csvFile == null)
        {
            if (this.pastedCsv)
            {
                return await csvStringFunction(this.pastedCsv);
            }
            return;
        }

        console.log(this.csvFile);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let result: any = null;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await new Promise<any>((resolve, reject) =>
        {
            if (this.csvFile)
            {
                const reader = new FileReader();

                reader.onload = async(e) =>
                {
                    if (e.target) 
                    {
                        const text = e.target.result;
                        console.log(text);
                        if (typeof text === 'string')
                        {
                            result = await csvStringFunction(text);
                        }
                    }
                    resolve(result);
                };
                reader.onerror = (error) =>
                {
                    reject(error);
                };

                reader.readAsText(this.csvFile);
            }
            else
            {
                reject('No file.');
            }
        });
        return result;
    }

    async addBladeUsersToBaseline()
    {
        if (this.selectedBaselineOrGroup) 
        {
            const { selectedBaselineOrGroup } = this;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.selectedBladeUsers.forEach((memberToAdd: any) =>
            {
                if (memberToAdd.email && !(selectedBaselineOrGroup.members.some(m => m.email === memberToAdd.email)))
                {
                    const rbm: (DashboardGroupMember | RoleBaselineMember) & {performance?: string} = (this.isGroupPage) ? (new DashboardGroupMember()) : (new RoleBaselineMember());
                    rbm.bladeUser = memberToAdd._id;
                    rbm.email = memberToAdd.email;
                    rbm.firstName = memberToAdd.firstName;
                    rbm.lastName = memberToAdd.lastName;
                    if (!this.isGroupPage)
                    {
                        // rbm.userdata.push({fieldName: 'performance', fieldValue: 'Unknown'});
                        // console.log({
                        //     message: 'added perf data',
                        //     rbm
                        // });
                    }
                    selectedBaselineOrGroup.members.push(rbm);
                }
            });
            console.log(JSON.parse(JSON.stringify(selectedBaselineOrGroup)));
            this.updateRoleBaseline(true);
            this.bladeUserSelectModalOpen = false;
        }
    }

    async deleteAllFilteredMembers()
    {
        if (this.selectedBaselineOrGroup == null) return;
        const emailsToDelete = this.filteredTableData.map(i => i.email);

        const confirmed = confirm(`Are you sure you want to remove the following members from the ${this.dataNameShort}: ${
            this.selectedBaselineOrGroup.name}?\n${
            emailsToDelete.join(', ')}`);
        if (confirmed)
        {
            this.selectedBaselineOrGroup.members = this.selectedBaselineOrGroup.members.filter(m => !(emailsToDelete.includes(m.email)));
            this.tableFilter = '';
            await this.updateRoleBaseline();
        }
    }

    async deleteSelectedFilteredMembers()
    {
        if (this.selectedBaselineOrGroup == null) return;
        const fsm = this.selectedMembers.filter(sm => this.filteredTableData.includes(sm));
        const emailsToDelete = fsm.map(i => i.email);

        const confirmed = confirm(`Are you sure you want to remove the following members from the ${this.dataNameShort}: ${
            this.selectedBaselineOrGroup.name}?\n${
            emailsToDelete.join(', ')}`);
        if (confirmed)
        {
            this.selectedBaselineOrGroup.members = this.selectedBaselineOrGroup.members.filter(m => !(emailsToDelete.includes(m.email)));
            await this.updateRoleBaseline();
        }
    }

    downloadReportFileName = '';
    showDownloadReportModal = false;
    onClickDownload()
    {
        this.downloadReportFileName = '';
        this.showDownloadReportModal = true;
    }

    get downloadDefaultText()
    {
        const defaultText = this.isBaselinePage ? 'Baseline' : 'All Groups';
        return `${this.selectedBaselineOrGroup ? this.selectedBaselineOrGroup.name : defaultText} Members`;
    }

    downloadTableAsCSV()
    {
        this.showDownloadReportModal = false;
        if (!(this.downloadReportFileName))
        {
            this.downloadReportFileName = this.downloadDefaultText;
        }
        // console.log(this.sortBy);
        // console.log(this.sortDesc);
        const data = this.customSort(this.filteredTableData, [this.sortBy], [this.sortDesc]);
        downloadCSV(
            data,
            this.tableHeaders,
            this.downloadReportFileName,
        );
    }

    checkingAssessmentProgress = false;
    async checkAssessed()
    {
        if (this.selectedBaselineOrGroup)
        {
            this.checkingAssessmentProgress = true;
            try
            {
                if (this.isGroupPage)
                {
                    const result = await dashboardGroupModule.checkAssessed(this.selectedBaselineOrGroup._id);
                    Object.assign(this.selectedBaselineOrGroup, result);
                }
                else
                {
                    const result = await roleBaselineModule.checkAssessed(this.selectedBaselineOrGroup._id);
                    Object.assign(this.selectedBaselineOrGroup, result);
                }
                // this.$forceUpdate();
            }
            catch (err)
            {
                alert('Failed to check assessment progress for Job baseline. Please check browser console for errors.');
                console.error(err);
            }
            this.checkingAssessmentProgress = false;
        }
        else
        {
            if (this.isGroupPage)
            {
                const promises: Promise<DashboardGroup | null>[] = [];
                const groups = this.dashboardGroups;
                for (const _group of groups)
                {
                    const group = _group;
                    const cb = async ()=>
                    {
                        const resp = await dashboardGroupModule.checkAssessed(group._id);
                        if (resp)
                        {
                            for(let i = 0; i < this.baselinesOrGroups.length; i++)
                            {
                                if (this.baselinesOrGroups[i]._id === group._id)
                                {
                                    Object.assign(this.baselinesOrGroups[i], resp);
                                }
                            }
                            // TODO: What do we need to do here...?
                        }
                        return resp;
                    };
                    promises.push(cb());
                }
                await Promise.all(promises);
            }
        }
    }

    @Watch('selectedBaselineOrGroup')
    async onSelectedBaselineChanged()
    {
        // await this.refresh();
        if (this.selectedBaselineOrGroup != null)
        {
            this.lessThan10WarningVisible = (this.selectedBaselineOrGroup.members.length < 10);

            // push instead of replace so this is added to the back button stack
            // switch to replace if we don't want baseline/group selection to be an entry on the back button stack
            this.$router.push({
                query: { startingSelected: this.selectedBaselineOrGroup.name },
            });
        }
        else
        {
            this.$router.push(this.$route.path);
        }
        await this.checkAssessed();
    }

    @Watch('tableFilter')
    async onFilterChanged()
    {
        this.selectedMembers = this.selectedMembers.filter(m => this.filteredTableData.some(i => i.email === m.email));
    }

    validateName(name: string): boolean
    {
        if (!name || name.trim().length === 0)
        {
            alert('Please enter a name for the Job baseline.');
            return false;
        }
        else if (this.baselinesOrGroups.some(b => b.name === name.trim()))
        {
            // TODO: this check ought to be more robust eventually
            alert(`This name is already in use by a ${this.dataName}.`);
            return false;
        }
        return true;
    }

    newBaselineName = '';

    async onModalCreate()
    {
        // validate
        if (!this.multiCreate && !(this.validateName(this.newBaselineName)))
        {
            return;
        }

        // create
        try
        {
            if (this.multiCreate && (this.csvFile != null || !!(this.pastedCsv.trim())))
            {
                if (this.isGroupPage)
                {
                    const dashboardGroups: DashboardGroup[] =
                        await this.useCSV(async(csv: string) => this.createBaselinesFromCSVString(csv));
                    console.log(dashboardGroups);
                    if (dashboardGroups && dashboardGroups.length)
                    {
                        await dashboardGroupModule.multicreate(
                            { dashboardId: ((await dashModule.getActiveDashboard())._id), dashboardGroups }
                        );
                        await this.refresh();
                        this.createModalOpen = false;
                    }
                    else
                    {
                        alert('Input CSV is invalid.');
                    }
                }
                else
                {
                    // const baselines: RoleBaseline[] =
                    //     this.useCSV((csv: string) => this.createBaselinesFromCSVString(csv));
                    return; // not supported at the moment
                }
            }
            else
            {
                let created: RoleBaseline | DashboardGroup | null = null;
                if (this.isGroupPage)
                {
                    const dashboardGroup = new DashboardGroup();
                    dashboardGroup.name = this.newBaselineName.trim();
                    created =
                        await dashboardGroupModule.create(
                            { dashboardId: ((await dashModule.getActiveDashboard())._id), dashboardGroup }
                        );
                }
                else
                {
                    const baseline = new RoleBaseline();
                    baseline.name = this.newBaselineName.trim();
                    created =
                        await roleBaselineModule.create(
                            { dashboardId: ((await dashModule.getActiveDashboard())._id), baseline }
                        );
                }
                this.createModalOpen = false;
                if (created)
                {
                    if (this.csvFile != null || !!(this.pastedCsv.trim()))
                    {
                        await this.useCSV(async(csv: string) => await this.addCSVStringToBaseline(csv, created));
                    }
                    this.selectedBaselineOrGroup = created;
                }
                await this.refresh();
            }
        }
        catch (err)
        {
            alert('Creation failed. Please check browser console for errors.');
            console.error(err);
        }
    }

    editBaselineName = '';

    async onModalEditName()
    {
        if (this.selectedBaselineOrGroup && this.validateName(this.editBaselineName))
        {
            this.selectedBaselineOrGroup.name = this.editBaselineName;
            await this.updateRoleBaseline(true);
            this.editNameModalOpen = false;
        }
    }

    async updateRoleBaseline(checkAssessed = false, baselineOrGroupOverride: RoleBaseline | DashboardGroup | null = null)
    {
        // validate
        const selectedBaselineOrGroup = baselineOrGroupOverride || this.selectedBaselineOrGroup;
        if (!selectedBaselineOrGroup)
            return;

        // update
        try
        {
            const result = (this.isGroupPage && (selectedBaselineOrGroup as DashboardGroup))
                ? (await dashboardGroupModule.update((selectedBaselineOrGroup as DashboardGroup)))
                : ((selectedBaselineOrGroup as RoleBaseline)
                    ? (await roleBaselineModule.update((selectedBaselineOrGroup as RoleBaseline)))
                    : null
                );
            Object.assign(selectedBaselineOrGroup, result);
            // this.$forceUpdate();
            if (checkAssessed)
            {
                await this.checkAssessed();
            }
            // await this.refresh();
        }
        catch (err)
        {
            alert('Update failed. Please check browser console for errors.');
            console.error(err);
        }
    }

    get tableHeaders()
    {
        if (this.selectedBaselineOrGroup == null && this.isBaselinePage) return [];
        const headers = [{ text: 'Email', value: 'email', sortable: true }];
        const headerSet = new Set<string>();
        this.unmappedTableData.forEach((member) =>
        {
            if (member.fullName) headerSet.add('Full Name');
            if (member.firstName) headerSet.add('First Name');
            if (member.lastName) headerSet.add('Last Name');
            if (member.userId) headerSet.add('User ID');
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((member as any).groups) headerSet.add('Groups');
            member.userdata.forEach(({ fieldName }) =>
            {
                headerSet.add(fieldName);
            });
        });

        headerSet.forEach((headerText) =>
        {
            headers.push({
                text: headerText,
                value: headerText.trim().replace(/[_ ]/g, '').toLowerCase(),
                sortable: true,
            });
        });

        // Get rid of the performance column for now since the new model does not need it
        if (headers.findIndex(h => h.text.toLowerCase() === 'performance') >= 0)
        {
            headers.splice(headers.findIndex(h => h.text === 'Performance'), 1);
        }
        
        return headers;
    }

    get bladeUserSelectTableHeaders()
    {
        if (this.selectedBaselineOrGroup == null) return [];
        const headers = [
            { text: 'Email', value: 'email', sortable: true },
            { text: 'First Name', value: 'firstName', sortable: true },
            { text: 'Last Name', value: 'lastName', sortable: true },
        ];
        return headers;
    }

    get unmappedTableData()
    {
        let memberList: RoleBaselineMember | DashboardGroupMember[] = [];
        if (this.selectedBaselineOrGroup == null)
        {
            if (this.isGroupPage)
            {
                for(const group of this.dashboardGroups)
                {
                    for(const member of group.members)
                    {
                        let idx = memberList.findIndex(a => (a.userId && member.userId && a.userId === member.userId) || (a.email && member.email && a.email === member.email));
                        if (idx === -1)
                        {
                            memberList.push(Object.assign(new DashboardGroupMember, member));
                            idx = memberList.length - 1;
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            (memberList[idx] as any).groups = [];
                        }

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (memberList[idx] as any).groups.push(group.name);
                    }
                }

                for(let i = 0; i < memberList.length; ++i)
                {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const groups = ((memberList[i] as any).groups as (string)[]);
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ((memberList[i] as any).groups) = groups.join(', ');
                }
            }
            else
            {
                return [];
            }
        }
        else
        {
            memberList = this.selectedBaselineOrGroup.members;
        }
        return memberList;
    }

    get tableData()
    {
        const startingMemberList = this.unmappedTableData;
        
        const data = startingMemberList.map((member) =>
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const mapped: any = {
                email: member.email,
                fullname: member.fullName,
                firstname:
                    member.bladeUser
                        ? (member.bladeUser.firstName || member.firstName)
                        : member.firstName,
                lastname:
                    member.bladeUser
                        ? (member.bladeUser.lastName || member.lastName)
                        : member.lastName,
                userid:
                    member.bladeUser
                        ? (member.bladeUser.studentId || member.userId)
                        : member.userId,
                
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                groups: (member as any).groups,
                performance: null
            };
            member.userdata.forEach(({ fieldName, fieldValue }) =>
            {
                mapped[fieldName.trim().replace(/[_ ]/g, '').toLowerCase()] = fieldValue;
            });

            // TODO: Fight back on this confusing choice
            if (mapped.performance && mapped.performance.toLowerCase() === 'low')
            {
                mapped.performance = 'Unknown';
            }
            return mapped;
        });
        return data;
    }

    get bladeUserSelectTableData()
    {
        if (this.selectedBaselineOrGroup == null) return [];
        const data = this.users.map((user) =>
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const mapped: any = {
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
            };
            return mapped;
        });
        // console.log(data);
        return data;
    }

    get filteredTableData()
    {
        if (this.tableFilter.trim().length === 0) return this.tableData;

        const search = this.tableFilter.trim().toLowerCase().split(' ');
        return this.tableData.filter(a =>
        {
            const searchableText = JSON.stringify(a).toLowerCase();
            return search.every(token => searchableText.includes(token));
        });
    }

    get filteredBladeUserSelectTableData()
    {
        if (this.bladeUserSelectTableFilter.trim().length === 0) return this.bladeUserSelectTableData;

        const search = this.bladeUserSelectTableFilter.trim().toLowerCase().split(' ');
        return this.bladeUserSelectTableData.filter(a =>
        {
            const searchableText = JSON.stringify(a).toLowerCase();
            return search.every(token => searchableText.includes(token));
        });
    }

    tableHeight()
    {
        if (this.isGroupPage)
        {
            return window.innerWidth > 878 ? 'calc(100vh - 440px)' : 'calc(100vh - 485px)';
        }
        const space =
            (this.lessThan10WarningVisible)
                ? 415
                : 380;
        return window.innerWidth > 878 ? `calc(100vh - ${space}px)` : `calc(100vh - ${space + 45}px)`;
    }

    onResize()
    {
        this.$forceUpdate();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customSort(items: any[], sortBy: any[], sortDescTab: boolean[])
    {
        // Only support sorting with a single element
        if (sortBy.length != 1 || items.length < 1) return items;

        const sortDesc = sortDescTab[0];
        const fieldIdx = sortBy[0];
        const sortKey = (items[0][fieldIdx] == null) ? Object.keys(items[0])[fieldIdx] : fieldIdx;
        // console.log(fieldIdx);
        // console.log(sortKey);
        // console.log(items);

        items.sort((a, b)=>
        {
            const aval = a[sortKey] || '';
            const bval = b[sortKey] || '';
            const res = -aval.localeCompare(bval, undefined, {numeric: true, sensitivity: 'base'});
            if (sortDesc)
            {
                return -res;
            }
            return res;
        });

        return items;
    }

    changeSort(column: number)
    {
        if (this.sortBy == `${column}`)
        {
            this.sortDesc = !this.sortDesc;
        }
        else
        {
            this.sortBy = `${column}`;
            this.sortDesc = true;
        }
    }

    changeBladeUserSelectSort(column: number)
    {
        if (this.bladeUserSelectSortBy == `${column}`)
        {
            this.bladeUserSelectSortDesc = !this.bladeUserSelectSortDesc;
        }
        else
        {
            this.bladeUserSelectSortBy = `${column}`;
            this.bladeUserSelectSortDesc = true;
        }
    }

    get rowClass()
    {
        // if (this.page.benchmarkPageIdx !== null) return 'idt-clickable-row';
        return '';
    }

    // clickedTableRow(_item: any)
    // {
    // }

    copyFilteredTableDataToClipboard()
    {
        if (!(this.filteredTableData) || this.filteredTableData.length === 0)
            return;

        const headersWithoutGroups = this.tableHeaders.filter(h => h.text.toLowerCase() !== 'groups');
        const headerTexts = headersWithoutGroups.map(h => h.text);
        const headerKeys = headersWithoutGroups.map(h => h.value);
        copy(
            `${
                headerTexts.map((h) => `"${h.replaceAll(/"/g, '""')}"`).join()
            }\n${
                this.filteredTableData.map(
                    (v) => headerKeys.map(
                        (h) => `"${(v[h] != null) ? `${v[h]}`.replaceAll(/"/g, '""') : ''}"`
                    ).join()
                ).join('\n')
            }`
        );
    }

    onKeyDown(event: KeyboardEvent)
    {
        if ((event.ctrlKey || event.metaKey) && event.key === 'c')
        {
            this.copyFilteredTableDataToClipboard();
        }
        else if (!(this.createModalOpen) && (event.ctrlKey || event.metaKey) && event.key === 'v')
        {
            this.openCSVModal();
        }
    }

    async refresh()
    {
        this.loading = true;
        try
        {
            const res = await this.dataModule.getAllInDashboard((await dashModule.getActiveDashboard())._id);
            if (res)
            {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                this.baselinesOrGroups =
                    (this.isGroupPage)
                        ? (res as DashboardGroup[]).filter((g: DashboardGroup) => !(g.exploratory))
                        : res;
            }

            // Intentionally non-async; don't want this to block refresh()
            this.checkAssessed();
        }
        catch (er)
        {
            alert(`Error loading ${this.dataNameShortPlural}.`);
        }
        this.loading = false;
    }

    async mounted()
    {
        await this.refresh();
        const startingSelected = this.$route.query.startingSelected;
        if (startingSelected)
        {
            const match = this.baselinesOrGroups.find(b => b.name === startingSelected);
            if (match)
            {
                this.selectedBaselineOrGroup = match;
            }
        }
    }

    created()
    {
        window.addEventListener("keydown", this.onKeyDown);
    }

    destroyed()
    {
        window.removeEventListener("keydown", this.onKeyDown);
    }
}

pageViewerComponents
    .registerComponent(DashboardPageType.RoleBaselinePage, RoleBaselinePage)
    .registerDataFactory(DashboardPageType.RoleBaselinePage, ()=>new RoleBaselineDashboardPage)
;
pageViewerComponents
    .registerComponent(DashboardPageType.DashboardGroupManager, RoleBaselinePage)
    .registerDataFactory(DashboardPageType.DashboardGroupManager, ()=>new DashboardGroupManagerDashboardPage)
;
