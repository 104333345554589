/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from '@/utils/axios';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';

/*
    Docs for class modules here: https://github.com/gertqin/vuex-class-modules
*/

function saveEnabledList(enabled: Map<string, boolean>) 
{
    const saveData: string[] = [];
    for (const en of enabled.keys()) 
    {
        saveData.push(en);
    }
    window.localStorage.setItem('assetEnabledSettings', JSON.stringify(saveData));
}

@Module
class AssetModule extends VuexModule
{
    loaded = false;
    environments: string[] = [];
    manifests = new Map<string, any>();
    enabled = new Map<string, boolean>();
    _manifestSwitch = false;

    aggregateDirectoryTreeNode(path: string) 
    {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const state = this;
        
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const node: { Directories: any[], Files: any[] } = {
            Directories: [],
            Files: []
        };
  
        const pathSplit = path === '' ? [] : path.split('/');
        for (const k of state.enabled.keys()) 
        {
            const manifest = state.manifests.get(k);
            if (!manifest) continue;
  
            let cur = manifest.Root;
            for (let i = 0; i < pathSplit.length; ++i) 
            {
                const part = pathSplit[i];
                console.log(`trying ${part}`);
                if (cur.Directories[part]) 
                {
                    console.log('found');
                    cur = cur.Directories[part];
                }
                else 
                {
                    cur = null;
                    break;
                }
            }
  
            if (cur) 
            {
                const dirs = Object.keys(cur.Directories);
                for (const dir of dirs) 
                {
                    if (!node.Directories.includes(dir)) 
                    {
                        node.Directories.push(dir);
                    }
                }
  
                const files = Object.keys(cur.Files);
                for (const file of files) 
                {
                    node.Files.push(Object.assign({ local: file, env: k }, cur.Files[file]));
                }
            }
            else 
            {
                continue; 
            }
        }
  
        node.Files.sort((a, b) => a.local.localeCompare(b.local));
        for (let i = 1; i < node.Files.length; ++i) 
        {
            const a = node.Files[i - 1];
            const b = node.Files[i];
  
            if (a.local === b.local) 
            {
                a.showEnv = true;
                b.showEnv = true;
            }
        }
  
        return node;
    }

    @Mutation
    setAssetEnvironments(envs: string[])
    {
        this.environments = envs;
    }

    @Mutation
    setAssetManifest(arg: {environment: string, data: any})
    {
        this.manifests.set(arg.environment, arg.data);
        this._manifestSwitch = !this._manifestSwitch;
    }

    @Mutation
    setAssetManifestEnabled(arg: { environment: string, enabled: boolean })
    {
        this.enabled.set(arg.environment, arg.enabled);
        saveEnabledList(this.enabled);
        this._manifestSwitch = !this._manifestSwitch;
    }

    @Mutation
    setAssetManifestEnabledListFromNameList(nameList: string[])
    {
        this.enabled.clear();
        for(const key of nameList)
        {
            this.enabled.set(key, true);
        }

        saveEnabledList(this.enabled);
        this._manifestSwitch = !this._manifestSwitch;
    }

    @Mutation
    setAssetSystemLoaded(loaded: boolean)
    {
        this.loaded = loaded;
    }

    @Action
    async loadAssetData()
    {
        if (this.loaded) return;
        this.setAssetManifestEnabledListFromNameList(JSON.parse(window.localStorage.getItem('assetEnabledSettings') || '["master"]'));
        
        try
        {
            const envReq = await axios.get(`https://assets.redmeru.com/environments.json?nocache=${Date.now()}`, {
                headers: {},
                ignoreAdditionalHeaders: true
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any);

            const environments = envReq.data;

            // eslint-disable-next-line no-restricted-syntax
            for (let i = 0; i < environments.length; ++i) 
            {
                const env = environments[i];
                try 
                {
                    const manReq = await axios.get(`https://assets.redmeru.com/${env}/manifest.json?nocache=${Date.now()}`, {
                        headers: {},
                        ignoreAdditionalHeaders: true
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any);

                    const parsed = manReq.data;
                    parsed.enabled = false;
                    this.setAssetManifest({ environment: env, data: parsed });
                }
                catch (er) 
                {
                    console.error(`Failed to load environment manifest ${env}`);
                    environments.splice(i);
                    // eslint-disable-next-line no-plusplus
                    --i;
                }
            }
            this.setAssetEnvironments(environments);
        }
        catch(err)
        {
            console.error(err);
        }
    }

    get getAssetEnvironments()
    {
        return this.environments;
    }

    get getAssetManifest()
    {
        if (this._manifestSwitch && !this._manifestSwitch) console.log('...?');
        return (env: string) => this.manifests.get(env);
    }

    get getEnabledAssetManifests()
    {
        if (this._manifestSwitch && !this._manifestSwitch) console.log('...?');
        const arr = [];
        for (const k of this.enabled.keys()) arr.push(k);
        return arr;
    }

    get isAssetManifestEnabled()
    {
        if (this._manifestSwitch && !this._manifestSwitch) console.log('...?');
        return (env: string) => this.enabled.get(env) || false;
    }

    get listAssetsAtPath()
    {
        if (this._manifestSwitch && !this._manifestSwitch) console.log('...?');
        return (path: string) => 
        {
            const node = this.aggregateDirectoryTreeNode(path);
            return node.Files;
        };
    }

    get listDirectoriesAtPath()
    {
        if (this._manifestSwitch && !this._manifestSwitch) console.log('...?');
        return (path: string) =>
        {
            const node = this.aggregateDirectoryTreeNode(path);
            const res: string[] = [];
            for (const k of node.Directories) res.push(k);
            return res;
        };
    }
}

// Register the module
import store from '../index';
const assetModule = new AssetModule({store, name: 'assets'});
export default assetModule;