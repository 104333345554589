






import { Component, Vue, Prop } from 'vue-property-decorator';
import { DashboardPageFieldType, FieldValueType, IndividualDataTableUserData } from '@/models/hcad/shared/dashboard';
import { UserInfoAndMetrics } from '../../../models/hcad/shared/queries';
import { fieldViewerComponents, FieldViewerContext, getIndex, getValue } from '../../../utils/typed-configs';

@Component
export default class LongevityPredictionRenderer extends Vue
{
    @Prop({type: Number, required: true})
    type!: DashboardPageFieldType;

    @Prop({required: true})
    value!: FieldValueType;

    @Prop({type: Object, required: true})
    context!: UserInfoAndMetrics<IndividualDataTableUserData>;

    get showCheckbox()
    {
        return showCheckbox(this.value as number);
    }

    get computedColor()
    {
        if (this.showCheckbox) return 'green';
        return 'grey';
    }
}

function showCheckbox(v: number)
{
    return v >= 0.8;
}

function displayText(v: number)
{
    if (showCheckbox(v)) return '☑';
    return '';
}

function toString(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext)
{
    const index = getIndex(fieldIdx, fieldType, a);
    const aValue = getValue(a, index);
    return aValue ? displayText(aValue as number) : '';
}

function sort(fieldIdx: number, fieldType: DashboardPageFieldType, a: FieldViewerContext, b: FieldViewerContext)
{
    const index = getIndex(fieldIdx, fieldType, a);
    const aValue = getValue(a, index);
    const bValue = getValue(b, index);
    const aDefined = (aValue != null);
    const bDefined = (bValue != null);
    if (aDefined && !bDefined) return -1;
    if (!aDefined && bDefined) return 1;
    if (!aDefined && !bDefined) return 0;
    const aval = aValue as number;
    const bval = bValue as number;
    return (aval) - (bval);
}

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.LongevityPrediction, LongevityPredictionRenderer)
    .registerDataFactory(DashboardPageFieldType.LongevityPrediction, () => ({ toString, sort }));

fieldViewerComponents
    .registerComponent(DashboardPageFieldType.MatchPercent, LongevityPredictionRenderer)
    .registerDataFactory(DashboardPageFieldType.MatchPercent, () => ({ toString, sort }));

