























import { Component, Vue } from 'vue-property-decorator';
import auth from '@/store/modules/AuthModule';
import activeEnvironment from './config/Environment';

@Component
export default class App extends Vue
{
    get busy_visual()
    {
        // Wrapper so that we can add other bits in here
        return this.busy;
    }

    get busy()
    {
        return this.loading;
    }

    get loggedIn()
    {
        return auth.isSignedIn;
    }

    get userName()
    {
        return auth.activeUser ? `${auth.activeUser.firstName} ${auth.activeUser.lastName}` : '';
    }

    loading = true;
    async mounted()
    {
        this.loading = true;
        try
        {
            await auth.init();
        }
        catch(er)
        {
            console.error(`Failed to load - ${er}`);
        }

        if (document.title === 'Loading...')
        {
            document.title = activeEnvironment.DefaultTabText;
        }
        this.loading = false;
    }

    logout()
    {
        auth.logout();
    }
}
